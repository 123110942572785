import React from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import { useWizard, WizardHero, ValidationMessage } from '@oup/shared-front-end';

import compose from '../../../utils/compose/compose.js';
import { closeForm } from '../../../redux/reducers/placementTestSessionCreate';
import { closePlacementTestOnboardingWizard } from '../../../redux/actions/hubUi';
import { clearWizardState } from '../../../redux/actions/placementTestOnboardingWizard';
import { refreshPlacementTestList } from '../../../redux/actions/placementTests';
import withLocalizedContent from '../../../language/withLocalizedContent';
import { HubIllustrationAltText, HubIllustrationConstants } from '../../../globals/hubConstants';
import { featureIsEnabled } from '../../../globals/envSettings';

function PlacementTestSummary({
  placementTestSessionNameValue,
  localizedContent: { placementTests: placementTestsContent },
  clearWizardStateAction,
  clearPlacementTestSessionCreateStateAction,
  closeWizardAction,
  refreshPlacementTestListAction
}) {
  const { loading, setNextStepDisabled, stepHandler } = useWizard();
  const canShowStudentResults = featureIsEnabled('show-test-results-to-students');

  setNextStepDisabled(loading);

  stepHandler(() => {
    closeWizardAction();
    clearWizardStateAction();
    clearPlacementTestSessionCreateStateAction();
    if (!canShowStudentResults) {
      refreshPlacementTestListAction();
    }
  });

  const studentsBulkImportIsInProgress = useSelector(
    state => state.placementTestSessionCreate.placementTest.studentsBulkImportIsInProgress
  );

  const getWizardStepTitle = () =>
    studentsBulkImportIsInProgress
      ? `${placementTestSessionNameValue ||
          placementTestsContent.placement_test_notification_page_session_name_fallback} ${
          placementTestsContent.placement_test_summary_page_title_bulk_in_progress
        }`
      : `${placementTestSessionNameValue ||
          placementTestsContent.placement_test_notification_page_session_name_fallback} ${
          placementTestsContent.placement_test_summary_page_title
        }`;

  const getWizardStepSubtitle = () =>
    studentsBulkImportIsInProgress ? (
      `${placementTestsContent.placement_test_summary_page_subtitle_bulk_in_progress}`
    ) : (
      <>
        {placementTestsContent.placement_test_summary_page_subtitle}{' '}
        <strong>{placementTestsContent.placement_test_summary_page_subtitle_manage_session}</strong>
      </>
    );

  return (
    <div data-testid="ONBOARDING_WIZARD_CREATE_A_PLACEMENT_TEST_SESSION_SUMMARY_CONTAINER">
      <WizardHero
        heading={{ text: getWizardStepTitle() }}
        subHeading={getWizardStepSubtitle()}
        image={{ src: HubIllustrationConstants.SUCCESS, alt: HubIllustrationAltText.SUCCESS }}
        content={
          studentsBulkImportIsInProgress && (
            <ValidationMessage state="information">
              {placementTestsContent.placement_test_summary_page_info_bulk_in_progress}
            </ValidationMessage>
          )
        }
      />
    </div>
  );
}

PlacementTestSummary.propTypes = {
  placementTestSessionNameValue: PropTypes.string,
  localizedContent: PropTypes.object.isRequired,
  clearWizardStateAction: PropTypes.func.isRequired,
  clearPlacementTestSessionCreateStateAction: PropTypes.func.isRequired,
  closeWizardAction: PropTypes.func.isRequired,
  refreshPlacementTestListAction: PropTypes.func.isRequired
};

export default compose(
  withLocalizedContent('placementTests'),
  connect(
    ({ placementTestSessionCreate }) => ({
      placementTestSessionNameValue: placementTestSessionCreate.placementTestSessionNameValue
    }),
    {
      clearWizardStateAction: clearWizardState,
      clearPlacementTestSessionCreateStateAction: closeForm,
      closeWizardAction: closePlacementTestOnboardingWizard,
      refreshPlacementTestListAction: refreshPlacementTestList
    }
  )
)(PlacementTestSummary);
