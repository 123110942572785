import React from 'react';
import PropTypes from 'prop-types';
import { useWizard, Footer } from '@oup/shared-front-end';

function RegisterOrSignInWizardFooter({ content }) {
  const { nextStep, nextStepDisabled } = useWizard();

  return (
    <Footer
      primaryButtonAction={nextStep}
      primaryButtonLabel={content.next}
      primaryButtonAttributes={{
        disabled: nextStepDisabled,
        dataAttributes: {
          testid: 'REGISTER_OR_SIGNIN_WIZARD_NEXT_BUTTON'
        }
      }}
    />
  );
}

RegisterOrSignInWizardFooter.propTypes = {
  content: PropTypes.object.isRequired
};

export default RegisterOrSignInWizardFooter;
