import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// Components
import { Checkbox } from '@oup/shared-front-end';
import ScrollContainer from '../../ScrollContainer/ScrollContainer';
import PopoutNavFooter from '../../PopoutNavFooter/PopoutNavFooter';
import Unit from '../Unit';
// Utils
import { sanitizeUrl } from '../../../utils/url';
// Styles
import styles from '../ManageProductLocks.scss';

function ManageProductLocksForm({
  productDetails = {},
  productStructureUnits = [],
  productLocks = {},
  content,
  onSave
}) {
  const [checkedItems, setCheckedItems] = useState([]);

  useEffect(() => {
    setCheckedItems(productLocks.lockedNodes || []);
  }, [productStructureUnits, productLocks]);

  const onCheckboxClick = (e, uId, isCurrentlyChecked) => {
    e.stopPropagation();
    const newCheckedItems = [...checkedItems];

    if (isCurrentlyChecked) {
      const index = newCheckedItems.indexOf(uId);
      newCheckedItems.splice(index, 1);
    } else {
      newCheckedItems.push(uId);
    }
    setCheckedItems(newCheckedItems);
  };

  const allUnitIds = productStructureUnits.map(unit => unit.uId);

  const toggleAllCheckboxes = e => {
    e.stopPropagation();
    if (allUnitIds.length === checkedItems.length) {
      setCheckedItems([]);
    } else {
      setCheckedItems(allUnitIds);
    }
  };

  const getContent = () => {
    const units = productStructureUnits.map(unit => {
      const isChecked = checkedItems.includes(unit.uId);
      return (
        <Unit content={content} key={unit.uId} data={unit} isChecked={isChecked} onCheckboxClick={onCheckboxClick} />
      );
    });

    return units;
  };

  return (
    <ScrollContainer
      footerContent={<PopoutNavFooter nextButtonText={content.save} nextAction={() => onSave(checkedItems)} />}
    >
      <div className={styles.lockContentContainer}>
        <div className={styles.headerContent}>
          <p>{content.manage_students_access_2}</p>
          <p>{content.manage_students_access_subtitle}</p>
        </div>
        <div className={styles.productDetailsContainer}>
          <div className={styles.imageContainer}>
            <img src={sanitizeUrl(productDetails.coverImage)} alt={`${productDetails.title} cover`} />
          </div>
          <div className={styles.titleContainer}>
            <p>{productDetails.title}</p>
          </div>
        </div>
        <div className={styles.tabContainer}>
          <div className={styles.tabText}>
            <Checkbox
              id="selectAll"
              label={content.select_all}
              value={content.select_all}
              onChange={toggleAllCheckboxes}
              checked={allUnitIds.length === checkedItems.length}
            />
          </div>
          <div className={styles.line} />
        </div>
        <div className={styles.unitsContainer}>{getContent()}</div>
      </div>
    </ScrollContainer>
  );
}

ManageProductLocksForm.propTypes = {
  productDetails: PropTypes.object.isRequired,
  productStructureUnits: PropTypes.array.isRequired,
  productLocks: PropTypes.object,
  content: PropTypes.object.isRequired,
  onSave: PropTypes.func
};

export default connect(({ productLocks, productStructure }) => ({
  productStructureUnits: productStructure.productStructure,
  productLocks: productLocks.productLocks
}))(ManageProductLocksForm);
