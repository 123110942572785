import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { parse as parseQueryString } from 'query-string';

import { useWizard, Heading, RadioButtonGroup } from '@oup/shared-front-end';
import { useNavigate } from 'react-router-dom';
import { AUTH_OPTIONS } from '../../globals/appConstants.js';
import { HubIllustrationConstants, HubIllustrationAltText } from '../../globals/hubConstants';
import { getIdpLoginUrl } from '../../redux/sagas/preRender/auth/getCredentials/redirectToIdP.saga.js';
import styles from './RegisterOrSignInStep.scss';

function RegisterOrSignInStep({ content, platform }) {
  const navigate = useNavigate();

  const { setNextStepDisabled, stepHandler } = useWizard();
  const [selectedUserOption, setSelectedUserOption] = useState('');

  stepHandler(async () => {
    if (selectedUserOption === AUTH_OPTIONS.LOGIN) {
      const url = await getIdpLoginUrl(null, { platform });
      window.location.href = url;
    } else {
      navigate('/register');
    }
  });

  useEffect(() => {
    setNextStepDisabled(!selectedUserOption);
  }, [selectedUserOption]);

  return (
    <div data-testid="REGISTER_OR_SIGNIN_WIZARD_STEP_1" className={styles.stepContainer}>
      <div className={styles.leftContainer}>
        <Heading text={content.register_or_sign_in} size="medium" className={styles.heading} />
        <RadioButtonGroup
          variant="outline"
          name="registerOrSignIn"
          classnames={{ container: styles.radioButtonGroupContainer }}
          checkedValue={selectedUserOption}
          legend={
            selectedUserOption === AUTH_OPTIONS.REGISTER ? content.choose_register_legend : content.choose_login_legend
          }
          radioGroup={[
            {
              id: AUTH_OPTIONS.LOGIN,
              label: content.sign_in_option,
              value: AUTH_OPTIONS.LOGIN
            },
            {
              id: AUTH_OPTIONS.REGISTER,
              label: content.register_option,
              value: AUTH_OPTIONS.REGISTER
            }
          ]}
          onChange={e => setSelectedUserOption(e.target.value)}
        />
      </div>
      <div className={styles.rightContainer}>
        <img className={styles.image} src={HubIllustrationConstants.SUCCESS} alt={HubIllustrationAltText.SUCCESS} />
      </div>
    </div>
  );
}

RegisterOrSignInStep.propTypes = { content: PropTypes.object.isRequired, platform: PropTypes.string };

export default connect(() => {
  const queryString = parseQueryString(window.location.search) || {};
  return {
    platform: queryString.providerId || queryString.platform
  };
})(RegisterOrSignInStep);
