import React, { Component } from 'react';
import PropTypes from 'prop-types';
// Lodash services
import { get } from 'lodash';
// Services
import { connect } from 'react-redux';
import withRouter from '../../utils/withRouter';
import compose from '../../utils/compose/compose.js';
import getClassLinkByRole from '../../structure/HubDashboardLayout/Services/getClassLinkByRole';
import getDropdownItems from '../HubDropdownMenu/Services/getDropdownItems';
import redirectToProgress from '../../structure/HubDashboardLayout/Services/redirectToGradebookProgress';
// Style
import styles from './ClassTile.scss';
// Constants
import userRoles, { roleIsAtLeast } from '../../globals/userRoles';
// Components
import Link from '../Link/Link';
import SVGIcon, { GLYPHS } from '../SVGIcon/SVGIcon';
import ActionPanel from '../ActionPanel/ActionPanel';
import HubDropdownMenu from '../HubDropdownMenu/HubDropdownMenu';
import TeacherClassCode from '../TeacherClassCode/TeacherClassCode';
import copyToClipboard from '../../structure/HubOrganizationLayout/Utils/copyToClipboard';

// Redux
import { openEditClassroomModal } from '../../redux/actions/hubUi';

class ClassTile extends Component {
  static getTeacherName = teacherNameList =>
    teacherNameList.length === 1 ? `Teacher: ${teacherNameList[0]}` : `${teacherNameList.length} teachers`;

  getDropdownOptions = () => {
    const { item, displayClassCode } = this.props;

    return { isClassCodeEnabled: displayClassCode && item.joiningCode?.enabled };
  };

  getDropdownActions = () => {
    const { customOrgId, item, id: classId, setClassToArchive } = this.props;

    const copyText = copyToClipboard();
    const classCode = `${customOrgId}-${item?.joiningCode?.code}`;

    return {
      setClassToArchive: event => {
        event.preventDefault();
        setClassToArchive(classId);
      },
      copyClassCode: () => {
        copyText(classCode);
      }
    };
  };

  displayDropdownMenuPanel = () => {
    const {
      props: { item, userId, userRole }
    } = this;

    if (roleIsAtLeast(userRoles.TEACHER_ADMIN, userRole)) return true;
    if (userRole === userRoles.TEACHER) {
      // Check if the teacher is assigned to the class
      const teacherIdList = get(item, ['teacherIdList'], []);
      return teacherIdList.includes(userId);
    }
    return false;
  };

  render() {
    const {
      props: {
        id,
        item,
        to,
        hubContent,
        currentOrganisationId,
        userRole,
        history: { push },
        customOrgId,
        displayClassCode,
        openEditClassroom
      },
      displayDropdownMenuPanel
    } = this;

    const actions = [
      {
        tooltip: hubContent.action_button_class_gradebook,
        glyph: GLYPHS.ICON_PROGRESS,
        onClick: event => redirectToProgress(event, to, currentOrganisationId, push),
        hasAccess: roleIsAtLeast(userRoles.TEACHER, userRole)
      }
    ];

    const displayActionPanel = actions.some(action => action.hasAccess);
    const displayDropdownMenu = displayDropdownMenuPanel();

    return (
      <div data-testid="CLASS_TILE" data-list="tile">
        <Link className={styles.classroom} to={getClassLinkByRole(to, currentOrganisationId, userRole)}>
          <div className={styles.imgContainer}>
            <div className={styles.svgContainer}>
              <SVGIcon glyph={GLYPHS.ICON_CLASS} />
            </div>
          </div>
          <div className={styles.classroomDetails}>
            <div className={styles.textContainer}>
              <span className={styles.classroomName}>{item.name}</span>
              <div>
                {get(item, ['teacherNameList', 'length']) ? ClassTile.getTeacherName(item.teacherNameList) : null}
              </div>
              {displayClassCode && (
                <TeacherClassCode
                  joiningCode={item.joiningCode}
                  hubContent={hubContent}
                  customClassname="classCodeInClassTile"
                  customOrgId={customOrgId}
                  isClassTile
                  labelText={hubContent.class_code_label}
                  displayCopyButton={false}
                  openEditClassroomModal={openEditClassroom}
                />
              )}
            </div>
            {displayActionPanel && <ActionPanel actions={actions} />}
          </div>
          {displayDropdownMenu && (
            <HubDropdownMenu
              dropdownData={getDropdownItems(
                'classTile',
                hubContent,
                this.getDropdownOptions(),
                this.getDropdownActions(),
                userRole
              )}
              parentId={id}
              customClassname={styles.dropDownMenu}
            />
          )}
        </Link>
      </div>
    );
  }
}

ClassTile.propTypes = {
  id: PropTypes.string,
  item: PropTypes.object,
  to: PropTypes.string,
  hubContent: PropTypes.object,
  history: PropTypes.object.isRequired,
  currentOrganisationId: PropTypes.string,
  userId: PropTypes.string.isRequired,
  userRole: PropTypes.string,
  setClassToArchive: PropTypes.func,
  customOrgId: PropTypes.string,
  displayClassCode: PropTypes.bool,
  openEditClassroom: PropTypes.func
};

ClassTile.defaultProps = {
  item: {},
  to: '',
  currentOrganisationId: ''
};

export default compose(withRouter, connect(null, { openEditClassroom: openEditClassroomModal }))(ClassTile);
