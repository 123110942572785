import React from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import { TextLink, WizardHero } from '@oup/shared-front-end';

import compose from '../../../utils/compose/compose.js';
import withLocalizedContent from '../../../language/withLocalizedContent';
import { clearWizardState } from '../../../redux/actions/placementTestOnboardingWizard';
import { closeForm } from '../../../redux/reducers/placementTestSessionCreate';
import { closePlacementTestOnboardingWizard } from '../../../redux/actions/hubUi';
import { HubIllustrationAltText, HubIllustrationConstants } from '../../../globals/hubConstants';

import styles from './PlacementTestSessionIntroductionWizardStep.scss';
import getOptProductsCatalogueUrl from '../../../utils/opt/getOptProductsCatalogueUrl';

function PlacementTestSessionIntroductionWizardStep({
  localizedContent: { hubGlossary: hubContent, placementTests: placementTestsContent }
}) {
  const currentOrganisationId = useSelector(state => state.identity.currentOrganisationId);
  const { customId: customOrgId = '' } = useSelector(state => state.organisations.data[currentOrganisationId]);

  return (
    <div
      data-testid="ONBOARDING_WIZARD_CREATE_A_PLACEMENT_TEST_SESSION_INTRODUCTION_WIZARD_STEP_CONTAINER"
      className={styles.introductionContainer}
    >
      <WizardHero
        heading={{ text: placementTestsContent.placement_test_session_creation_introduction_step_title, size: 'large' }}
        subHeading={placementTestsContent.placement_test_session_creation_introduction_step_subtitle}
        image={{
          src: HubIllustrationConstants.SETUP,
          alt: HubIllustrationAltText.SETUP
        }}
        variant="introduction"
        content={
          <>
            <ol className={styles.organizationSetupSteps}>
              <li>
                <p>{placementTestsContent.placement_test_session_creation_introduction_step_first_step_bold}</p>
                <p>{placementTestsContent.placement_test_session_creation_introduction_step_first_step}</p>
              </li>
              <li>
                <p>{placementTestsContent.placement_test_session_creation_introduction_step_second_step_bold}</p>
                <p>{placementTestsContent.placement_test_session_creation_introduction_step_second_step}</p>
              </li>
              <li>
                <p>{placementTestsContent.placement_test_session_creation_introduction_step_third_step_bold}</p>
                <p>{placementTestsContent.placement_test_session_creation_introduction_step_third_step}</p>
              </li>

              <li>
                <p>{placementTestsContent.placement_test_session_creation_introduction_step_forth_step_bold}</p>
                <p>{placementTestsContent.placement_test_session_creation_introduction_step_forth_step}</p>
              </li>
            </ol>
            <TextLink to={hubContent.help_and_support_link_to} target="_blank">
              {hubContent.organization_page_learn_more_about_placement_tests}
            </TextLink>
            <br /> <br />
            <TextLink to={getOptProductsCatalogueUrl(customOrgId)} target="_blank">
              {placementTestsContent.placement_test_session_creation_introduction_step_second_step_bold}
            </TextLink>
          </>
        }
      />
    </div>
  );
}

PlacementTestSessionIntroductionWizardStep.propTypes = {
  localizedContent: PropTypes.object.isRequired
};

export default compose(
  withLocalizedContent('hubGlossary', 'placementTests'),
  connect(null, {
    closeWizardAction: closePlacementTestOnboardingWizard,
    clearWizardStateAction: clearWizardState,
    clearPlacementTestSessionCreateStateAction: closeForm
  })
)(PlacementTestSessionIntroductionWizardStep);
