import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useWizard, Heading } from '@oup/shared-front-end';

import compose from '../../../utils/compose/compose.js';
import PlacementTestStartEndDate from './PlacementTestStartEndDate';
import PlacementTestExtraTime from './PlacementTestExtraTime';
import { setEditedPlacementTest } from '../../../redux/reducers/placementTestSessionCreate';
import withLocalizedContent from '../../../language/withLocalizedContent';
import { featureIsEnabled } from '../../../globals/envSettings';

import { createPlacementTestSessionRequest } from '../../../redux/actions/placementTestOnboardingWizard';

import styles from './PlacementTestSchedule.scss';
import animationTimeout from '../../ClassOnboardingWizard/animationUtils/animationTimeout';
import animationStyles from '../../ClassOnboardingWizard/animationUtils/animationStyles.scss';

function PlacementTestSchedule({
  placementTestSessionNameValue,
  testStartDate = '',
  testEndDate = '',
  extraTimePerItem = 0,
  setEditedPlacementTestAction,
  placementTestSessionIdCreated,
  placementTestSessionId,
  localizedContent: { placementTests: placementTestsContent },
  orgId,
  userId,
  createPlacementTestSessionRequestAction,
  editedPlacementTest,
  showResultsToStudents,
  sendEmailsToStudents
}) {
  const [editedStartDate, setEditedStartDate] = useState(testStartDate);
  const [editedEndDate, setEditedEndDate] = useState(testEndDate);
  const [editedExtraTimePerItem, setEditedExtraTimePerItem] = useState(extraTimePerItem);

  const [editedStartDateIsInvalid, setEditedStartDateIsInvalid] = useState(false);
  const [editedEndDateIsInvalid, setEditedEndDateIsInvalid] = useState(false);
  const [editedExtraTimePerItemIsInvalid, setEditedExtraTimePerItemIsInvalid] = useState(false);

  const { stepHandler, loading, setNextStepDisabled, nextStep } = useWizard();
  const [stepOut, setStepOut] = useState(false);
  const fadeOut = stepOut ? animationStyles.fadeOut : '';

  const canShowStudentResults = featureIsEnabled('show-test-results-to-students');

  setNextStepDisabled(loading || editedStartDateIsInvalid || editedEndDateIsInvalid || editedExtraTimePerItemIsInvalid);

  stepHandler(async () => {
    if (placementTestSessionIdCreated || placementTestSessionId) {
      return;
    }

    setEditedPlacementTestAction({
      testStartDate: editedStartDate,
      testEndDate: editedEndDate,
      extraTimePerItem: editedExtraTimePerItem
    });

    createPlacementTestSessionRequestAction({
      userId,
      orgId,
      placementTestSessionNameValue,
      placementTest: {
        ...editedPlacementTest,
        ...(canShowStudentResults ? { showResultsToStudents } : {}),
        ...(!canShowStudentResults ? { sendEmailsToStudents } : {})
      }
    });

    setStepOut(true);
    await animationTimeout();
    throw new Error();
  });

  useEffect(() => {
    if (placementTestSessionIdCreated) {
      nextStep();
    }
  }, [placementTestSessionIdCreated]);

  return (
    <div
      data-testid="ONBOARDING_WIZARD_CREATE_A_PLACEMENT_TEST_SESSION_SCHEDULE_CONTAINER"
      className={`${fadeOut} ${styles.stepContainer}`}
    >
      <Heading
        text={`${placementTestsContent.create_placement_test_schedule_title} ${placementTestSessionNameValue}`}
        size="medium"
        className={styles.heading}
      />
      <PlacementTestStartEndDate
        testStartDate={editedStartDate}
        setTestStartDate={setEditedStartDate}
        setTestStartDateIsInvalid={setEditedStartDateIsInvalid}
        testEndDate={editedEndDate}
        setTestEndDate={setEditedEndDate}
        setTestEndDateIsInvalid={setEditedEndDateIsInvalid}
      />
      <PlacementTestExtraTime
        extraTimePerItem={editedExtraTimePerItem}
        setExtraTimePerItem={setEditedExtraTimePerItem}
        setExtraTimePerItemIsInvalid={setEditedExtraTimePerItemIsInvalid}
      />
    </div>
  );
}

PlacementTestSchedule.propTypes = {
  placementTestSessionNameValue: PropTypes.string.isRequired,
  testStartDate: PropTypes.string.isRequired,
  testEndDate: PropTypes.string.isRequired,
  extraTimePerItem: PropTypes.number.isRequired,
  setEditedPlacementTestAction: PropTypes.func,
  placementTestSessionIdCreated: PropTypes.string,
  placementTestSessionId: PropTypes.string,
  localizedContent: PropTypes.object.isRequired,
  createPlacementTestSessionRequestAction: PropTypes.func,
  editedPlacementTest: PropTypes.object,
  userId: PropTypes.string,
  orgId: PropTypes.string,
  sendEmailsToStudents: PropTypes.bool,
  showResultsToStudents: PropTypes.bool
};

export default compose(
  withLocalizedContent('placementTests'),
  connect(
    ({ placementTestSessionCreate, placementOnboardingWizard, identity }) => ({
      placementTestSessionNameValue: placementTestSessionCreate.placementTestSessionNameValue,
      testStartDate: placementTestSessionCreate.placementTest.testStartDate,
      testEndDate: placementTestSessionCreate.placementTest.testEndDate,
      extraTimePerItem: placementTestSessionCreate.placementTest.extraTimePerItem,
      testStartDateIsInvalid: placementTestSessionCreate.testStartDateIsInvalid,
      placementTestSessionIdCreated: placementOnboardingWizard.placementTestSessionIdCreated,
      userId: identity.userId,
      orgId: identity.currentOrganisationId,
      editedPlacementTest: placementTestSessionCreate.editedPlacementTest,
      placementTestSessionId: placementTestSessionCreate.placementTestSessionId,
      showResultsToStudents: placementTestSessionCreate.placementTest.showResultsToStudents,
      sendEmailsToStudents: placementTestSessionCreate.placementTest.sendEmailsToStudents
    }),
    {
      setEditedPlacementTestAction: setEditedPlacementTest,
      createPlacementTestSessionRequestAction: createPlacementTestSessionRequest
    }
  )
)(PlacementTestSchedule);
