import React from 'react';
import PropTypes from 'prop-types';
import { useWizard, Footer } from '@oup/shared-front-end';
import { ICON_LEFT } from '@oup/shared-front-end/svg/oup';

function ChooseRoleWizardFooter({ content }) {
  const { step, nextStep, prevStep, nextStepDisabled } = useWizard();

  return (
    <Footer
      primaryButtonAction={nextStep}
      primaryButtonLabel={content.next}
      primaryButtonAttributes={{
        disabled: nextStepDisabled,
        dataAttributes: {
          testid: 'CHOOSE_ROLE_WIZARD_NEXT_BUTTON'
        }
      }}
      secondaryButtonLabel={step !== 0 ? content.back : ''}
      secondaryButtonAttributes={{
        dataAttributes: {
          testid: 'CHOOSE_ROLE_WIZARD_BACK_BUTTON'
        },
        icon: {
          placement: 'left',
          component: <ICON_LEFT />
        }
      }}
      secondaryButtonAction={prevStep}
    />
  );
}

ChooseRoleWizardFooter.propTypes = {
  content: PropTypes.object.isRequired
};

export default ChooseRoleWizardFooter;
