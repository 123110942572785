import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';

import { PLACEMENT_TEST_RESULTS_STATUS, PLACEMENT_TEST_STATUS } from '@oup/shared-node-browser/constants.js';
import compose from '../../utils/compose/compose.js';
import withLocalizedContent from '../../language/withLocalizedContent';
import withBreakpoint from '../../decorators/withBreakpoint';
import { HubLayoutConstants } from '../../globals/hubConstants.js';
import styles from './MyTasks.scss';
import { loadStudentTasksRequest } from '../../redux/actions/placementTests.js';
import { openPlacementTestSessionInformationModal } from '../../redux/actions/hubUi';
import { continueEditedPlacementTest } from '../../redux/reducers/placementTestSessionCreate';
import { resetForm } from '../../redux/reducers/joinClass.reducer';
import HubPageLayout from '../../structure/HubPageLayout/HubPageLayout.js';
import getPageLayoutData from '../../structure/HubDashboardLayout/Utils/getPageLayoutData.js';
import MyTasksList from './MyTasksList';
import UserAccessPlacementTestModal from './UserAccessPlacementTestModal/UserAccessPlacementTestModal';

const getClassIdfromQuery = location => {
  if (!location) return '';
  const { search } = location;
  const classId = search.split('=').at(1);
  return classId;
};

function MyTasks({
  hubContent,
  openPlacementTestSessionInformationModalAction,
  continueEditedPlacementTestAction,
  placementTestId,
  joinPlacementTestWithCodeModalOpen = false,
  resetJoinCodeModalAction,
  loadStudentTasksRequestAction,
  studentTasks
}) {
  const initialEmptyStateText = {
    title: hubContent.my_tasks_to_do_tab_empty_state_title || '',
    bodyText: hubContent.my_tasks_to_do_tab_empty_state_subtitle || ''
  };

  const initialtabTitle = hubContent.my_tasks_to_do_tab;

  const [emptyStateText, setEmptyStateText] = useState(initialEmptyStateText);
  const [tabTitle, setTabTitle] = useState(initialtabTitle);
  const [placementId, setPlacementId] = useState('');

  const params = useParams();
  const location = useLocation();

  useEffect(() => {
    if (!joinPlacementTestWithCodeModalOpen && placementTestId) {
      setPlacementId(placementTestId);
      resetJoinCodeModalAction();
    }
  }, [joinPlacementTestWithCodeModalOpen, placementTestId]);

  let placementTest = null;
  const classId = getClassIdfromQuery(location) || placementId;

  if (classId) {
    placementTest = studentTasks.placementTests.find(test => test._id === classId);
  }

  const _getStudentTasks = filters => {
    const payload = {
      status: filters
    };
    loadStudentTasksRequestAction(payload);
  };

  useEffect(() => {
    if (placementTest) {
      continueEditedPlacementTestAction({
        placementTestSessionId: placementTest._id,
        placementTestSessionNameValue: placementTest.classGroup.className,
        joiningCode: placementTest?.code,
        placementTest: placementTest.configurations.CONFIGURATION_PLACEMENT_TEST,
        orgId: placementTest?.classGroup?.orgId
      });
      if (!joinPlacementTestWithCodeModalOpen) {
        openPlacementTestSessionInformationModalAction();
        setPlacementId('');
      }
    }
  }, [placementTest, joinPlacementTestWithCodeModalOpen]);

  useEffect(() => {
    const { view = PLACEMENT_TEST_RESULTS_STATUS.ACTIVE } = params;

    switch (view) {
      case PLACEMENT_TEST_STATUS.MISSING.toLowerCase():
        _getStudentTasks(PLACEMENT_TEST_RESULTS_STATUS.MISSED);
        setEmptyStateText({
          title: hubContent.my_tasks_missing_tab_empty_state_title,
          bodyText: hubContent.my_tasks_missing_tab_empty_state_subtitle || ''
        });
        setTabTitle(hubContent?.my_tasks_missing_tab);
        break;
      case PLACEMENT_TEST_RESULTS_STATUS.COMPLETED.toLowerCase():
        _getStudentTasks(PLACEMENT_TEST_RESULTS_STATUS.COMPLETED);
        setEmptyStateText({
          title: hubContent.my_tasks_completed_tab_empty_state_title || '',
          bodyText: hubContent.my_tasks_completed_tab_empty_state_subtitle || ''
        });
        setTabTitle(hubContent?.my_tasks_completed_tab);
        break;
      case PLACEMENT_TEST_RESULTS_STATUS.UPCOMING.toLowerCase():
        _getStudentTasks(PLACEMENT_TEST_RESULTS_STATUS.UPCOMING);
        setEmptyStateText({
          title: hubContent.my_tasks_upcoming_tab_empty_state_title || '',
          bodyText: hubContent.my_tasks_upcoming_tab_empty_state_subtitle || ''
        });
        setTabTitle(hubContent.my_tasks_upcoming_tab);
        break;
      default:
        _getStudentTasks(PLACEMENT_TEST_RESULTS_STATUS.ACTIVE.toLowerCase());
        setEmptyStateText({
          title: hubContent.my_tasks_to_do_tab_empty_state_title || '',
          bodyText: hubContent.my_tasks_to_do_tab_empty_state_subtitle || ''
        });
        setTabTitle(hubContent.my_tasks_to_do_tab);
        break;
    }
  }, [params]);

  const pageLayout = getPageLayoutData({
    pathname: HubLayoutConstants.PATH_NAMES.TASKS_PATH,
    content: hubContent,
    course: false,
    classData: false,
    orgData: false,
    userData: false,
    isLmsLtiTool: false,
    currentOrganisationLti: false,
    numberOfTodoTasks: studentTasks.numberOfTodoTasks
  });

  return (
    <HubPageLayout {...pageLayout}>
      <div>
        <Helmet title={hubContent.my_tasks} titleTemplate={hubContent.page_title_template} />
        <div className={styles.content}>
          <MyTasksList tabTitle={tabTitle} emptyStateText={emptyStateText} />
        </div>
      </div>
      <UserAccessPlacementTestModal />
    </HubPageLayout>
  );
}

export default compose(
  connect(
    ({
      joinClass: { placementTestId },
      identity: { currentOrganisationId },
      loadPlacementTestsReducer: { studentTasks },
      hubUi: { joinPlacementTestWithCodeModalOpen }
    }) => ({
      currentOrganisationId,
      placementTestId,
      joinPlacementTestWithCodeModalOpen,
      studentTasks
    }),
    {
      openPlacementTestSessionInformationModalAction: openPlacementTestSessionInformationModal,
      continueEditedPlacementTestAction: continueEditedPlacementTest,
      resetJoinCodeModalAction: resetForm,
      loadStudentTasksRequestAction: loadStudentTasksRequest
    }
  ),
  withLocalizedContent('hubGlossary'),
  withBreakpoint
)(MyTasks);

MyTasks.propTypes = {
  hubContent: PropTypes.object.isRequired,
  firstName: PropTypes.string.isRequired,
  selectMyTasksViewAction: PropTypes.func.isRequired,
  currentOrganisationId: PropTypes.string,
  getPlacementTestsAction: PropTypes.func,
  openPlacementTestSessionInformationModalAction: PropTypes.func,
  continueEditedPlacementTestAction: PropTypes.func,
  placementTestId: PropTypes.string,
  joinPlacementTestWithCodeModalOpen: PropTypes.bool,
  resetJoinCodeModalAction: PropTypes.func,
  loadStudentTasksRequestAction: PropTypes.func,
  studentTasks: PropTypes.array,
  numberOfTodoTasks: PropTypes.number
};
