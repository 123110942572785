import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { connect, useSelector } from 'react-redux';
import { Footer } from '@oup/shared-front-end';
import compose from '../../../../utils/compose/compose.js';
import PanelHeading from '../../../../components/PanelHeading/PanelHeading';
import ScrollContainer from '../../../../components/ScrollContainer/ScrollContainer';
import { updatePlacementTestSessionRequest } from '../../../../redux/actions/placementTestOnboardingWizard';
import { setPlacementTest, setEditedPlacementTest } from '../../../../redux/reducers/placementTestSessionCreate';
import withLocalizedContent from '../../../../language/withLocalizedContent';
import NotificationsAndReminders from '../../../../components/PlacementTestOnBoardingWizard/EmailNotificationsAndReminders/NotificationsAndReminders';
import style from '../../../../components/PlacementTestOnBoardingWizard/EmailNotificationsAndReminders/NotificationsAndReminders.scss';
import closeBtnStyle from '../../../../components/SidePanel/SidePanel.scss';
import useNotifications from '../../../../utils/hooks/opt/useNotifications';
import PanelNavigationLink from '../../../../components/PanelNavigationLink/PanelNavigationLink';

function PlacementTestEditNotificationsAndReminders({
  panelSessionName,
  updatePlacementTestSessionRequestAction,
  setPlacementTestAction,
  setEditedPlacementTestAction,
  cancelNotificationsAndReminders,
  localizedContent: { placementTests: placementTestsContent, hubGlossary: hubContent, sidePanel: sidePanelContent }
}) {
  const {
    placementTestSessionCreate: {
      placementTestSessionId,
      placementTestSessionNameValue,
      placementTest,
      editedPlacementTest
    },
    orgId
  } = useSelector(({ placementTestSessionCreate, identity }) => ({
    placementTestSessionCreate,
    orgId: identity.currentOrganisationId
  }));

  const { initialStateRef, state, setState, onStateUpdate } = useNotifications({
    placementTest,
    editedPlacementTest,
    setEditedPlacementTestAction
  });

  const onSave = useCallback(
    e => {
      updatePlacementTestSessionRequestAction({
        orgId,
        sessionId: placementTestSessionId,
        placementTestSessionNameValue,
        placementTest: {
          sendEmailsToStudents: state.sendEmailsToStudents,
          notificationReminders: state.notificationReminders
        }
      });
      setPlacementTestAction({
        sendEmailsToStudents: state.sendEmailsToStudents,
        notificationReminders: state.notificationReminders
      });
      cancelNotificationsAndReminders(e, true);
    },
    [updatePlacementTestSessionRequestAction, setPlacementTestAction, cancelNotificationsAndReminders, state]
  );

  const onCancel = useCallback(
    e => {
      setState(initialStateRef.current);
      setEditedPlacementTestAction(initialStateRef.current);
      cancelNotificationsAndReminders(e);
    },
    [setEditedPlacementTestAction, cancelNotificationsAndReminders]
  );

  return (
    <>
      <nav aria-label="Close" className={closeBtnStyle.closeToolbar}>
        <PanelNavigationLink text={sidePanelContent.close_action_text} isLhs={false} action={onCancel} />
      </nav>

      <ScrollContainer
        headerContent={
          <PanelHeading
            title={placementTestsContent.placement_test_manage_notifications_and_reminders}
            subtitle={`${hubContent.for_label} <strong>${panelSessionName}</strong>`}
          />
        }
        footerContent={
          <Footer
            primaryButtonLabel={hubContent.save}
            secondaryButtonLabel={hubContent.cancel}
            primaryButtonAction={onSave}
            secondaryButtonAction={onCancel}
            primaryButtonAttributes={{
              disabled: !state.isTouched || !state.isValid
            }}
          />
        }
      >
        <div className={style.notificationsAndRemindersContainer}>
          <NotificationsAndReminders
            sendEmailsToStudents={state.sendEmailsToStudents}
            notificationReminders={state.notificationReminders}
            initialState={initialStateRef.current}
            onStateUpdate={onStateUpdate}
          />
        </div>
      </ScrollContainer>
    </>
  );
}

PlacementTestEditNotificationsAndReminders.propTypes = {
  panelSessionName: PropTypes.string.isRequired,
  updatePlacementTestSessionRequestAction: PropTypes.func.isRequired,
  setPlacementTestAction: PropTypes.func,
  setEditedPlacementTestAction: PropTypes.func,
  cancelNotificationsAndReminders: PropTypes.func.isRequired,
  localizedContent: PropTypes.object
};

export default compose(
  withLocalizedContent('hubGlossary', 'placementTests', 'sidePanel'),
  connect(null, {
    updatePlacementTestSessionRequestAction: updatePlacementTestSessionRequest,
    setPlacementTestAction: setPlacementTest,
    setEditedPlacementTestAction: setEditedPlacementTest
  })
)(PlacementTestEditNotificationsAndReminders);
