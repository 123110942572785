import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import APP_CONSTANTS from '@oup/shared-node-browser/constants';
import { TextLink } from '@oup/shared-front-end';
import styles from './HubSupportContactUsHeader.scss';

function HubSupportContactUsHeader({ customClass, content }) {
  return (
    <div className={classnames(styles.entityPageHeader, customClass)}>
      <div className="grid">
        <div className="row">
          <div className="col">
            <div className={styles.headingAndButton}>
              <h1>{content.hub_support_page_title_header}</h1>
              <p>{content.support_contact_us_paragraph1}</p>
              <p>
                {content.support_contact_us_paragraph2}&nbsp;
                <TextLink target="_blank" to={APP_CONSTANTS.HUB_SUPPORT_CONTACT_US}>
                  {content.support_contact_us_link_text}
                </TextLink>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

HubSupportContactUsHeader.propTypes = {
  customClass: PropTypes.any,
  content: PropTypes.object.isRequired
};
export default HubSupportContactUsHeader;
