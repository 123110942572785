import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Wizard } from '@oup/shared-front-end';
import { connect } from 'react-redux';
import { getSelfSelectedRole } from '../../globals/userRoles.js';
import OrganizationSetupWizard from './OrganizationSetupWizard.js';
import OnboardingStudentSetupWizard from './OnboardingStudentSetupWizard/OnboardingStudentSetupWizard.js';
import WizardFooter from './WizardFooter.js';

import { openJoinClassWithCodeModal, showSelfSelectRoleModal } from '../../redux/actions/hubUi.js';

import ChooseRole from './ChooseRole/ChooseRole.js';
import WizardHeader from './WizardHeader.js';
import styles from './SelfRegisteredUserOnboardingWizard.scss';
import { HubLayoutConstants } from '../../globals/hubConstants.js';

function SelfRegisteredUserOnboardingWizard({ firstName, selectRole }) {
  const selfSelectedRole = getSelfSelectedRole();
  const [chooseRoleWizardCompleted, setChooseRoleWizardCompleted] = useState(!!selfSelectedRole);
  const [selectedRole, setSelectedRole] = useState(selfSelectedRole);

  return (
    <div className={styles.wizardContainer} data-testid="SELF_REGISTERED_USER_ONBOARDING_WIZARD_CONTAINER">
      <Wizard header={<WizardHeader />} footer={<WizardFooter />}>
        <ChooseRole
          selectRoleAction={selectRole}
          selectedRole={selectedRole}
          setSelectedRole={setSelectedRole}
          setChooseRoleWizardCompleted={setChooseRoleWizardCompleted}
        />
      </Wizard>
      {chooseRoleWizardCompleted && selectedRole === HubLayoutConstants.SELF_SELECT_ROLES[0].key && (
        <OnboardingStudentSetupWizard firstName={firstName} />
      )}
      {chooseRoleWizardCompleted && selectedRole === HubLayoutConstants.SELF_SELECT_ROLES[1].key && (
        <OrganizationSetupWizard firstName={firstName} />
      )}
    </div>
  );
}

SelfRegisteredUserOnboardingWizard.propTypes = {
  firstName: PropTypes.string.isRequired,
  selectRole: PropTypes.func.isRequired
};

export default connect(null, {
  openJoinClassWithCodeModalAction: openJoinClassWithCodeModal,
  showSelfSelectRoleModalAction: showSelfSelectRoleModal
})(SelfRegisteredUserOnboardingWizard);
