import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import compose from '../../../utils/compose/compose.js';
import Link from '../../../components/Link/Link';
import styles from './HubSubHeader.scss';
import USER_ROLES, { roleIsAtLeast } from '../../../globals/userRoles.js';
import Button, { GLYPHS } from '../../../components/Button/Button.js';
import withLocalizedContent from '../../../language/withLocalizedContent';
import { isAdminOrSupport as isAdminOrSupportSelector } from '../../../redux/selectors/authorization/user';
import { isCesMode } from '../../../utils/platform.js';
import { HubLayoutConstants } from '../../../globals/hubConstants';

const profileUrlRegex = /^\/myProfile.*$/;
const schoolUrlRegex = /^\/(mySchool|org).*$/;
const dashboardUrlRegex = /^\/dashboard.*$/;
const productsUrlRegex = /^\/products.*$/;

function HubSubHeader({
  localizedContent: { siteHeaders: content },
  pathname,
  isMemberOfOrg,
  menuOpen,
  isAdminOrSupport,
  isAtLeastTeacher
}) {
  if (!isMemberOfOrg) return null;

  return (
    <div id="subNavMenu">
      <nav className={classnames(styles.quickNavLinks, menuOpen && styles.open)} aria-label="Secondary">
        <div className="grid">
          <div className="row">
            <div className="col">
              <ul role="tablist">
                {/* Everyone can see this link: */}
                <li role="tab" aria-controls="maincontent" aria-selected={profileUrlRegex.test(pathname)}>
                  <Link
                    id="myProfileLink"
                    to={HubLayoutConstants.PATH_NAMES.PROFILE_PAGE}
                    className={profileUrlRegex.test(pathname) ? styles.selected : null}
                  >
                    {content.my_profile_text}
                  </Link>
                </li>

                {/* Everyone except learners and noobies can see this link: */}
                {isAtLeastTeacher ? (
                  <li role="tab" aria-controls="maincontent" aria-selected={schoolUrlRegex.test(pathname)}>
                    <Link
                      id="mySchoolLink"
                      to={HubLayoutConstants.PATH_NAMES.SCHOOL_PAGE}
                      className={schoolUrlRegex.test(pathname) ? styles.selected : null}
                    >
                      {content.my_school_text}
                    </Link>
                  </li>
                ) : null}

                {isAdminOrSupport ? (
                  <li role="tab" aria-controls="maincontent" aria-selected={dashboardUrlRegex.test(pathname)}>
                    <Link
                      id="dashboardLink"
                      to={HubLayoutConstants.PATH_NAMES.DASHBOARD_ADMIN}
                      className={dashboardUrlRegex.test(pathname) ? styles.selected : null}
                    >
                      {content.dashboard_text}
                    </Link>
                  </li>
                ) : null}

                {isAdminOrSupport ? (
                  <li role="tab" aria-controls="maincontent" aria-selected={productsUrlRegex.test(pathname)}>
                    <Link
                      id="productsLink"
                      to={HubLayoutConstants.PATH_NAMES.PRODUCTS_ADMIN}
                      className={productsUrlRegex.test(pathname) ? styles.selected : null}
                    >
                      {content.products_tab_text}
                    </Link>
                  </li>
                ) : null}

                {/* Support link (Note this is replicated in SiteHeader menu for sm+ devices */}
                <li className="sm-hide">
                  <div className="pad2">
                    <Button
                      id="supportLink"
                      to={HubLayoutConstants.PATH_NAMES.SUPPORT_PATH}
                      glyph={isCesMode() ? GLYPHS.ICON_HELP : null}
                      fullWidth
                      text={content.help_and_support}
                    />
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
}

HubSubHeader.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  pathname: PropTypes.string.isRequired,
  menuOpen: PropTypes.bool.isRequired,
  isAdminOrSupport: PropTypes.bool.isRequired,
  isAtLeastTeacher: PropTypes.bool.isRequired,
  isMemberOfOrg: PropTypes.bool.isRequired
};

export default compose(
  connect(state => ({
    pathname: window.location.pathname,
    isMemberOfOrg: !!state.identity.role,
    isAtLeastTeacher:
      roleIsAtLeast(USER_ROLES.TEACHER, state.identity.role) && USER_ROLES.OUP_CONTENT !== state.identity.role,
    isAdminOrSupport: isAdminOrSupportSelector(state),
    orgSubscriptions: state.subscriptions
  })),
  withLocalizedContent('siteHeaders')
)(HubSubHeader);
