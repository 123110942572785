import React from 'react';
import PropTypes from 'prop-types';
// Style
import styles from './PeopleFooter.scss';
// Components
import breakpoints from '../../../globals/breakpoints';
import Button, { buttonTypes } from '../../../components/Button/Button';

function PeopleFooter({
  widgetText,
  actions,
  isModalOpen = false,
  selectedUsers = [],
  context = '',
  contextName = '',
  breakpoint
}) {
  const selectedItems = selectedUsers.length;
  const shownActions = actions.filter(action => action.title);
  return (
    <div className={`${styles.container} ${selectedItems && !isModalOpen ? styles.open : ''}`}>
      <div className={styles.widgetContainer}>
        <span>{selectedItems}</span>
        <span>{widgetText}</span>
      </div>
      <div
        className={
          shownActions.length < 3 || breakpoint !== breakpoints.XXS
            ? styles.buttonContainer
            : styles.buttonStackedContainer
        }
      >
        {shownActions?.length
          ? shownActions.map(({ title, action }, index) => (
              <div key={index}>
                <Button
                  key={title}
                  onClick={e => action(e, { context, selectedUsers, contextName })}
                  text={title}
                  type={buttonTypes.ACTION_BLOCK}
                />
              </div>
            ))
          : ''}
      </div>
    </div>
  );
}

PeopleFooter.propTypes = {
  widgetText: PropTypes.string.isRequired,
  actions: PropTypes.array.isRequired,
  isModalOpen: PropTypes.bool,
  context: PropTypes.string,
  selectedUsers: PropTypes.array,
  contextName: PropTypes.string,
  breakpoint: PropTypes.string
};

export default PeopleFooter;
