import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

import { connect } from 'react-redux';
import { Heading } from '@oup/shared-front-end';

import compose from '../../../utils/compose/compose.js';
import { initialiseInstance, setFilter, setSort, setTerm } from '../../../redux/reducers/data/search.reducer';
import { handlePeopleSelectionPage, resetPagination } from '../../../redux/actions/hubPeople';
import { toggleArchiveClassModal, openEditClassroomModal, openOnboardingWizard } from '../../../redux/actions/hubUi';
import {
  setClassroomsToArchive,
  selectClassForArchive,
  deselectClassForArchive
} from '../../../redux/reducers/classroomArchive';

import styles from './OrganizationClasses.scss';

import {
  getOrganizationClassesHeaderItems,
  HubLayoutConstants,
  HubIllustrationConstants,
  HubIllustrationAltText
} from '../../../globals/hubConstants';
import { GLYPHS } from '../../../components/SVGIcon/SVGIcon';
import userRoles from '../../../globals/userRoles';
import breakpoints from '../../../globals/breakpoints';
import colors from '../../../globals/colors';
import { featureIsEnabled } from '../../../globals/envSettings';
import filterClasses from '../../HubDashboardLayout/Services/filterClasses';
import loadClassDetails from '../../HubDashboardLayout/Services/loadClassDetails';
import redirectToGradebookProgress from '../../HubDashboardLayout/Services/redirectToGradebookProgress';
import getOrganizationClassLink from '../../HubDashboardLayout/Utils/getOrganizationClassLink';
import { classSortOptions } from '../../../globals/hubSearchFilter';

import SubSectionSkeletonLoader from '../../../components/SkeletonLoader/SubSectionSkeletonLoader';
import PaginationButtons from '../../../components/PaginationButtons/PaginationButtons';
import SlideupPanel from '../../../components/SlideupPanel/SlideupPanel';
import Classes from './Classes';
import Button, { buttonTypes } from '../../../components/Button/Button';
import HubRowHeader from '../../../components/HubRowHeader/HubRowHeader';
import DataRefresher from '../../../components/DataRefresher/DataRefresher';
import HubFilterBar from '../../../components/HubFilterBar/HubFilterBar';
import HubFilterBarSkeleton from '../../../components/HubFilterBar/HubFilterBarSkeleton';
import HubEmptyStateRestyle from '../../../components/HubEmptyStateRestyle/HubEmptyStateRestyle';
import withDataRecency from '../../../dataRecency/withDataRecency';
import withSearchInitialiser from '../../../components/SearchInitialiser/withSearchInitialiser';

class OrganizationClasses extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTerm: ''
    };
  }

  componentWillUnmount() {
    const {
      props: { resetPaginationAction, setClassesToArchiveAction, orgId }
    } = this;
    resetPaginationAction();
    setClassesToArchiveAction([], orgId);
  }

  _setClassesToArchive = (classesIds, event) => {
    event.stopPropagation();
    const { orgId, archiveClassModalAction, setClassesToArchiveAction } = this.props;
    if (classesIds && orgId) setClassesToArchiveAction(classesIds, orgId);
    archiveClassModalAction();
  };

  setSelectedClassForArchive = classId => {
    const { selectedIds, deselectClassToArchiveAction, selectClassToArchiveAction } = this.props;
    const isSelected = (selectedIds || []).includes(classId);
    if (isSelected) {
      deselectClassToArchiveAction(classId);
    } else if (selectedIds.length < HubLayoutConstants.MAX_ORGANIZATION_CLASSES_SELECTION_FOR_ARCHIVE) {
      selectClassToArchiveAction(classId);
    }
  };

  setSearchTermActionClasses = searchTerm => {
    const {
      props: { setSearchTermAction, resetPaginationAction }
    } = this;
    setSearchTermAction('orgClasses', searchTerm);
    setSearchTermAction('profileClasses', searchTerm);
    this.setState({
      searchTerm
    });
    resetPaginationAction();
  };

  render() {
    const {
      props: {
        userId,
        userRole,
        history: { push },
        orgId,
        areClassesLoading,
        organization,
        hubContent,
        classes,
        classIds,
        totalResults,
        breakpoint,
        page,
        loadClasses,
        endSlice,
        handlePageSelectionAction,
        openEditClassroomModalAction,
        openOnboardingWizardAction,
        archiveClassModalAction,
        setClassesToArchiveAction,
        classFilters,
        setSortAction,
        nrOfExistingClassesInOrg,
        currentOrganisationLti,
        selectedIds
      },
      state: { searchTerm },
      _setClassesToArchive,
      setSelectedClassForArchive,
      setSearchTermActionClasses
    } = this;

    const setSortActionStaff = sort => {
      setSortAction('orgClasses', sort[0]);
    };

    const sortValue = classFilters.sortClasses;

    const customFilterComponent = <HubFilterBarSkeleton />;

    const orgTitle = `${organization.name} | ${hubContent.organization_classes}`;

    const { MAX_ORGANIZATION_CLASSES_PER_PAGE, MAX_ORGANIZATION_CLASSES_SELECTION_FOR_ARCHIVE } = HubLayoutConstants;
    const classAction = openOnboardingWizardAction;
    const headerItems = getOrganizationClassesHeaderItems(hubContent, classAction);

    const filtersEmptyStateButton = {
      text: hubContent.organization_page_filters_reset,
      icon: GLYPHS.ICON_REFRESH,
      action: () => {
        loadClassDetails(loadClasses, orgId, userId, undefined, undefined, true);
        this.setState({
          searchTerm: ''
        });
      }
    };
    const noClassesEmptyStateButton = {
      icon: GLYPHS.ICON_PLUS,
      text: hubContent.create_class,
      action: openOnboardingWizardAction
    };
    const getEmptyState = () => {
      const emptyState =
        nrOfExistingClassesInOrg && !totalResults ? (
          <HubEmptyStateRestyle
            iconSrc={HubIllustrationConstants.SEARCH}
            iconAlt={HubIllustrationAltText.SEARCH}
            title={hubContent.organization_page_filters_empty_state_title}
            bodyText={hubContent.organization_page_filters_empty_state_subtitle}
            btnFilledBase={filtersEmptyStateButton}
            btnOutlineBase={
              !currentOrganisationLti && {
                isPrimaryButton: true,
                text: hubContent.add_classes,
                icon: GLYPHS.ICON_PLUS,
                action: () => headerItems[0].buttonAction()
              }
            }
          />
        ) : (
          <HubEmptyStateRestyle
            iconSrc={HubIllustrationConstants.CLASS_STUDENTS}
            title={currentOrganisationLti ? '' : hubContent.org_classes_empty_state_title}
            bodyText={currentOrganisationLti ? '' : hubContent.org_classes_empty_state_subtitle}
            btnFilledBase={currentOrganisationLti ? '' : noClassesEmptyStateButton}
          />
        );

      return emptyState;
    };

    const triggerMultipleClassesArchiveModal = () => {
      setClassesToArchiveAction(selectedIds, orgId);
      archiveClassModalAction();
    };

    const actions = {
      setSelectionAction: setSelectedClassForArchive,
      setClassToArchive: _setClassesToArchive,
      redirectToProgress: (event, id) => redirectToGradebookProgress(event, getOrganizationClassLink(id), orgId, push),
      redirectToOrgClassPage: (event, classId) =>
        redirectToGradebookProgress(event, getOrganizationClassLink(classId), orgId, push)
    };
    const showHeading = featureIsEnabled('navigation-changes');
    const headingArgs = {
      text: hubContent.organization_classes,
      size: 'small'
    };
    return (
      <div className={styles.orgClassesContainer}>
        <Helmet title={orgTitle} />
        {showHeading && <Heading {...headingArgs} />}
        <DataRefresher
          loading={areClassesLoading}
          noSidePadding={showHeading}
          refreshData={() => {
            loadClassDetails(
              loadClasses,
              orgId,
              userId,
              endSlice,
              {
                ...(classFilters && { active: classFilters.filtersClasses.active }),
                ...(classFilters && { archived: classFilters.filtersClasses.archived })
              },
              true
            );
            this.setState({ searchTerm: '' });
          }}
        />
        <div>
          <HubFilterBar
            idPrefix="organizationClassSearch"
            filterButtonText={breakpoint !== breakpoints.XXS ? hubContent.status_button : hubContent.filter_button}
            overlayLabelText={hubContent.status_button}
            breakpoint={breakpoint}
            sortOnChange={setSortActionStaff}
            sortOptions={classSortOptions('organizationClassSearch', sortValue, hubContent)}
            ariaControls="searchResults"
            sortValue={sortValue}
            withSearchBar
            searchTerm={searchTerm}
            searchTermOnChange={searchString => setSearchTermActionClasses(searchString)}
            searchPlaceholder={hubContent.search_org_classes_placeholder}
          />
        </div>
        {areClassesLoading ? (
          <SubSectionSkeletonLoader
            tabName=""
            panelName=""
            speed={2}
            foregroundColor={colors.COLOR_GREY_DISABLED2}
            backgroundColor={colors.COLOR_WHITE}
            customEnhancedComponent={customFilterComponent}
          />
        ) : (
          <div>
            {headerItems && headerItems.length && totalResults
              ? headerItems.map((item, index) => {
                  const filteredClasses = filterClasses(classes, classIds, userId, item.isTeacherInClass);
                  const displayClassCode =
                    !currentOrganisationLti &&
                    (userRole === userRoles.ORG_ADMIN || userRole === userRoles.TEACHER_ADMIN);
                  const isLargeBreakpoint = breakpoint === breakpoints.MD || breakpoint === breakpoints.LG;

                  return (
                    <div key={item.name}>
                      {filteredClasses && filteredClasses.length ? (
                        <HubRowHeader
                          item={item}
                          type={HubLayoutConstants.HUB_ROW_HEADER_TYPES.ORG_CLASSES}
                          selectable={false}
                          isTeacherAssignedToClass={false}
                          ids={filteredClasses}
                          hasAddButton={!currentOrganisationLti}
                          displayClassCode={displayClassCode && isLargeBreakpoint}
                          customClassname={displayClassCode && isLargeBreakpoint ? 'headerWithFourColumns' : ''}
                          hubContent={hubContent}
                        />
                      ) : null}
                      <div className={styles.rowContainer}>
                        <Classes
                          actions={actions}
                          breakpoint={breakpoint}
                          ids={filteredClasses}
                          items={classes}
                          hubContent={hubContent}
                          selectedIds={selectedIds}
                          dropdownTop={
                            /** If this is the last section on Organization Classes page, then apply the dropdownTop logic */
                            index === (headerItems?.length ?? 0) - 1
                          }
                          displayClassCode={displayClassCode}
                          customOrgId={organization?.customId}
                          customClassname={displayClassCode ? 'rowWithFourColumns' : ''}
                          userRole={userRole}
                          openEditClassroomModal={openEditClassroomModalAction}
                        />
                      </div>
                    </div>
                  );
                })
              : getEmptyState()}
          </div>
        )}
        {!areClassesLoading && totalResults > MAX_ORGANIZATION_CLASSES_PER_PAGE ? (
          <div className={styles.paginationButtonsContainer}>
            <PaginationButtons
              idPrefix="organizationClassesSearch"
              value={page}
              numberOfPages={Math.ceil(totalResults / 10)}
              onClick={handlePageSelectionAction}
              aria={{ 'aria-controls': 'searchResults' }}
            />
          </div>
        ) : null}

        <SlideupPanel isOpen={!!selectedIds.length}>
          <div className={styles.widgetContainer}>
            <span>{selectedIds.length}</span>
            <span>selected {selectedIds.length === MAX_ORGANIZATION_CLASSES_SELECTION_FOR_ARCHIVE && '(maximum)'}</span>
          </div>
          <div className={styles.buttonContainer}>
            <Button
              onClick={triggerMultipleClassesArchiveModal}
              text={hubContent.archive_classes_label}
              type={buttonTypes.ACTION_BLOCK}
            />
          </div>
        </SlideupPanel>
      </div>
    );
  }
}

OrganizationClasses.defaultProps = {
  classes: {},
  classIds: [],
  userId: '',
  orgId: '',
  hubContent: {}
};

OrganizationClasses.propTypes = {
  areClassesLoading: PropTypes.bool.isRequired,
  organization: PropTypes.object.isRequired,
  hubContent: PropTypes.object,
  orgId: PropTypes.string,
  loadClasses: PropTypes.func.isRequired,
  classes: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  classIds: PropTypes.array,
  totalResults: PropTypes.number.isRequired,
  userId: PropTypes.string,
  userRole: PropTypes.string,
  breakpoint: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  page: PropTypes.number.isRequired,
  endSlice: PropTypes.number.isRequired,
  handlePageSelectionAction: PropTypes.func.isRequired,
  resetPaginationAction: PropTypes.func.isRequired,
  setClassesToArchiveAction: PropTypes.func.isRequired,
  archiveClassModalAction: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  openEditClassroomModalAction: PropTypes.func,
  openOnboardingWizardAction: PropTypes.func,
  classFilters: PropTypes.object,
  setFilterAction: PropTypes.func,
  setSortAction: PropTypes.func,
  setSearchTermAction: PropTypes.func,
  nrOfExistingClassesInOrg: PropTypes.number,
  currentOrganisationLti: PropTypes.bool,
  selectedIds: PropTypes.array,
  selectClassToArchiveAction: PropTypes.func,
  deselectClassToArchiveAction: PropTypes.func
};

export default compose(
  withSearchInitialiser({
    searchSource: 'orgClasses',
    isAsYouType: true,
    initialFilters: {
      active: true,
      isOrgClassTab: true
    }
  }),
  withSearchInitialiser({
    searchSource: 'profileClasses',
    isAsYouType: true,
    initialFilters: {
      active: true,
      isOrgClassTab: true
    }
  }),
  withDataRecency('orgClasses', 'profileClasses'),
  connect(
    ({
      identity: { userId = '', currentOrganisationId: orgId = '', currentOrganisationLti, role: userRole = '' } = {},
      organisations: { data: orgs = {} } = {},
      search: {
        orgClasses: {
          classIds: classes = {},
          loading: areClassesLoading = false,
          totalResults = 0,
          sort: sortClasses = 'name:asc',
          page: pageClasses = 1,
          filters: filtersClasses = {},
          totalResults: totalResultsClasses = 0,
          error: errorClasses = false,
          currentClassList = [],
          classIds: classesOrgClasses = {}
        } = {}
      } = {},
      hubPeople: { page = 0, startSlice = 0, endSlice = 0 } = {},
      classrooms: { data: classroomsData = [] } = {},
      classroomArchive
    }) => ({
      organization: orgs[orgId] || {},
      currentOrganisationLti,
      userId,
      userRole,
      orgId,
      areClassesLoading,
      classes,
      classFilters: {
        sortClasses,
        pageClasses,
        filtersClasses,
        totalResultsClasses,
        errorClasses
      },
      classIds: [
        ...Object.keys(currentClassList).filter(orgClass => currentClassList[orgClass].teacherIdList?.includes(userId)),
        ...Object.keys(currentClassList).filter(orgClass => !currentClassList[orgClass].teacherIdList?.includes(userId))
      ].slice(startSlice, endSlice),
      totalResults,
      classesOrgClasses,
      page,
      startSlice,
      endSlice,
      nrOfExistingClassesInOrg: Object.keys(classroomsData).length,
      selectedIds: [...classroomArchive.classroomIds]
    }),
    {
      loadClasses: initialiseInstance,
      handlePageSelectionAction: handlePeopleSelectionPage,
      resetPaginationAction: resetPagination,
      selectClassToArchiveAction: selectClassForArchive,
      deselectClassToArchiveAction: deselectClassForArchive,
      setClassesToArchiveAction: setClassroomsToArchive,
      archiveClassModalAction: toggleArchiveClassModal,
      openEditClassroomModalAction: openEditClassroomModal,
      openOnboardingWizardAction: openOnboardingWizard,
      setFilterAction: setFilter,
      setSortAction: setSort,
      setSearchTermAction: setTerm
    }
  )
)(OrganizationClasses);
