import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

import compose from '../../utils/compose/compose.js';

import withLocalizedContent from '../../language/withLocalizedContent';
import RegisterOrSignInWizard from '../../components/RegisterOrSignInWizard/RegisterOrSignInWizard.js';
import mountLiveChat, { unmountLiveChat } from '../../utils/chat/liveChat';

function RedeemCodePage({ localizedContent: { redeemCodePage: content } }) {
  useEffect(() => {
    mountLiveChat();

    return () => {
      unmountLiveChat();
    };
  }, []);

  return (
    <div>
      <Helmet title={content.page_title} />
      <RegisterOrSignInWizard />
    </div>
  );
}

RedeemCodePage.propTypes = {
  localizedContent: PropTypes.object.isRequired
};

export default compose(withLocalizedContent('redeemCodePage'))(RedeemCodePage);
