import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from '../../utils/compose/compose.js';

import {
  getFilteredProfileCourseMaterials,
  setUnfilteredProfileCourseMaterials,
  resetFiltersProfileCourseMaterials,
  setSortProfileCourseMaterials
} from '../../redux/actions/hubProfileCourseMaterialFilters';
import { classworkSortOptions } from '../../globals/hubSearchFilter';

import HubFilterBar from '../HubFilterBar/HubFilterBar';

class HubProfilePageMaterialFilter extends Component {
  options = this.generateOptions();

  componentDidMount() {
    const {
      setUnfilteredProfileCourseMaterialsAction,
      resetFiltersProfileCourseMaterialsAction,
      products,
      userId
    } = this.props;

    resetFiltersProfileCourseMaterialsAction(userId);
    if (products && Object.keys(products).length) {
      setUnfilteredProfileCourseMaterialsAction({
        isDataSet: true,
        data: products,
        initialData: products,
        userId
      });
    }
  }

  componentDidUpdate(prevProps) {
    const {
      setUnfilteredProfileCourseMaterialsAction,
      resetFiltersProfileCourseMaterialsAction,
      products,
      userId
    } = this.props;
    if (prevProps.userId !== userId) {
      resetFiltersProfileCourseMaterialsAction(userId);
      if (products && Object.keys(products).length) {
        setUnfilteredProfileCourseMaterialsAction({
          isDataSet: true,
          data: products,
          initialData: products,
          userId
        });
      }
    } else if (!prevProps.data.length && !prevProps.isDataSet && products && Object.keys(products).length) {
      setUnfilteredProfileCourseMaterialsAction({
        isDataSet: true,
        data: products,
        initialData: products,
        userId
      });
    }
  }

  componentWillUnmount() {
    const { setUnfilteredProfileCourseMaterialsAction } = this.props;
    setUnfilteredProfileCourseMaterialsAction({
      isDataSet: false,
      data: {},
      initialData: {},
      userId: ''
    });
  }

  _setSortActionProfile = sort => {
    const { setSortProfileCourseMaterialsAction } = this.props;
    setSortProfileCourseMaterialsAction(sort[0]);
  };

  _setFilterOptions = valueName => {
    const { getFilteredProfileCourseMaterialsAction, filters } = this.props;
    const filterOptions = [];
    for (let i = 0; i < this.options.length; i += 1) {
      if (this.options[i].value === valueName) {
        this.options[i].checked = filters.indexOf(valueName) === -1;
      }
      if (this.options[i].checked) {
        filterOptions.push(this.options[i].value);
      }
    }
    return getFilteredProfileCourseMaterialsAction(filterOptions);
  };

  _getFilterOptions = stateOptions => {
    for (let i = 0; i < this.options.length; i += 1) {
      if (stateOptions && stateOptions.length && stateOptions.indexOf(this.options[i].value) !== -1) {
        this.options[i].checked = true;
      }
    }
    return this.options;
  };

  generateOptions() {
    const { hubContent } = this.props;

    return [
      {
        checked: false,
        id: 'courseMaterials-notStarted',
        name: 'courseMaterials-notStarted',
        onChange: valueName => this._setFilterOptions(valueName),
        text: hubContent?.hub_course_material_filters_not_started,
        value: 'notStarted'
      },
      {
        checked: false,
        id: 'courseMaterials-active',
        name: 'courseMaterials-active',
        onChange: valueName => this._setFilterOptions(valueName),
        text: hubContent?.hub_course_material_filters_active,
        value: 'active'
      },
      {
        checked: false,
        id: 'courseMaterials-expiringSoon',
        name: 'courseMaterials-expiringSoon',
        onChange: valueName => this._setFilterOptions(valueName),
        text: hubContent?.hub_course_material_filters_expiring_soon,
        value: 'expiringSoon'
      },
      {
        checked: false,
        id: 'courseMaterials-expired',
        name: 'courseMaterials-expired',
        onChange: valueName => this._setFilterOptions(valueName),
        text: hubContent?.hub_course_material_filters_expired,
        value: 'expired'
      },
      {
        checked: false,
        id: 'courseMaterials-noLicence',
        name: 'courseMaterials-noLicence',
        onChange: valueName => this._setFilterOptions(valueName),
        text: hubContent?.hub_course_material_filters_no_licence,
        value: 'noLicence'
      }
    ];
  }

  render() {
    const { breakpoint, filters, hubContent, sortValue } = this.props;
    return (
      <HubFilterBar
        key=""
        idPrefix=""
        filterButtonText={hubContent.hub_course_material_filters_licence}
        overlayLabelText={hubContent.hub_course_material_filters_licence}
        breakpoint={breakpoint}
        filterOptions={this._getFilterOptions(filters)}
        sortOnChange={this._setSortActionProfile}
        sortOptions={classworkSortOptions('profile', sortValue, hubContent)}
        sortValue={sortValue}
        ariaControls="searchResults"
      />
    );
  }
}

HubProfilePageMaterialFilter.propTypes = {
  userId: PropTypes.string,
  products: PropTypes.object,
  data: PropTypes.object,
  hubContent: PropTypes.string,
  isDataSet: PropTypes.bool,
  breakpoint: PropTypes.string,
  setUnfilteredProfileCourseMaterialsAction: PropTypes.func,
  getFilteredProfileCourseMaterialsAction: PropTypes.func,
  resetFiltersProfileCourseMaterialsAction: PropTypes.func,
  setSortProfileCourseMaterialsAction: PropTypes.func,
  sortValue: PropTypes.string,
  filters: PropTypes.array
};

export default compose(
  connect(
    ({ hubProfileCourseMaterialFilters }) => ({
      data: hubProfileCourseMaterialFilters.data,
      isDataSet: hubProfileCourseMaterialFilters.isDataSet,
      filters: hubProfileCourseMaterialFilters.filters,
      sortValue: hubProfileCourseMaterialFilters.sort || ''
    }),
    {
      setUnfilteredProfileCourseMaterialsAction: setUnfilteredProfileCourseMaterials,
      getFilteredProfileCourseMaterialsAction: getFilteredProfileCourseMaterials,
      resetFiltersProfileCourseMaterialsAction: resetFiltersProfileCourseMaterials,
      setSortProfileCourseMaterialsAction: setSortProfileCourseMaterials
    }
  )
)(HubProfilePageMaterialFilter);
