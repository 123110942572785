import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import userRoles, {
  orgUserRolesHighToLow,
  isTeacherAdminOrAbove,
  isTeacherOrAbove,
  selfRegisteredOrgUserRoles
} from '../globals/userRoles';

import LocationChangeListener from './locationChangeListener';
import HubLayout from './HubLayout';
import YoungLearnerLayout from './YoungLearnerLayout/YoungLearnerLayout';
import HubDashboardLayout from './HubDashboardLayout';
import HubIndexRedirectHandler from '../routes/hubIndexRedirectHandler';
import NotFoundView from '../views/errors/NotFoundView';
import { featureIsEnabled } from '../globals/envSettings';

// Hub Dashboard Page
import MyDashboard from '../routes/MyDashboard/MyDashboard';
import MyTasks from '../routes/MyTasks/MyTasks';
import HubProductLayout from './HubProductLayout/HubProductLayout';
import HubClassLayout from './HubClassLayout/HubClassLayout';
import MyCourses from '../routes/MyCourses/MyCourses';
import MyProgress from '../routes/MyProgress/MyProgress';
import RedeemCodePage from '../routes/RedeemCode/RedeemCodePage';
import RedeemCodeSuccessPage from '../routes/RedeemCode/RedeemCodeSuccessPage';
import RedeemCodePageWizard from '../routes/RedeemCodePage/RedeemCodePage';

// Hub Products Pages
import CourseMaterial from './HubProductLayout/CourseMaterial/CourseMaterial';
import ResourceCategory from './HubProductLayout/Resources/ResourceCategory';
import ResourceHome from './HubProductLayout/Resources/ResourceHome';
import ProductPage from '../routes/ProductPage/ProductPage';

// Hub Course assessments Pages
import AssessmentHome from './HubCourseAssessments/AssessmentHome/AssessmentHome';
import AssessmentFolderPage from './HubCourseAssessments/AssessmentFolderPage/AssessmentFolderPage';

// Hub Class related Pages
import MyClasses from '../routes/MyClasses/MyClasses';
import Classwork from './HubClassLayout/Classwork/Classwork';
import HubGradebook from './HubClassLayout/HubGradebook';
import StudentAnswerViewPage from '../routes/Gradebook/StudentAnswerViewPage';
import People from './HubClassLayout/People/People';
import ClassAssessment from './HubClassLayout/ClassAssessment/ClassAssessment';

// Hub Organization related Pages
import HubOrganizationLayout from './HubOrganizationLayout/HubOrganizationLayout';
import HubListPageStaff from './HubListPage/HubListPageStaff';
import HubListPageStudents from './HubListPage/HubListPageStudents';
import HubPersonProfilePage from './HubListPage/ProfilePage/HubPersonProfilePage';
import OrganizationClasses from './HubOrganizationLayout/OrganizationClasses/OrganizationClasses';
import OrganizationPlacementTests from './HubOrganizationLayout/OrganizationPlacementTests/OrganizationPlacementTests';
import OrganizationProgress from './HubOrganizationLayout/OrganizationProgress/OrganizationProgress';
import HubListPageLicences from './HubOrganizationLayout/OrganizationLicences/HubListPageLicences';
import LMSConfigTools from './HubOrganizationLayout/LMSConfig/LMSConfigTools';
import HubListPageCredits from './HubOrganizationLayout/OrganizationCredits/HubListPageCredits';

// Hub Support related pages
import HubSupportPage from '../routes/HubSupport/HubSupportPage';
import SupportArticlePage from '../routes/Support/SupportArticle/SupportArticlePage';

// Auth pages
import RegisterPage from '../routes/Register/RegisterPage';
import RequestPasswordReset from '../routes/RequestPasswordReset/RequestPasswordReset';
import ResetPassword from '../routes/ResetPassword/ResetPassword';
import RegisterChoice from '../routes/RegisterChoice/RegisterChoice';
import GeneralTermsAndConditions from '../routes/GeneralTermsAndConditions/GeneralTermsAndConditions';
import WelcomeToHubPage from '../routes/WelcomeToHubPage/WelcomeToHubPage';

// Blank page
import BlankPage from '../routes/BlankPage/BlankPage';
import StructuredContentPlayerBlankPage from '../routes/BlankPage/StructuredContentPlayerBlankPage';
import EreaderContentPlayerBlankPage from '../routes/BlankPage/EreaderContentPlayerBlankPage';

// Redirect to Auth0
import LoginPage from '../routes/LoginPage/LoginPage';

import LaunchTokenLogoutPage from '../routes/LaunchTokenLogoutPage/LaunchTokenLogoutPage';

import protectedRoute from './protectedRoute';
import DeclineOrgInvitation from '../routes/DeclineOrgInvitation/DeclineOrgInvitation';

import { RegisterOrgPage, LegacyRegisterOrgPage } from '../pages';
import RegisterPageWizard from '../routes/RegisterPageWizard/RegisterPageWizard';

function RouteManager() {
  const hubRestrictedUserRoles = selfRegisteredOrgUserRoles;
  const teacherAdminOrAboveRoles = orgUserRolesHighToLow.filter(role => isTeacherAdminOrAbove(role));
  const teacherOrAboveRoles = orgUserRolesHighToLow.filter(role => isTeacherOrAbove(role));

  // TODO: Remove this once the feature flag is removed
  const getRegisterOrgPageComponent = () =>
    featureIsEnabled('register-org-restyle') ? <RegisterOrgPage /> : <LegacyRegisterOrgPage />;

  // TODO: Remove this once the feature flag is removed
  const getRedeemPageComponent = () =>
    featureIsEnabled('young-learners') ? (
      <RedeemCodePageWizard />
    ) : (
      protectedRoute(RedeemCodePage, false, Object.keys(userRoles), '/myDashboard/redeem')
    );

  // TODO: Remove this once the feature flag is removed
  const getRegisterWizardPageComponent = () =>
    featureIsEnabled('young-learners') ? (
      <RegisterPageWizard />
    ) : (
      protectedRoute(RegisterPage, false, Object.keys(userRoles), '/myDashboard')
    );

  const registerWizardPageComponent = () => React.createElement(getRegisterWizardPageComponent);
  const redeemPageComponent = () => React.createElement(getRedeemPageComponent);

  return (
    <BrowserRouter>
      <LocationChangeListener>
        <Routes>
          {/* Blank page for loading core assets only, used for OUP.VIEW.embed prior to .gotoPage(...) */}
          <Route path="blank" element={<BlankPage />} />

          {/* Logged out status page for LTI Tokens and Impersonation tokens */}
          <Route
            path="/logged-out"
            element={
              <HubLayout>
                <LaunchTokenLogoutPage />
              </HubLayout>
            }
          />

          {/* redirect to Auth0 */}
          <Route path="/login/:orgId?" element={<LoginPage />} />

          <Route
            path="/"
            element={
              <HubLayout>
                <HubIndexRedirectHandler />
              </HubLayout>
            }
          />

          <Route
            path="/launch/:role?/:contentCode"
            element={
              <HubLayout>
                <StructuredContentPlayerBlankPage />
              </HubLayout>
            }
          />

          <Route
            path="/launch-ereader/book/:book/theme/:theme"
            element={
              <HubLayout>
                <EreaderContentPlayerBlankPage />
              </HubLayout>
            }
          />

          {/* Routes inherited from OUP */}
          <Route
            path="/terms-and-conditions/all"
            element={
              <HubLayout>
                <GeneralTermsAndConditions />
              </HubLayout>
            }
          />

          <Route
            path="/welcome-to-hub"
            element={
              <HubLayout>
                <WelcomeToHubPage />
              </HubLayout>
            }
          />

          <Route path="/register/:token?" element={<HubLayout>{registerWizardPageComponent()}</HubLayout>} />

          <Route
            path="/young-learner/register/:token?"
            element={
              <YoungLearnerLayout>
                <RegisterPageWizard />
              </YoungLearnerLayout>
            }
          />

          <Route
            path="/register-choice"
            element={
              <HubLayout>
                <RegisterChoice />
              </HubLayout>
            }
          />

          <Route
            path="/reset-password/:token"
            element={
              <HubLayout>
                <ResetPassword />
              </HubLayout>
            }
          />

          <Route
            path="/reset-password"
            element={
              <HubLayout>
                <RequestPasswordReset />
              </HubLayout>
            }
          />

          <Route
            path="/org/register"
            element={
              <HubLayout>
                {protectedRoute(getRegisterOrgPageComponent, true, Object.keys(userRoles), '/myDashboard')}
              </HubLayout>
            }
          />

          <Route
            path="/decline-invite/:token"
            element={
              <HubLayout>
                <DeclineOrgInvitation />
              </HubLayout>
            }
          />

          <Route
            path="/redeem/code"
            element={
              <HubLayout>
                <RedeemCodeSuccessPage />
              </HubLayout>
            }
          />

          <Route path="/redeem" element={<HubLayout>{redeemPageComponent()}</HubLayout>} />

          <Route
            path="/support/articles/:pageName?"
            element={
              <HubLayout>
                <SupportArticlePage />
              </HubLayout>
            }
          />

          <Route
            path="/support/:tabName?"
            element={
              <HubLayout>
                <HubSupportPage />
              </HubLayout>
            }
          />

          <Route
            path="/product/:resourceType?/:resourceId?"
            element={
              <HubLayout>
                <ProductPage />
              </HubLayout>
            }
          />

          <Route
            path="/myDashboard"
            element={
              <HubLayout>
                <HubDashboardLayout>
                  {protectedRoute(MyDashboard, true, Object.keys(userRoles), '/')}
                </HubDashboardLayout>
              </HubLayout>
            }
          />

          <Route
            path="/myDashboard/redeem"
            element={
              <HubLayout>
                <HubDashboardLayout>
                  {protectedRoute(MyDashboard, true, Object.keys(userRoles), '/')}
                </HubDashboardLayout>
              </HubLayout>
            }
          />

          <Route
            path="/myTasks/:view?"
            element={
              <HubLayout>
                <HubDashboardLayout>{protectedRoute(MyTasks, true, Object.keys(userRoles), '/')}</HubDashboardLayout>
              </HubLayout>
            }
          />

          <Route
            path="/myDashboard/classes"
            element={
              <HubLayout>
                <HubDashboardLayout>
                  {protectedRoute(MyDashboard, true, Object.keys(userRoles), '/')}
                </HubDashboardLayout>
              </HubLayout>
            }
          />

          <Route
            path="/myProgress/:panelName?/:itemId?"
            element={
              <HubLayout>
                <HubDashboardLayout>{protectedRoute(MyProgress, true, Object.keys(userRoles), '/')}</HubDashboardLayout>
              </HubLayout>
            }
          />

          <Route
            path="/myCourses/singleCourse/:courseId/assessmentFolder/:assessmentId/:folder/assessmentHome"
            element={
              <HubLayout>
                <HubDashboardLayout>
                  <HubProductLayout>
                    {protectedRoute(AssessmentHome, true, Object.keys(userRoles), '/')}
                  </HubProductLayout>
                </HubDashboardLayout>
              </HubLayout>
            }
          />

          <Route
            path="/myCourses/singleCourse/:courseId/assessmentFolder/:assessmentId/:folder/:subFolder"
            element={
              <HubLayout>
                <HubDashboardLayout>
                  <HubProductLayout>
                    {protectedRoute(AssessmentFolderPage, true, Object.keys(userRoles), '/')}
                  </HubProductLayout>
                </HubDashboardLayout>
              </HubLayout>
            }
          />

          <Route
            path="/myCourses/singleCourse/:courseId/resourceFolder/:folder/resourceHome"
            element={
              <HubLayout>
                <HubDashboardLayout>
                  <HubProductLayout>{protectedRoute(ResourceHome, true, Object.keys(userRoles), '/')}</HubProductLayout>
                </HubDashboardLayout>
              </HubLayout>
            }
          />

          <Route
            path="/myCourses/singleCourse/:courseId/resourceFolder/:folder/getStartedHome"
            element={
              <HubLayout>
                <HubDashboardLayout>
                  <HubProductLayout>{protectedRoute(ResourceHome, true, Object.keys(userRoles), '/')}</HubProductLayout>
                </HubDashboardLayout>
              </HubLayout>
            }
          />

          <Route
            path="/myCourses/singleCourse/:courseId/resourceFolder/:folder/professionalDevelopmentHome"
            element={
              <HubLayout>
                <HubDashboardLayout>
                  <HubProductLayout>{protectedRoute(ResourceHome, true, Object.keys(userRoles), '/')}</HubProductLayout>
                </HubDashboardLayout>
              </HubLayout>
            }
          />

          <Route
            path="/myCourses/singleCourse/:courseId/resourceFolder/:folder/testHome"
            element={
              <HubLayout>
                <HubDashboardLayout>
                  <HubProductLayout>{protectedRoute(ResourceHome, true, Object.keys(userRoles), '/')}</HubProductLayout>
                </HubDashboardLayout>
              </HubLayout>
            }
          />

          <Route
            path="/myCourses/singleCourse/:courseId/resourceFolder/:folder/:subFolder"
            element={
              <HubLayout>
                <HubDashboardLayout>
                  <HubProductLayout>
                    {protectedRoute(ResourceCategory, true, Object.keys(userRoles), '/')}
                  </HubProductLayout>
                </HubDashboardLayout>
              </HubLayout>
            }
          />

          <Route
            path="/myCourses/singleCourse/:courseId/resourceFolder/:folder"
            element={
              <HubLayout>
                <HubDashboardLayout>
                  <HubProductLayout>
                    {protectedRoute(ResourceCategory, true, Object.keys(userRoles), '/')}
                  </HubProductLayout>
                </HubDashboardLayout>
              </HubLayout>
            }
          />

          <Route
            path="/myCourses/singleCourse/:courseId/courseMaterial"
            element={
              <HubLayout>
                <HubDashboardLayout>
                  <HubProductLayout>
                    {protectedRoute(CourseMaterial, true, Object.keys(userRoles), '/')}
                  </HubProductLayout>
                </HubDashboardLayout>
              </HubLayout>
            }
          />

          <Route
            path="/myCourses/singleCourse/:courseId"
            element={
              <HubLayout>
                <HubDashboardLayout>
                  {protectedRoute(HubProductLayout, true, Object.keys(userRoles), '/')}
                </HubDashboardLayout>
              </HubLayout>
            }
          />

          <Route
            path="/myCourses"
            element={
              <HubLayout>
                <HubDashboardLayout>{protectedRoute(MyCourses, true, Object.keys(userRoles), '/')}</HubDashboardLayout>
              </HubLayout>
            }
          />

          <Route
            path="/myClasses/singleClass/:classroomId/classwork"
            element={
              <HubLayout>
                <HubDashboardLayout>
                  <HubClassLayout>{protectedRoute(Classwork, true, hubRestrictedUserRoles, '/')}</HubClassLayout>
                </HubDashboardLayout>
              </HubLayout>
            }
          />

          <Route
            path="/myClasses/singleClass/:classroomId/assessment"
            element={
              <HubLayout>
                <HubDashboardLayout>
                  <HubClassLayout>{protectedRoute(ClassAssessment, true, hubRestrictedUserRoles, '/')}</HubClassLayout>
                </HubDashboardLayout>
              </HubLayout>
            }
          />

          <Route
            path="/myClasses/singleClass/:classroomId/people/:personId"
            element={
              <HubLayout>
                <HubDashboardLayout>
                  <HubClassLayout>
                    {protectedRoute(HubPersonProfilePage, true, hubRestrictedUserRoles, '/')}
                  </HubClassLayout>
                </HubDashboardLayout>
              </HubLayout>
            }
          />

          <Route
            path="/myClasses/singleClass/:classroomId/people"
            element={
              <HubLayout>
                <HubDashboardLayout>
                  <HubClassLayout>{protectedRoute(People, true, hubRestrictedUserRoles, '/')}</HubClassLayout>
                </HubDashboardLayout>
              </HubLayout>
            }
          />

          <Route
            path="/myClasses/singleClass/:classroomId/progress/:orgId?/:tabName?/:panelName?/:itemId?/:learnerId?"
            element={
              <HubLayout>
                <HubDashboardLayout>
                  <HubClassLayout>
                    {protectedRoute(
                      HubGradebook,
                      true,
                      teacherOrAboveRoles,
                      '/myClasses/singleClass/:classroomId/classwork'
                    )}
                  </HubClassLayout>
                </HubDashboardLayout>
              </HubLayout>
            }
          />

          <Route
            path="/myClasses/singleClass/:classroomId"
            element={
              <HubLayout>
                <HubDashboardLayout>
                  {protectedRoute(HubClassLayout, true, hubRestrictedUserRoles, '/')}
                </HubDashboardLayout>
              </HubLayout>
            }
          />

          <Route
            path="/myClasses"
            element={
              <HubLayout>
                <HubDashboardLayout>{protectedRoute(MyClasses, true, Object.keys(userRoles), '/')}</HubDashboardLayout>
              </HubLayout>
            }
          />

          <Route
            path="/myOrganization/singleClass/:classroomId/people/:personId"
            element={
              <HubLayout>
                <HubDashboardLayout>
                  <HubClassLayout>
                    {protectedRoute(HubPersonProfilePage, true, teacherAdminOrAboveRoles, '/')}
                  </HubClassLayout>
                </HubDashboardLayout>
              </HubLayout>
            }
          />

          <Route
            path="/myOrganization/singleClass/:classroomId/people"
            element={
              <HubLayout>
                <HubDashboardLayout>
                  <HubClassLayout>{protectedRoute(People, true, teacherAdminOrAboveRoles, '/')}</HubClassLayout>
                </HubDashboardLayout>
              </HubLayout>
            }
          />

          <Route
            path="/myOrganization/singleClass/:classroomId/classwork"
            element={
              <HubLayout>
                <HubDashboardLayout>
                  <HubClassLayout>{protectedRoute(Classwork, true, teacherAdminOrAboveRoles, '/')}</HubClassLayout>
                </HubDashboardLayout>
              </HubLayout>
            }
          />

          <Route
            path="/myOrganization/singleClass/:classroomId/assessment"
            element={
              <HubLayout>
                <HubDashboardLayout>
                  <HubClassLayout>
                    {protectedRoute(ClassAssessment, true, teacherAdminOrAboveRoles, '/')}
                  </HubClassLayout>
                </HubDashboardLayout>
              </HubLayout>
            }
          />

          <Route
            path="/myOrganization/singleClass/:classroomId/progress/:orgId?/:tabName?/:panelName?/:itemId?/:learnerId?"
            element={
              <HubLayout>
                <HubDashboardLayout>
                  <HubClassLayout>{protectedRoute(HubGradebook, true, teacherAdminOrAboveRoles, '/')}</HubClassLayout>
                </HubDashboardLayout>
              </HubLayout>
            }
          />

          <Route
            path="/myOrganization/singleClass/:classroomId"
            element={
              <HubLayout>
                <HubDashboardLayout>
                  {protectedRoute(HubClassLayout, true, teacherAdminOrAboveRoles, '/')}
                </HubDashboardLayout>
              </HubLayout>
            }
          />

          <Route
            path="/myOrganization/lmsConfig"
            element={
              <HubLayout>
                <HubDashboardLayout>
                  <HubOrganizationLayout>
                    {protectedRoute(LMSConfigTools, true, teacherAdminOrAboveRoles, '/')}
                  </HubOrganizationLayout>
                </HubDashboardLayout>
              </HubLayout>
            }
          />

          <Route
            path="/myOrganization/:list/:personId/:tab"
            element={
              <HubLayout>
                <HubDashboardLayout>
                  <HubOrganizationLayout>
                    {protectedRoute(HubPersonProfilePage, true, teacherAdminOrAboveRoles, '/')}
                  </HubOrganizationLayout>
                </HubDashboardLayout>
              </HubLayout>
            }
          />

          <Route
            path="/myOrganization/licences"
            element={
              <HubLayout>
                <HubDashboardLayout>
                  <HubOrganizationLayout>
                    {protectedRoute(HubListPageLicences, true, teacherAdminOrAboveRoles, '/')}
                  </HubOrganizationLayout>
                </HubDashboardLayout>
              </HubLayout>
            }
          />

          <Route
            path="/myOrganization/credits"
            element={
              <HubLayout>
                <HubDashboardLayout>
                  <HubOrganizationLayout>
                    {protectedRoute(HubListPageCredits, true, teacherAdminOrAboveRoles, '/')}
                  </HubOrganizationLayout>
                </HubDashboardLayout>
              </HubLayout>
            }
          />

          <Route
            path="/myOrganization/staff"
            element={
              <HubLayout>
                <HubDashboardLayout>
                  <HubOrganizationLayout>
                    {protectedRoute(HubListPageStaff, true, teacherAdminOrAboveRoles, '/')}
                  </HubOrganizationLayout>
                </HubDashboardLayout>
              </HubLayout>
            }
          />

          <Route
            path="/myOrganization/students"
            element={
              <HubLayout>
                <HubDashboardLayout>
                  <HubOrganizationLayout>
                    {protectedRoute(HubListPageStudents, true, teacherAdminOrAboveRoles, '/')}
                  </HubOrganizationLayout>
                </HubDashboardLayout>
              </HubLayout>
            }
          />

          <Route
            path="/myOrganization/classes"
            element={
              <HubLayout>
                <HubDashboardLayout>
                  <HubOrganizationLayout>
                    {protectedRoute(OrganizationClasses, true, teacherAdminOrAboveRoles, '/')}
                  </HubOrganizationLayout>
                </HubDashboardLayout>
              </HubLayout>
            }
          />

          <Route
            path="/myOrganization/placementTests"
            element={
              <HubLayout>
                <HubDashboardLayout>
                  <HubOrganizationLayout>
                    {protectedRoute(OrganizationPlacementTests, true, teacherAdminOrAboveRoles, '/')}
                  </HubOrganizationLayout>
                </HubDashboardLayout>
              </HubLayout>
            }
          />

          <Route
            path="/myOrganization/progress"
            element={
              <HubLayout>
                <HubDashboardLayout>
                  <HubOrganizationLayout>
                    {protectedRoute(OrganizationProgress, true, teacherAdminOrAboveRoles, '/')}
                  </HubOrganizationLayout>
                </HubDashboardLayout>
              </HubLayout>
            }
          />

          <Route
            path="/myOrganization"
            element={
              <HubLayout>
                <HubDashboardLayout>
                  {protectedRoute(HubOrganizationLayout, true, teacherAdminOrAboveRoles, '/')}
                </HubDashboardLayout>
              </HubLayout>
            }
          />

          <Route
            path="/studentAnswerView/orgId/:orgId/class/:classroomId/:itemId/:learnerId/:bid/:uId/:activityId/:parentUId?"
            element={
              <HubLayout>
                <HubDashboardLayout>
                  <StudentAnswerViewPage />
                </HubDashboardLayout>
              </HubLayout>
            }
          />

          {/* 404 */}
          <Route
            path="*"
            element={
              <HubLayout>
                <NotFoundView />
              </HubLayout>
            }
          />
        </Routes>
      </LocationChangeListener>
    </BrowserRouter>
  );
}

export default RouteManager;
