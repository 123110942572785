import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Wizard } from '@oup/shared-front-end';

import NewAdminProvidesOrganizationDetails from './NewAdminProvidesOrganizationDetails/NewAdminProvidesOrganizationDetails.js';
import OrganizationSetupWizardFooter from './OrganizationSetupWizardFooter.js';
import OnboardingWizardAddStaff from './OnboardingWizardAddStaff/OnboardingWizardAddStaff.js';
import OnboardingWizardSuccess from './OnboardingWizardSuccess/OnboardingWizardSuccess.js';
import CreateFirstPlacementTestOrClassModal from './CreateFirstPlacementTestOrClassModal/CreateFirstPlacementTestOrClassModal.js';
import { showSelfSelectRoleModal } from '../../redux/actions/hubUi.js';
import OrganizationSetupWizardHeader from './OrganizationSetupWizardHeader.js';
import StopSettingUpOrgModal from './StopSettingUpOrgModal/StopSettingUpOrgModal.js';
import { HubLayoutConstants } from '../../globals/hubConstants.js';
import OnboardingWizardWelcome from './OnboardingWizardWelcome/OnboardingWizardWelcome.js';
import ChooseOrgType from './ChooseOrgType/ChooseOrgType.js';

// Why have we drilled this first name prop down through the wizard components?
function OrganizationSetupWizard({ showSelfSelectRoleModalAction, firstName }) {
  const [isOpenStopSettingUpOrgModal, setIsOpenStopSettingUpOrgModal] = useState(false);
  const [isOpenCreateFirstPlacementTestOrClassModal, setIsOpenCreateFirstPlacementTestOrClassModal] = useState(false);
  const [selectedOrgType, setSelectedOrgType] = useState('');

  return (
    <>
      <Wizard
        header={
          <OrganizationSetupWizardHeader
            // why is this not just called selectedOrgType?
            secondWizardType={selectedOrgType}
            setIsOpenStopSettingUpOrgModal={setIsOpenStopSettingUpOrgModal}
            setIsOpenCreateFirstPlacementTestOrClassModal={setIsOpenCreateFirstPlacementTestOrClassModal}
          />
        }
        footer={<OrganizationSetupWizardFooter />}
      >
        <OnboardingWizardWelcome firstName={firstName} />
        <ChooseOrgType selectedOrgType={selectedOrgType} setSelectedOrgType={setSelectedOrgType} />
        {/* Why is this called branch? */}
        <NewAdminProvidesOrganizationDetails branch={selectedOrgType} />
        <OnboardingWizardAddStaff branch={selectedOrgType} />
        <OnboardingWizardSuccess
          setIsOpenCreateFirstPlacementTestOrClassModal={setIsOpenCreateFirstPlacementTestOrClassModal}
          showSelfSelectRoleModalAction={showSelfSelectRoleModalAction}
          isOpenCreateFirstPlacementTestOrClassModal={isOpenCreateFirstPlacementTestOrClassModal}
        />
      </Wizard>

      <StopSettingUpOrgModal
        isOpen={isOpenStopSettingUpOrgModal}
        closeWarningModal={() => setIsOpenStopSettingUpOrgModal(false)}
      />

      <CreateFirstPlacementTestOrClassModal
        isOpen={isOpenCreateFirstPlacementTestOrClassModal}
        onClose={() => {
          setIsOpenCreateFirstPlacementTestOrClassModal(false);
          showSelfSelectRoleModalAction(false);
        }}
        onNext={() => {
          localStorage.setItem('selectedOrgType', selectedOrgType);
          window.location = HubLayoutConstants.PATH_NAMES.DASHBOARD_PATH;
        }}
        wizardType={selectedOrgType}
      />
    </>
  );
}

OrganizationSetupWizard.propTypes = {
  showSelfSelectRoleModalAction: PropTypes.func,
  firstName: PropTypes.string.isRequired
};

export default compose(
  connect(null, {
    showSelfSelectRoleModalAction: showSelfSelectRoleModal
  })
)(OrganizationSetupWizard);
