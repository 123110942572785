import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

import { Heading, useWizard, RadioButtonGroup, TextLink } from '@oup/shared-front-end';
import { ICON_COURSE, ICON_ASSESSMENT, ICON_CLASS } from '@oup/shared-front-end/svg/oup';
import { ACCESS_CODE_TYPES } from '@oup/shared-node-browser/constants';
import compose from '../../../utils/compose/compose.js';

import {
  closeSelfRegisteredWizard,
  openRedeemModal,
  openJoinClassWithCodeModal,
  openJoinPlacementTestWithCodeModal
} from '../../../redux/actions/hubUi';

import withLocalizedContent from '../../../language/withLocalizedContent';
import styles from './ChooseStudentCode.scss';

function ChooseStudentCode({
  localizedContent: { selfRegisteredUserOnboardingWizard: content, enterCode: enterCodeContent },
  closeSelfRegisteredWizardAction,
  openRedeemModalAction,
  openJoinPlacementTestWithCodeModalAction,
  openJoinClassWithCodeModalAction
}) {
  const { setNextStepDisabled, stepHandler } = useWizard();
  const [selectedStudentCodeOption, setSelectedStudentCodeOption] = useState('');

  const handleSelectedOption = e => {
    setSelectedStudentCodeOption(e.target.value);
  };

  stepHandler(() => {
    switch (selectedStudentCodeOption) {
      case ACCESS_CODE_TYPES.PRODUCT:
        closeSelfRegisteredWizardAction();
        openRedeemModalAction();
        break;

      case ACCESS_CODE_TYPES.CLASS:
        closeSelfRegisteredWizardAction();
        openJoinClassWithCodeModalAction();
        break;

      case ACCESS_CODE_TYPES.PLACEMENT_TEST:
        closeSelfRegisteredWizardAction();
        openJoinPlacementTestWithCodeModalAction();
        break;

      default:
        break;
    }
  });

  useEffect(() => {
    setNextStepDisabled(!selectedStudentCodeOption);
  }, [selectedStudentCodeOption]);

  return (
    <div
      data-testid="SELF_REGISTERED_USER_ONBOARDING_WIZARD_CHOOSE_STUDENT_CODE_CONTAINER"
      className={styles.stepContainer}
    >
      <Heading
        text={content.self_registered_user_onboarding_wizard_choose_student_code_title}
        size="medium"
        className={styles.heading}
      />
      <RadioButtonGroup
        variant="outline"
        name="enter-code-options"
        legend="enter code"
        checkedValue={[selectedStudentCodeOption]}
        classnames={{ fieldset: styles.radioButtonGroupFieldset, container: styles.radioButtonGroupContainer }}
        onChange={handleSelectedOption}
        radioGroup={[
          {
            id: `enter-product-code-radio`,
            label: enterCodeContent.enter_product_code_title,
            description: enterCodeContent.enter_product_code_subtitle,
            value: ACCESS_CODE_TYPES.PRODUCT,
            image: <ICON_COURSE className={styles.radioButtonImage} aria-hidden="true" />
          },
          {
            id: `enter-class-code-radio`,
            label: enterCodeContent.enter_class_code_title,
            description: enterCodeContent.enter_class_code_subtitle,
            value: ACCESS_CODE_TYPES.CLASS,
            image: <ICON_CLASS className={styles.radioButtonImage} aria-hidden="true" />
          },
          {
            id: `enter-placement-test-code-radio`,
            label: enterCodeContent.enter_placement_test_code_title,
            description: enterCodeContent.enter_placement_test_code_subtitle,
            value: ACCESS_CODE_TYPES.PLACEMENT_TEST,
            image: <ICON_ASSESSMENT className={styles.radioButtonImage} aria-hidden="true" />
          }
        ]}
      />
      <TextLink
        component={RouterLink}
        onClick={e => {
          e.preventDefault();
          closeSelfRegisteredWizardAction();
        }}
      >
        {content.self_registered_user_what_to_do_next_continue_to_hub}
      </TextLink>
    </div>
  );
}

ChooseStudentCode.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  closeSelfRegisteredWizardAction: PropTypes.func.isRequired,
  openJoinClassWithCodeModalAction: PropTypes.func.isRequired,
  openJoinPlacementTestWithCodeModalAction: PropTypes.func.isRequired,
  openRedeemModalAction: PropTypes.func.isRequired
};

export default compose(
  withLocalizedContent('selfRegisteredUserOnboardingWizard', 'enterCode'),
  connect(null, {
    closeSelfRegisteredWizardAction: closeSelfRegisteredWizard,
    openRedeemModalAction: openRedeemModal,
    openJoinClassWithCodeModalAction: openJoinClassWithCodeModal,
    openJoinPlacementTestWithCodeModalAction: openJoinPlacementTestWithCodeModal
  })
)(ChooseStudentCode);
