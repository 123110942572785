import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// Constants
import { Helmet } from 'react-helmet';
import compose from '../../utils/compose/compose.js';
import { HubIllustrationConstants, HubLayoutConstants } from '../../globals/hubConstants.js';
// Services
import getCourseLink from '../../structure/HubDashboardLayout/Services/getCourseLink';
import { loadCourses } from '../../redux/actions/hubCourses';
// Components
import { GLYPHS } from '../../components/SVGIcon/SVGIcon';
import TileContainer from '../../components/TileContainer/TileContainer';
import DataRefresher from '../../components/DataRefresher/DataRefresher';
import CourseTile from '../../components/CourseTile/CourseTile';
import HubPageLayout from '../../structure/HubPageLayout/HubPageLayout';
import { featureIsEnabled } from '../../globals/envSettings';
import getPageLayoutData from '../../structure/HubDashboardLayout/Utils/getPageLayoutData';

function MyCourses({
  courses,
  courseIds,
  openRedeemModal,
  hubContent,
  breakpoint,
  areCoursesLoading,
  loadCourseData,
  currentOrganisationLti
}) {
  const pageLayout = getPageLayoutData({
    pathname: HubLayoutConstants.PATH_NAMES.COURSES_PATH,
    content: hubContent,
    currentOrganisationLti
  });

  return (
    <HubPageLayout {...pageLayout}>
      <div>
        <Helmet title={hubContent.my_courses_page_title} titleTemplate={hubContent.page_title_template} />
        <DataRefresher loading={areCoursesLoading} refreshData={() => loadCourseData()} />
        <TileContainer
          data={{
            isLoading: areCoursesLoading,
            ids: courseIds,
            iconSrc: HubIllustrationConstants.DIGITAL_LIBRARY,
            title: hubContent.no_courses_text,
            subtitle: hubContent.no_courses_access_code_text,
            showFirstEmptyStateButton: true,
            firstButtonText: featureIsEnabled('learner-dashboard-refresh')
              ? hubContent.enter_a_code
              : hubContent.enter_a_code_text,
            firstButtonIcon: GLYPHS.ICON_PLUS,
            onClickFirstButton: openRedeemModal,
            isFirstButtonPrimary: true,
            getLink: getCourseLink,
            items: courses,
            Tile: CourseTile
          }}
          hubContent={hubContent}
          breakpoint={breakpoint}
          currentOrganisationLti={currentOrganisationLti}
        />
      </div>
    </HubPageLayout>
  );
}

MyCourses.propTypes = {
  breakpoint: PropTypes.string.isRequired,
  courses: PropTypes.object.isRequired,
  courseIds: PropTypes.array.isRequired,
  openRedeemModal: PropTypes.func.isRequired,
  hubContent: PropTypes.object.isRequired,
  areCoursesLoading: PropTypes.bool.isRequired,
  userRole: PropTypes.string,
  loadCourseData: PropTypes.func,
  currentOrganisationLti: PropTypes.bool,
  pageLayout: PropTypes.shape({
    ...HubPageLayout.propTypes,
    showPageInnerHero: PropTypes.bool
  })
};

const mapStateToProps = ({ identity: { role: userRole, currentOrganisationLti } }) => ({
  userRole,
  currentOrganisationLti
});

const mapDispatchToProps = {
  loadCourseData: loadCourses
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(MyCourses);
