import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useWizard } from '@oup/shared-front-end';
// Redux
import { closeOnboardingWizard } from '../../../redux/actions/hubUi';
import { clearWizardState } from '../../../redux/actions/classOnboardingWizard';
import { closeForm } from '../../../redux/reducers/classroomCreate.js';
// Components
import Button, { buttonTypes } from '../../Button/Button';
import SVGIcon, { GLYPHS } from '../../SVGIcon/SVGIcon.js';
import Link from '../../Link/Link';
// Styles
import styles from './CreateClassWizardHeader.scss';

function CreateClassWizardHeader({
  closeWizardAction,
  closeWizardWithWarningPopup,
  content,
  clearWizardStateAction,
  clearClassroomCreateStateAction
}) {
  const { step, isFirstStep } = useWizard();

  return (
    <div className={styles.header}>
      <div className={styles.container}>
        <div className={styles.title}>
          <span>{content.create_a_class} </span>
          {isFirstStep ? content.welcome : `${content.step} ${step}`}
        </div>

        <div className={styles.helpAndSupport}>
          <Link
            id="classOnboardingWizard-supportButton"
            dataTestId="CREATE_CLASS_WIZARD_SUPPORT_BUTTON"
            to={content.help_and_support_link_to}
            target="_blank"
            role="button"
          >
            <SVGIcon glyph={GLYPHS.ICON_HELP_AND_SUPPORT} />
            <span className={styles.helpSupportText}>{content.help_and_support}</span>
            <span className="a11y-hide">Help</span>
          </Link>
        </div>

        {/* Close button */}
        <Button
          text={content.close}
          onClick={() => {
            if (step === 2 || step === 3) {
              closeWizardWithWarningPopup();
            } else {
              closeWizardAction();
              clearWizardStateAction();
              clearClassroomCreateStateAction();
            }
          }}
          type={buttonTypes.CLOSE_BORDER_GRAY}
          customClassName={styles.closeButton}
          dataTestId="CREATE_CLASS_WIZARD_CLOSE_BUTTON"
        />
      </div>
    </div>
  );
}

CreateClassWizardHeader.propTypes = {
  closeWizardAction: PropTypes.func.isRequired,
  clearWizardStateAction: PropTypes.func.isRequired,
  clearClassroomCreateStateAction: PropTypes.func.isRequired,
  closeWizardWithWarningPopup: PropTypes.func.isRequired,
  content: PropTypes.object.isRequired
};

export default connect(null, {
  clearWizardStateAction: clearWizardState,
  clearClassroomCreateStateAction: closeForm,
  closeWizardAction: closeOnboardingWizard
})(CreateClassWizardHeader);
