import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { HUB_SUPPORT_PLACEMENT_TESTS_EXTRA_TIME_URL, PLACEMENT_TEST_STATUS } from '@oup/shared-node-browser/constants';
import { RadioButtonGroup } from '@oup/shared-front-end';

import compose from '../../../utils/compose/compose.js';
import Link from '../../Link/Link';
import styles from './PlacementTestExtraTime.scss';
import SVGIcon, { GLYPHS } from '../../SVGIcon/SVGIcon';
import { setEditedPlacementTest } from '../../../redux/reducers/placementTestSessionCreate';
import withLocalizedContent from '../../../language/withLocalizedContent';

function PlacementTestExtraTime({
  extraTimePerItem,
  setExtraTimePerItem,
  setExtraTimePerItemIsInvalid,
  editedPlacementTest,
  placementTest,
  setEditedPlacementTestAction,
  localizedContent: { placementTests: placementTestsContent },
  placementTestStatus
}) {
  const languageVariationCode = placementTestStatus
    ? placementTest.languageVariationCode
    : editedPlacementTest.languageVariationCode;

  const radioButtonListArgs = {
    variant: 'outline',
    name: 'extra-time-group',
    legend: 'legend name test',
    checkedValue: [extraTimePerItem],
    radioGroup: [
      {
        id: 'extra-time-radio-0',
        label: placementTestsContent.create_placement_test_extra_time_label_0,
        description: `${placementTestsContent.create_placement_test_extra_time_test_takes} ${
          languageVariationCode
            ? placementTestsContent.create_placement_test_extra_time_tooltip_0_lang
            : placementTestsContent.create_placement_test_extra_time_tooltip_0
        }`,
        value: 0,
        disabled: placementTestStatus === PLACEMENT_TEST_STATUS.ACTIVE
      },
      {
        id: 'extra-time-radio-25',
        label: placementTestsContent.create_placement_test_extra_time_label_25,
        description: `${placementTestsContent.create_placement_test_extra_time_test_takes} ${
          languageVariationCode
            ? placementTestsContent.create_placement_test_extra_time_tooltip_25_lang
            : placementTestsContent.create_placement_test_extra_time_tooltip_25
        }`,
        value: 25,
        disabled: placementTestStatus === PLACEMENT_TEST_STATUS.ACTIVE
      },
      {
        id: 'extra-time-radio-50',
        label: placementTestsContent.create_placement_test_extra_time_label_50,
        description: `${placementTestsContent.create_placement_test_extra_time_test_takes} ${
          languageVariationCode
            ? placementTestsContent.create_placement_test_extra_time_tooltip_50_lang
            : placementTestsContent.create_placement_test_extra_time_tooltip_50
        }`,
        value: 50,
        disabled: placementTestStatus === PLACEMENT_TEST_STATUS.ACTIVE
      }
    ]
  };

  const handleOnChange = e => {
    const checkInputVal = radioButtonListArgs.radioGroup.filter(arrVal => arrVal === e.target.value).length > 0;
    setEditedPlacementTestAction({ extraTimePerItem: Number(e.target.value) });
    setExtraTimePerItem(Number(e.target.value));
    setExtraTimePerItemIsInvalid(checkInputVal);
  };

  return (
    <div className={styles.extraTimeContainer}>
      <h3>{placementTestsContent.create_placement_test_extra_time_subtitle}</h3>
      <p>{placementTestsContent.create_placement_test_extra_time_tooltip}</p>
      <RadioButtonGroup {...radioButtonListArgs} onChange={handleOnChange} />
      <Link openInNewTab to={HUB_SUPPORT_PLACEMENT_TESTS_EXTRA_TIME_URL} underlined className={styles.link}>
        {placementTestsContent.create_placement_test_extra_time_link}
        <SVGIcon glyph={GLYPHS.ICON_LINK} />
      </Link>
    </div>
  );
}

PlacementTestExtraTime.propTypes = {
  extraTimePerItem: PropTypes.number.isRequired,
  setExtraTimePerItem: PropTypes.func,
  setExtraTimePerItemIsInvalid: PropTypes.func,
  editedPlacementTest: PropTypes.object,
  placementTest: PropTypes.object,
  setEditedPlacementTestAction: PropTypes.func,
  localizedContent: PropTypes.object.isRequired,
  placementTestStatus: PropTypes.string
};

export default compose(
  withLocalizedContent('placementTests'),
  connect(
    ({ placementTestSessionCreate }) => ({
      editedPlacementTest: placementTestSessionCreate.editedPlacementTest,
      placementTest: placementTestSessionCreate.placementTest
    }),
    {
      setEditedPlacementTestAction: setEditedPlacementTest
    }
  )
)(PlacementTestExtraTime);
