import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { RadioButtonGroup, Button, TextLink } from '@oup/shared-front-end';
import { ACCESS_CODE_TYPES } from '@oup/shared-node-browser/constants';
import { connect } from 'react-redux';
import { ICON_CLASS, ICON_COURSE, ICON_ASSESSMENT } from '@oup/shared-front-end/svg/oup';
import compose from '../../../utils/compose/compose.js';
import HubModalLayout from '../../../components/HubModalLayout/HubModalLayout';
import styles from './EnterCodeModal.scss';
import withLocalizedContent from '../../../language/withLocalizedContent';
import { featureIsEnabled } from '../../../globals/envSettings';
import LegacyButton, { buttonTypes } from '../../../components/Button/Button';

function EnterCodeModal({
  isOpen,
  onClose,
  onNext,
  localizedContent: { enterCode: content, hubGlossary: hubContent }
}) {
  const modalRef = useRef(null);
  const selectionInitialState = '';
  const [selectedOption, setSelectedOption] = useState(selectionInitialState);
  const isDisabledNextBtn = !selectedOption;

  useEffect(() => {
    if (!isOpen) {
      setSelectedOption(selectionInitialState);
    }
  }, [isOpen]);

  const handleOptionSelected = e => {
    setSelectedOption(e.target.value);
  };

  const handleNextBtn = () => {
    onNext(selectedOption);
  };

  const handleClose = () => {
    setSelectedOption(selectionInitialState);
    onClose();
  };

  const getRadioButtons = () => {
    const radioButtons = [
      {
        id: `enter-product-code-radio`,
        label: content.enter_product_code_title,
        description: content.enter_product_code_subtitle,
        value: ACCESS_CODE_TYPES.PRODUCT,
        image: <ICON_COURSE aria-hidden="true" className={styles.radioButtonIcons} />
      },
      {
        id: `enter-class-code-radio`,
        label: content.enter_class_code_title,
        description: content.enter_class_code_subtitle,
        value: ACCESS_CODE_TYPES.CLASS,
        image: <ICON_CLASS aria-hidden="true" className={styles.radioButtonIcons} />
      }
    ];

    if (featureIsEnabled('opt-main-features')) {
      radioButtons.push({
        id: `enter-placement-test-code-radio`,
        label: content.enter_placement_test_code_title,
        description: content.enter_placement_test_code_subtitle,
        value: ACCESS_CODE_TYPES.PLACEMENT_TEST,
        image: <ICON_ASSESSMENT aria-hidden="true" className={styles.radioButtonIcons} />
      });
    }

    return radioButtons;
  };

  return (
    isOpen && (
      <div className={`${styles.outerEnterCodeModal} ${isOpen ? styles.open : ''}`}>
        <HubModalLayout
          isOpen={isOpen}
          closeAction={onClose}
          focusOnFirstField={false}
          ariaLabel={content.enter_code_modal_title}
        >
          <div
            data-testid="ENTER_CODE_MODAL"
            className={`${styles.modalContainer} ${isOpen ? styles.animationFadeIn : ''}`}
            aria-hidden={!isOpen}
            ref={modalRef}
          >
            <div className={styles.modalHeader}>
              <LegacyButton type={buttonTypes.CLOSE_WHITE} iconOnly onClick={handleClose} />
              <h2 className={styles.title}>{content.enter_code_modal_title}</h2>
            </div>
            <div className={styles.modalContent}>
              <RadioButtonGroup
                variant="outline"
                name="enter-code-options"
                legend="legend name test 1"
                checkedValue={[selectedOption]}
                classnames={{ container: styles.radioButtonGroupContainer }}
                radioGroup={getRadioButtons()}
                onChange={handleOptionSelected}
              />
            </div>
            <hr className={styles.hr} />
            <div className={styles.modalFooter}>
              <TextLink
                dataTestId="ENTER_CODE_MODAL_CANCEL_BUTTON"
                onClick={e => {
                  e.preventDefault();
                  handleClose();
                }}
                ariaAttributes={{
                  label: hubContent.cancel
                }}
              >
                {hubContent.cancel}
              </TextLink>
              <Button
                dataTestId="ENTER_CODE_MODAL_CONFIRM_BUTTON"
                text={hubContent.next}
                onClick={handleNextBtn}
                disabled={isDisabledNextBtn}
                ariaAttributes={{
                  label: hubContent.next
                }}
              />
            </div>
          </div>
        </HubModalLayout>
      </div>
    )
  );
}

EnterCodeModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  localizedContent: PropTypes.object.isRequired
};

export default compose(withLocalizedContent('enterCode', 'hubGlossary'), connect(null, {}))(EnterCodeModal);
