import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { useWizard, Heading, RadioButtonGroup } from '@oup/shared-front-end';
import { USER_TYPES, USER_AGE, HubIllustrationConstants, HubIllustrationAltText } from '../../globals/hubConstants';
import styles from './ChooseRoleWizard.scss';

function ChooseAgeStep({ content, selectedUserType, selectedUserAge, onChange, onRoleChosen }) {
  const { setNextStepDisabled, stepHandler } = useWizard();

  useEffect(() => {
    setNextStepDisabled(!selectedUserAge);
  }, [selectedUserAge]);

  stepHandler(() => {
    if (selectedUserAge === USER_AGE.UNDER_12) {
      onRoleChosen('youngLearner');
      return;
    }
    onRoleChosen('standard');
  });

  return (
    <div data-testid="CHOOSE_AGE_WIZARD_STEP_2" className={styles.chooseAgeOuterContainer}>
      <div className={styles.chooseAgeInnerContainer}>
        <div className={styles.textContent}>
          <Heading
            text={selectedUserType === USER_TYPES.STUDENT ? content.how_old_are_you : content.how_old_is_your_child}
            size="medium"
            className={styles.heading}
          />
          <RadioButtonGroup
            variant="outline"
            name="userAge"
            legend={
              selectedUserType === USER_TYPES.STUDENT
                ? content.choose_age_as_learner_legend
                : content.choose_age_as_parent_legend
            }
            checkedValue={selectedUserAge}
            radioGroup={[
              {
                id: USER_AGE.UNDER_12,
                label: content.under_12_years_old,
                value: USER_AGE.UNDER_12,
                onChange: () => onChange(USER_AGE.UNDER_12),
                className: styles.chooseAgeRadioButton
              },
              {
                id: USER_AGE.BETWEEN_12_AND_16,
                label: content.between_12_and_16_years_old,
                value: USER_AGE.BETWEEN_12_AND_16,
                onChange: () => onChange(USER_AGE.BETWEEN_12_AND_16),
                className: styles.chooseAgeRadioButton
              },
              {
                id: USER_AGE.OVER_17,
                label: content.over_17_years_old,
                value: USER_AGE.OVER_17,
                onChange: () => onChange(USER_AGE.OVER_17),
                className: styles.chooseAgeRadioButton
              }
            ]}
          />
        </div>
        <div className={styles.imageContainer}>
          <img src={HubIllustrationConstants.STUDENT} alt={HubIllustrationAltText.STUDENT} />
        </div>
      </div>
    </div>
  );
}

ChooseAgeStep.propTypes = {
  content: PropTypes.string.isRequired,
  selectedUserType: PropTypes.string.isRequired,
  selectedUserAge: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onRoleChosen: PropTypes.func.isRequired
};

export default ChooseAgeStep;
