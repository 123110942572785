import React from 'react';
import PropTypes from 'prop-types';
// Components
import { Button } from '@oup/shared-front-end';
import HubModalLayout from '../HubModalLayout/HubModalLayout';
import SVGIcon, { GLYPHS } from '../SVGIcon/SVGIcon';
// Styles
import styles from './ManageProductLocks.scss';

function ManageProductLocksWarningModal({ isOpen, content, closeWarningModal, closePanel }) {
  return (
    <div className={`${styles.outerWarningModalContainer} ${isOpen ? styles.open : ''}`}>
      <HubModalLayout isOpen={isOpen} closeAction={closeWarningModal} ariaLabel={content.warning_wizard_arialabel}>
        <div className={`${styles.modalContainer} ${isOpen ? styles.animationFadeIn : ''}`} aria-hidden={!isOpen}>
          <div className={styles.modalHeader}>
            <Button
              text={content.close}
              variant="transparent"
              icon={{
                component: <SVGIcon className={styles.closeIcon} glyph={GLYPHS.ICON_CLOSE} />,
                placement: 'right'
              }}
              onClick={() => {
                closeWarningModal();
              }}
            />
          </div>

          <div className={styles.modalContent}>
            <p className={styles.title}>{content.managed_students_warning_modal_title}</p>
            <p className={styles.subtitle}>{content.managed_students_warning_modal_subtitle}</p>
          </div>

          <div className={styles.modalFooter}>
            <Button text={content.cancel} variant="transparent" onClick={() => closeWarningModal()} />
            <Button
              text={content.yes}
              variant="filled"
              onClick={() => {
                closeWarningModal();
                closePanel();
              }}
            />
          </div>
        </div>
      </HubModalLayout>
    </div>
  );
}
ManageProductLocksWarningModal.propTypes = {
  isOpen: PropTypes.bool,
  content: PropTypes.object,
  closeWarningModal: PropTypes.func,
  closePanel: PropTypes.func
};

export default ManageProductLocksWarningModal;
