import React from 'react';
import PropTypes from 'prop-types';
// Styles
import { Button } from '@oup/shared-front-end';
import styles from './MyDashboard.scss';
// Components
import SVGIcon, { GLYPHS } from '../../components/SVGIcon/SVGIcon';

function MyDashboardButton({ data: { ids, text, ariaLabel, onClick } }) {
  return ids.length ? (
    <div className={styles.dashboardMiddle}>
      <div>
        <Button
          icon={{ component: <SVGIcon glyph={GLYPHS.ICON_PLUS} /> }}
          onClick={onClick}
          text={text}
          ariaAttributes={{ label: ariaLabel }}
          variant="filled"
          dataAttributes={{ testId: 'OPEN_ONBOARDING_WIZARD_BUTTON_DASHBOARD' }}
        />
      </div>
    </div>
  ) : null;
}
MyDashboardButton.propTypes = {
  data: PropTypes.object.isRequired
};

export default MyDashboardButton;
