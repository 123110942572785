import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { validUserRoles } from '../globals/userRoles.js';
import { HubLayoutConstants } from '../globals/hubConstants';
import { formStates } from '../redux/reducers/invitationsBanner.reducer';
import { queryFilter } from '../utils/url.js';

// Site homepage
import HubHomepage from './HubHomepage/HubHomepage.js';

function HubIndexRedirectHandler({ userRole, invitesFetchStatus, invites }) {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // Redirect to dashboard after accepting all invites
    if (invites.length === 0 && !invitesFetchStatus && !!userRole) {
      navigate(HubLayoutConstants.PATH_NAMES.DASHBOARD_PATH, { replace: true });
    }
  }, [invites, invitesFetchStatus, userRole, navigate]);

  useEffect(() => {
    let nextPage;
    const urlParams = location.search;
    const returnTo = urlParams && (urlParams.match(/returnTo=([^&$]+)/) || [])[1];
    const nextPathFromSessionStorage = sessionStorage.getItem('goto-path');

    if (nextPathFromSessionStorage && nextPathFromSessionStorage !== '/login') {
      nextPage = nextPathFromSessionStorage;
    } else if (returnTo && returnTo !== encodeURIComponent('/') && returnTo !== encodeURIComponent('/login')) {
      // Redirect to where the user originally wanted to go:
      nextPage = decodeURIComponent(returnTo);
    } else if (!userRole) {
      // Redirect to homepage if user is not logged in:
      nextPage = HubLayoutConstants.PATH_NAMES.HOME_PAGE;
    } else {
      // Logged in users should be redirected to the dashboard
      nextPage = HubLayoutConstants.PATH_NAMES.DASHBOARD_PATH;
    }

    if (nextPathFromSessionStorage) sessionStorage.removeItem('goto-path');

    const filteredParams = queryFilter(urlParams, ['utm_source', 'utm_medium', 'utm_campaign', 'utm_content']);
    const newParams = filteredParams ? `?${filteredParams}` : '';
    if (nextPage.startsWith('http://') || nextPage.startsWith('https://')) {
      window.location.href = `${nextPage}${newParams}`;
    } else {
      navigate(`${nextPage}${newParams}`, { replace: true });
    }
  }, []);

  // This only happens when one of the conditions above is NOT causing a REDIRECT:
  return <HubHomepage />;
}

HubIndexRedirectHandler.propTypes = {
  /** Our current user's role */
  userRole: PropTypes.oneOf(validUserRoles),
  invitesFetchStatus: PropTypes.bool,
  invites: PropTypes.array
};

export default connect(state => ({
  userRole: state.identity.role,
  invitesFetchStatus: state.invites.formState !== formStates.INPUTTING && !!state.identity.role,
  invites: state.invites.invites
}))(HubIndexRedirectHandler);
