import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import withRouter from '../../../utils/withRouter';
import ClassProgressPage from '../ClassProgressPage';
import LearnerProgressPage from '../LearnerProgressPage';
import ProductSelectionPageV2 from '../ProductSelectionPageV2';

function GradebookV2({ match: { params }, location, myProgress = false, identity, classTitle }) {
  const sectionUid = location.hash.slice(1);

  switch (params.panelName) {
    case 'workbook':
      params.learnerId = myProgress ? identity.userId : params.learnerId;
      if (params.learnerId || myProgress) {
        return (
          <LearnerProgressPage
            role={identity.role}
            params={params}
            sectionUid={sectionUid}
            myProgress={myProgress}
            classTitle={classTitle}
            attemptFilter={myProgress ? 'latest' : ''}
          />
        );
      }
      return <ClassProgressPage role={identity.role} params={params} sectionUid={sectionUid} classTitle={classTitle} />;
    default:
      return (
        <ProductSelectionPageV2
          role={identity.role}
          params={params}
          myProgress={myProgress}
          identity={identity}
          isProgressPage
        />
      );
  }
}

GradebookV2.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  myProgress: PropTypes.bool,
  identity: PropTypes.object,
  classTitle: PropTypes.string
};

export default withRouter(
  connect((state, { match: { params } }) => ({
    identity: state.identity,
    classTitle: (state.classrooms.data[params.classroomId] || {}).name
  }))(GradebookV2)
);
