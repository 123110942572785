import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Button } from '@oup/shared-front-end';
import SVGIcon, { GLYPHS } from '../SVGIcon/SVGIcon';
import styles from './ManageProductLocks.scss';

function Unit({ data = {}, content, isChecked, onCheckboxClick }) {
  const handleEltcorePreviewNode = ({ contentCode = '', uId = '' }) => {
    window.open(`${window.origin}/launch/teacher/${contentCode}?uId=${uId}`);
  };

  return (
    <div className={styles.lockItem}>
      <div className={styles.padlockContainer}>
        <SVGIcon glyph={GLYPHS.ICON_LOCK_24} />
      </div>
      <Checkbox
        id={data.uId}
        label={data.levelName}
        value={data.levelName}
        onChange={e => onCheckboxClick(e, data.uId, isChecked)}
        checked={isChecked}
        hidelabel
      />
      <div className={styles.previewContainer}>
        <Button
          variant="transparent"
          ariaLabel={content.open_activity}
          icon={{
            component: <SVGIcon glyph={GLYPHS.ICON_EYE_ON} />
          }}
          onClick={() => {
            handleEltcorePreviewNode(data);
          }}
        />
      </div>
    </div>
  );
}

Unit.propTypes = {
  data: PropTypes.object,
  content: PropTypes.object,
  isChecked: PropTypes.bool,
  onCheckboxClick: PropTypes.func
};

export default Unit;
