import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from '../../utils/compose/compose.js';
import withLocalizedContent from '../../language/withLocalizedContent';
import { GLYPHS } from '../../components/SVGIcon/SVGIcon.js';
import userRoles from '../../globals/userRoles.js';
import HubTopBanner from '../HubHomepage/parts/HubTopBanner/HubTopBanner';
import HubTopContent from '../HubHomepage/parts/HubTopContent/HubTopContent';
import styles from './WelcomeToHubPage.scss';
import homepageContentV4 from '../HubHomepage/homepageContentV4.json';
import withBreakpoint from '../../decorators/withBreakpoint';
import HubInfoSection from '../../components/HubInfoSection/HubInfoSection';
import HubActionSection from '../../components/HubActionSection/HubActionSection';

const LEARNER_URL = '/myDashboard';
const TEACHER_URL = '/myClasses';
const TEACHER_ADMIN_URL = '/myOrganization/students';
const ORG_ADMIN_URL = '/myOrganization/students';
const NO_ROLE_URL = '/org/register';

function WelcomeToHubPage({ localizedContent: { hubGlossary: hubContent }, breakpoint, userRole }) {
  const hasRole = userRole !== null && userRole !== userRoles.USER;

  const redirectBasedOnRole = () => {
    let redirectUrl = '';
    switch (userRole) {
      case userRoles.LEARNER:
        redirectUrl = LEARNER_URL;
        break;
      case userRoles.TEACHER:
        redirectUrl = TEACHER_URL;
        break;
      case userRoles.TEACHER_ADMIN:
        redirectUrl = TEACHER_ADMIN_URL;
        break;
      case userRoles.ORG_ADMIN:
        redirectUrl = ORG_ADMIN_URL;
        break;
      default:
        redirectUrl = NO_ROLE_URL;
    }
    window.location.href = redirectUrl;
  };
  return (
    <div className={styles.welcomePageContainer}>
      <HubTopBanner
        hubContent={hubContent}
        breakpoint={breakpoint}
        homepageContent={homepageContentV4}
        customClassName={styles.welcomePageWideBanner}
        isWelcomePage
        withButton={false}
      />
      {hasRole ? (
        <HubInfoSection
          glyph={GLYPHS.ICON_LAPTOP_HOMEPAGE}
          title={hubContent.welcome_homepage_organization_subtitle_role}
        >
          <span className={styles.welcomePageTextExplanationContainer}>
            {hubContent.welcome_homepage_organization_text_role_1}
            <span className={styles.boldedText}>{hubContent.welcome_homepage_organization_text_role_2}</span>
            {hubContent.welcome_homepage_organization_text_role_3}
            <span className={styles.boldedText}>{hubContent.welcome_homepage_organization_text_role_4}</span>
            {hubContent.welcome_homepage_organization_text_role_5}
          </span>
        </HubInfoSection>
      ) : (
        <HubInfoSection
          glyph={GLYPHS.ICON_LAPTOP_HOMEPAGE}
          title={hubContent.welcome_homepage_organization_subtitle_no_role}
        >
          <span className={styles.welcomePageTextExplanationContainer}>
            {hubContent.welcome_homepage_organization_text_no_role_1}
            <span className={styles.boldedText}>{hubContent.welcome_homepage_organization_text_no_role_2}</span>
            {hubContent.welcome_homepage_organization_text_no_role_3}
            <span className={styles.boldedText}>{hubContent.welcome_homepage_organization_text_no_role_4}</span>
            {hubContent.welcome_homepage_organization_text_no_role_5}
          </span>
        </HubInfoSection>
      )}

      {hasRole ? (
        <HubActionSection onClick={redirectBasedOnRole} buttonText={hubContent.welcome_homepage_try_it_now}>
          <div className={styles.welcomePageTextSubtitleContainer}>
            {hubContent.welcome_homepage_explanation_role_title}
          </div>
        </HubActionSection>
      ) : (
        <HubActionSection onClick={redirectBasedOnRole} buttonText={hubContent.welcome_homepage_register_organization}>
          <div className={styles.welcomePageTextSubtitleContainer}>
            {hubContent.welcome_homepage_explanation_no_role_title}
          </div>
          <div className={styles.welcomePageTextExplanationContainer}>
            {hubContent.welcome_homepage_explanation_no_role_text}
          </div>
        </HubActionSection>
      )}
      <HubTopContent
        customTextClassName={styles.hubTopContentContainerText}
        text={hubContent.welcome_homepage_conclusion}
        homepageContent={homepageContentV4}
      />
    </div>
  );
}

WelcomeToHubPage.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  breakpoint: PropTypes.string,
  userRole: PropTypes.string
};

export default compose(
  withLocalizedContent('hubGlossary'),
  withBreakpoint,
  connect(state => ({
    userRole: state.identity.role
  }))
)(WelcomeToHubPage);
