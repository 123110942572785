import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import { useWizard, Heading } from '@oup/shared-front-end';

import compose from '../../../utils/compose/compose.js';
import animationTimeout from '../../ClassOnboardingWizard/animationUtils/animationTimeout';
import animationStyles from '../../ClassOnboardingWizard/animationUtils/animationStyles.scss';
import withLocalizedContent from '../../../language/withLocalizedContent';
import { featureIsEnabled } from '../../../globals/envSettings';
import { setEditedPlacementTest, setPlacementTest } from '../../../redux/reducers/placementTestSessionCreate';
import useNotifications from '../../../utils/hooks/opt/useNotifications';
import NotificationsAndReminders from './NotificationsAndReminders';
import styles from './EmailNotificationsAndReminders.scss';
import {
  getPlacementTestSessionRequest,
  updatePlacementTestSessionRequest
} from '../../../redux/actions/placementTestOnboardingWizard';
import { isDefaultReminders } from '../utils/helpers';
import { refreshPlacementTestList } from '../../../redux/actions/placementTests';

function EmailNotificationsAndReminders({
  placementTestSessionName,
  localizedContent: { placementTests: placementTestsContent },
  placementTestSessionUpdated,
  setEditedPlacementTestAction,
  updatePlacementTestSessionRequestAction,
  orgId,
  placementTestSessionIdCreated,
  placementTestSessionId,
  placementTestSessionNameValue,
  refreshPlacementTestListAction,
  placementTestSessionNamePending,
  getPlacementTestSessionRequestAction
}) {
  const {
    placementTestSessionCreate: { placementTest, editedPlacementTest }
  } = useSelector(({ placementTestSessionCreate }) => ({
    placementTestSessionCreate
  }));

  const { initialStateRef, state, onStateUpdate } = useNotifications({
    placementTest,
    editedPlacementTest,
    setEditedPlacementTestAction
  });

  const { stepHandler, loading, setNextStepDisabled, nextStep } = useWizard();
  const [stepOut, setStepOut] = useState(false);
  const fadeOut = stepOut ? animationStyles.fadeOut : '';
  const checkingName = placementTestSessionNamePending ? animationStyles.loading : '';
  const canShowStudentResults = featureIsEnabled('show-test-results-to-students');
  const dependencyArrayBasedOnFlag = canShowStudentResults ? [] : [placementTestSessionUpdated];
  const sessionId = placementTestSessionIdCreated || placementTestSessionId;

  setNextStepDisabled(loading || !state.isValid || checkingName);

  useEffect(() => {
    if (placementTestSessionUpdated) {
      getPlacementTestSessionRequestAction({ orgId, sessionId });
      nextStep();

      if (!canShowStudentResults) {
        refreshPlacementTestListAction();
      }
    }
  }, dependencyArrayBasedOnFlag);

  stepHandler(async () => {
    if (placementTestSessionUpdated) {
      return;
    }

    if (canShowStudentResults) {
      setEditedPlacementTestAction({
        sendEmailsToStudents: state.sendEmailsToStudents,
        notificationReminders: state.notificationReminders
      });
    } else {
      updatePlacementTestSessionRequestAction({
        orgId,
        sessionId,
        placementTestSessionNameValue,
        placementTest: {
          isSetupCompleted: true,
          ...editedPlacementTest,
          ...(!isDefaultReminders(state.notificationReminders)
            ? { notificationReminders: editedPlacementTest?.notificationReminders }
            : {}),
          ...{ sendEmailsToStudents: editedPlacementTest.sendEmailsToStudents }
        }
      });
    }
    setStepOut(true);
    await animationTimeout();
    throw new Error();
  });

  return (
    <div
      data-testid="ONBOARDING_WIZARD_CREATE_A_PLACEMENT_TEST_SESSION_EMAIL_NOTIFICATIONS_CONTAINER"
      className={`${fadeOut} ${styles.stepContainer}`}
    >
      <Heading
        text={`${placementTestsContent.placement_test_notification_page_title} ${placementTestSessionName ||
          placementTestsContent.placement_test_notification_page_session_name_fallback}`}
        size="medium"
        className={styles.heading}
      />
      <NotificationsAndReminders
        sendEmailsToStudents={state.sendEmailsToStudents}
        notificationReminders={state.notificationReminders}
        initialState={initialStateRef.current}
        onStateUpdate={onStateUpdate}
      />
    </div>
  );
}

EmailNotificationsAndReminders.propTypes = {
  placementTestSessionName: PropTypes.string,
  localizedContent: PropTypes.object.isRequired,
  placementTestSessionUpdated: PropTypes.bool,
  setEditedPlacementTestAction: PropTypes.func,
  updatePlacementTestSessionRequestAction: PropTypes.func,
  orgId: PropTypes.string,
  placementTestSessionIdCreated: PropTypes.bool,
  placementTestSessionId: PropTypes.string,
  placementTestSessionNameValue: PropTypes.string,
  refreshPlacementTestListAction: PropTypes.func,
  placementTestSessionNamePending: PropTypes.bool,
  getPlacementTestSessionRequestAction: PropTypes.func
};

export default compose(
  withLocalizedContent('placementTests'),
  connect(
    ({ placementTestSessionCreate, placementOnboardingWizard, identity }) => ({
      placementTestSessionName: placementTestSessionCreate.placementTestSessionNameValue,
      placementTestSessionUpdated: placementOnboardingWizard.placementTestSessionUpdated,
      orgId: identity.currentOrganisationId,
      placementTestSessionIdCreated: placementOnboardingWizard.placementTestSessionIdCreated,
      placementTestSessionId: placementTestSessionCreate.placementTestSessionId,
      placementTestSessionNameValue: placementTestSessionCreate.placementTestSessionNameValue,
      placementTestSessionNamePending: placementOnboardingWizard.placementTestSessionNamePending
    }),
    {
      setEditedPlacementTestAction: setEditedPlacementTest,
      setPlacementTestAction: setPlacementTest,
      updatePlacementTestSessionRequestAction: updatePlacementTestSessionRequest,
      refreshPlacementTestListAction: refreshPlacementTestList,
      getPlacementTestSessionRequestAction: getPlacementTestSessionRequest
    }
  )
)(EmailNotificationsAndReminders);
