import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { useWizard, RadioButtonGroup, Heading } from '@oup/shared-front-end';
import { ICON_STUDENT_CHOOSE_ROLE_24, ICON_TEACHER_CHOOSE_ROLE_24 } from '@oup/shared-front-end/svg/oup';
import compose from '../../../utils/compose/compose.js';
import { HubLayoutConstants } from '../../../globals/hubConstants';
import withLocalizedContent from '../../../language/withLocalizedContent';

import roleSelectStyles from './ChooseRole.scss';

function ChooseRole({
  localizedContent: { selfRegisteredUserOnboardingWizard: content },
  selectRoleAction,
  setSelectedRole,
  selectedRole,
  setChooseRoleWizardCompleted
}) {
  const { setNextStepDisabled, stepHandler } = useWizard();

  stepHandler(() => {
    setChooseRoleWizardCompleted(true);
  });

  useEffect(() => {
    if (selectedRole) {
      selectRoleAction(selectedRole, true);
    }
    setNextStepDisabled(!selectedRole);
  }, [selectedRole]);

  return (
    <div
      data-testid="SELF_REGISTERED_USER_ONBOARDING_WIZARD_CHOOSE_ROLE_CONTAINER"
      className={roleSelectStyles.chooseRoleStep}
    >
      <Heading
        text={content.self_registered_user_onboarding_wizard_choose_role}
        size="medium"
        className={roleSelectStyles.heading}
      />
      <Heading
        text={content.self_registered_user_onboarding_wizard_role_choose_step_subtitle}
        variant="h2"
        size="small"
        className={roleSelectStyles.subHeading}
      />
      <RadioButtonGroup
        classnames={{
          fieldset: roleSelectStyles.radioButtonGroupFieldset,
          container: roleSelectStyles.radioButtonGroupContainer
        }}
        variant="outline"
        name="choose-role"
        legend="choose role"
        checkedValue={[selectedRole]}
        radioGroup={[
          {
            id: HubLayoutConstants.SELF_SELECT_ROLES[0].key,
            label: HubLayoutConstants.SELF_SELECT_ROLES[0].title,
            value: HubLayoutConstants.SELF_SELECT_ROLES[0].key,
            image: <ICON_STUDENT_CHOOSE_ROLE_24 className={roleSelectStyles.RadiobuttonImage} aria-hidden="true" />
          },
          {
            id: HubLayoutConstants.SELF_SELECT_ROLES[1].key,
            label: HubLayoutConstants.SELF_SELECT_ROLES[1].title,
            value: HubLayoutConstants.SELF_SELECT_ROLES[1].key,
            image: <ICON_TEACHER_CHOOSE_ROLE_24 className={roleSelectStyles.RadiobuttonImage} aria-hidden="true" />
          }
        ]}
        onChange={e => setSelectedRole(e.target.value)}
      />
    </div>
  );
}

ChooseRole.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  selectRoleAction: PropTypes.func.isRequired,
  selectedRole: PropTypes.string.isRequired,
  setSelectedRole: PropTypes.func.isRequired,
  setChooseRoleWizardCompleted: PropTypes.func.isRequired
};

export default compose(withLocalizedContent('selfRegisteredUserOnboardingWizard', 'hubGlossary'))(ChooseRole);
