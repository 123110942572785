import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import classnames from 'classnames';
// Redux
import { connect } from 'react-redux';
import { Heading } from '@oup/shared-front-end';
import compose from '../../../utils/compose/compose.js';
import { loadClassAssessments } from '../../../redux/actions/classAssessments';
import { hubDownloadResource } from '../../../redux/actions/hubResource';
import { openRedeemModal, toggleAssessmentLicenceTab } from '../../../redux/actions/hubUi';
import { switchToPage, setOnlineTestData } from '../../../redux/actions/onlineTestActions';
// HOC
import withLocalizedContent from '../../../language/withLocalizedContent';
// Components
import SubSectionSkeletonLoader from '../../../components/SkeletonLoader/SubSectionSkeletonLoader';
import HubEmptyStateRestyle from '../../../components/HubEmptyStateRestyle/HubEmptyStateRestyle';
import ClassAssessmentTest from '../../../components/ClassAssessmentTest/ClassAssessmentTest';
import HubListHeader from '../../HubListPage/HubListHeader';
import OnlineTestModal from '../../../components/OnlineTestModal/OnlineTestModal';
import HubPreviewInfoModal from '../../../components/HubPreviewInfoModal/HubPreviewInfoModal';
import HubPreviewWarningModal from '../../../components/HubPreviewWarningModal/HubPreviewWarningModal';
import OnlineTestPanel from '../../../panels/OnlineTestPanel';
// Constants
import {
  TEST_STATUS,
  HubLayoutConstants,
  HubIllustrationConstants,
  LTI_ACTIONS,
  ONLINE_TEST_PANEL_CONTEXT,
  REFRESH_CLASS_ASSESSMENTS_INTERVAL,
  HubIllustrationAltText
} from '../../../globals/hubConstants';
import userRoles from '../../../globals/userRoles';
import breakpoints from '../../../globals/breakpoints';
import { GLYPHS } from '../../../components/SVGIcon/SVGIcon';
// Services
import getOnTestClickAction, { getViewInfoModalData } from '../Services/getClassTestData.js';
import downloadOrPreviewAssessment from '../../HubCourseAssessments/Services/downloadOrPreviewAssessment';
// Styles
import colors from '../../../globals/colors';
import styles from './ClassAssessment.scss';
import { initialiseInstance, setFilter, setSort } from '../../../redux/reducers/data/search.reducer';
import HubFilterBar from '../../../components/HubFilterBar/HubFilterBar';
import { assessmentFilterOptions, assessmentSortOptions } from '../../../globals/hubSearchFilter';
import { featureIsEnabled } from '../../../globals/envSettings';

const initialFilters = { completed: true, active: true, upcoming: true };
const initialSort = 'dueDate:asc';
const instance = 'orgClassAssessments';

class ClassAssessment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedToPreviewTestId: '',
      selectedToViewInfoTestId: '',
      selectedToViewWarningTestId: '',
      isSetTestModalOpen: false,
      isEditTestPanelOpen: false,
      isCancelTestPanelOpen: false,
      isFilterLoaded: false
    };
  }

  componentDidMount() {
    const { initialiseInstanceAction } = this.props;
    initialiseInstanceAction(instance, false, initialFilters, undefined, initialSort);

    this._getAssessments();
    this.fetchDataInterval = setInterval(() => {
      this._getAssessments();
    }, REFRESH_CLASS_ASSESSMENTS_INTERVAL);
  }

  componentDidUpdate(prevProps) {
    const { testSuccess, assessmentFilters } = this.props;
    const { isCancelTestPanelOpen, isEditTestPanelOpen, isFilterLoaded } = this.state;

    if (
      (testSuccess !== prevProps.testSuccess && !isCancelTestPanelOpen && !isEditTestPanelOpen) ||
      assessmentFilters.filtersAssessments.completed !== prevProps.assessmentFilters.filtersAssessments.completed ||
      assessmentFilters.filtersAssessments.active !== prevProps.assessmentFilters.filtersAssessments.active ||
      assessmentFilters.filtersAssessments.upcoming !== prevProps.assessmentFilters.filtersAssessments.upcoming ||
      assessmentFilters.sortAssessments !== prevProps.assessmentFilters.sortAssessments
    ) {
      this._getAssessments();
      if (!isFilterLoaded) this.setState({ isFilterLoaded: true });
    }
  }

  componentWillUnmount() {
    // Clear the interval when the component is unmounted
    clearInterval(this.fetchDataInterval);
  }

  _getAssessments = () => {
    const {
      props: { getClassAssessments, orgId, classroomId, assessmentFilters }
    } = this;
    const allFiltersUnchecked =
      !assessmentFilters.filtersAssessments.completed &&
      !assessmentFilters.filtersAssessments.active &&
      !assessmentFilters.filtersAssessments.upcoming;

    const payload = {
      orgId,
      classroomId,
      filters: allFiltersUnchecked ? initialFilters : this.getFilters(),
      sortBy: allFiltersUnchecked ? initialSort : this.getSort()
    };
    getClassAssessments(payload);
  };

  getFilters = () => {
    const { userRole, assessmentFilters } = this.props;
    const filters = {
      completed: assessmentFilters.filtersAssessments.completed,
      active: assessmentFilters.filtersAssessments.active,
      upcoming: assessmentFilters.filtersAssessments.upcoming
    };

    if (userRole === userRoles.LEARNER) {
      filters.withLicence = true;
      filters.noLicence = true;
    } else {
      filters.allStudentsHaveLicence = true;
      filters.someStudentsNeedLicence = true;
    }
    return filters;
  };

  getSort = () => {
    const { assessmentFilters } = this.props;
    const sort = assessmentFilters.sortAssessments;
    return sort;
  };

  setFilterActionAssesments = (valueName, value) => {
    const { setFilterAction } = this.props;
    setFilterAction(instance, valueName, value);
  };

  setSortActionAssessment = sort => {
    const { setSortAction } = this.props;
    setSortAction(instance, sort[0]);
  };

  getPageContent = () => {
    const {
      props: {
        assessmentsData,
        userRole,
        classroomId,
        orgId,
        openRedeemCodeModal,
        hubContent,
        toggleAssessmentLicenceCodeTab,
        localizedContent: { hubClassAssessments: assessmentContent },
        downloadIsLoading
      },
      state: { selectedToPreviewTestId, selectedToViewInfoTestId, selectedToViewWarningTestId },
      openPreviewTestModal,
      closePreviewTestModal,
      closeTestInfoModal,
      _openSetTestPanel,
      openWarningInfoModal,
      closeWarningInfoModal
    } = this;
    const previewModalActions = { openPreviewTestModal, closeTestInfoModal };
    const { sortedActiveTestIds, sortedUpcomingTestIds, sortedCompletedTestIds } = this.filterTestIds();
    const showFirstButtonInformLabel = userRole !== userRoles.LEARNER;

    return (
      <>
        <OnlineTestModal
          test={assessmentsData?.[selectedToPreviewTestId]}
          selectedTestId={selectedToPreviewTestId}
          closeModal={closePreviewTestModal}
          openWarningModal={openWarningInfoModal}
          hubContent={hubContent}
          userRole={userRole}
          downloadIsLoading={downloadIsLoading}
        />
        <HubPreviewInfoModal
          testId={selectedToViewInfoTestId}
          testData={assessmentsData?.[selectedToViewInfoTestId]}
          modalData={
            selectedToViewInfoTestId && assessmentsData?.[selectedToViewInfoTestId]
              ? getViewInfoModalData(
                  assessmentContent,
                  assessmentsData?.[selectedToViewInfoTestId],
                  previewModalActions,
                  userRole,
                  classroomId,
                  orgId
                )[userRole]
              : {}
          }
          ariaLabel={assessmentContent.preview_info_modal_aria_label.replace(
            '{testTitle}',
            assessmentsData[selectedToViewInfoTestId]?.title
          )}
          closeModal={closeTestInfoModal}
          openRedeemModal={openRedeemCodeModal}
          openStudensStatusLicenceTab={toggleAssessmentLicenceCodeTab}
          userRole={userRole}
          hubContent={assessmentContent}
          showFirstButtonInformLabel={showFirstButtonInformLabel}
        />
        <HubPreviewWarningModal
          testId={selectedToViewWarningTestId}
          ariaLabel={assessmentContent.preview_info_modal_aria_label.replace(
            '{testTitle}',
            assessmentsData[selectedToViewWarningTestId]?.title
          )}
          closeModal={closePreviewTestModal}
          hubContent={assessmentContent}
          closeWarningInfoModal={closeWarningInfoModal}
        />
        <div
          className={classnames(styles.assessmentsContainer, userRole === userRoles.LEARNER && styles.learnerTestOrder)}
        >
          {sortedActiveTestIds.length || sortedUpcomingTestIds.length ? (
            <div>
              <HubListHeader
                item={{
                  name: assessmentContent.active_and_upcoming_tests,
                  status: '',
                  buttonText: userRole !== userRoles.LEARNER ? assessmentContent.set_test : '',
                  action: _openSetTestPanel
                }}
                showCheckboxHeader={false}
                hasHeaderButtonAccess={userRole !== userRoles.LEARNER}
                customClassName="containerWithNoMargin"
              />
              <ul>
                {this.getTestItems(sortedActiveTestIds, TEST_STATUS.ACTIVE)}
                {this.getTestItems(sortedUpcomingTestIds, TEST_STATUS.UPCOMING)}
              </ul>
            </div>
          ) : null}
          {sortedCompletedTestIds.length ? (
            <div>
              <HubListHeader
                item={{
                  name: assessmentContent.completed_tests,
                  status: userRole !== userRoles.LEARNER ? assessmentContent.percentage_completed : '',
                  buttonText: userRole !== userRoles.LEARNER ? assessmentContent.set_test : '',
                  action: _openSetTestPanel
                }}
                showCheckboxHeader={false}
                hasHeaderButtonAccess={userRole !== userRoles.LEARNER}
                customClassName="completedTest"
              />
              <ul>{this.getTestItems(sortedCompletedTestIds, TEST_STATUS.COMPLETED)}</ul>
            </div>
          ) : null}
        </div>
      </>
    );
  };

  getTestItems = (ids = [], status) => {
    const {
      props: {
        assessmentsData,
        userRole,
        openRedeemCodeModal,
        toggleAssessmentLicenceCodeTab,
        localizedContent: { hubClassAssessments: assessmentContent },
        context,
        orgId,
        classroomId
      },
      openPreviewTestModal,
      openTestInfoModal,
      openEditTestPanel,
      openCancelTestPanel
    } = this;

    // MOCK DATA for development of 8409 that have been used also in 7282
    const testLicenceStudentsList = {
      '38d34810-df15-11eb-b147-0586af4d1a62': {
        expiresOn: '2022-05-18T10:30:13.58Z'
      },
      '469d2510-31b8-11ec-90fd-49c78515b79c': {
        expiresOn: '2022-05-06T10:30:13.58Z'
      }
      // ,
      // '7405f880-31b6-11ec-91d2-2f212900bed3': {
      //   expiresOn: '2022-05-06T10:30:13.58Z'
      // }
    };

    // MOCK DATA for development of 8409 that have been used also in 7282
    if (assessmentsData && ids) {
      ids.forEach(id => {
        if (assessmentsData[id].status && assessmentsData[id].status === 'upcoming') {
          assessmentsData[id].licenceStudentsList = testLicenceStudentsList;
        }
      });
    }

    const showFirstButtonInformLabel = userRole !== userRoles.LEARNER;

    return ids.map((id, index) => {
      const onTestClick = () => {
        getOnTestClickAction(
          id,
          userRole,
          status,
          assessmentsData[id]?.hasLicence,
          openPreviewTestModal,
          openTestInfoModal
        );
      };

      return (
        <ClassAssessmentTest
          key={id}
          id={id}
          orgId={orgId}
          classroomId={classroomId}
          testData={assessmentsData[id]}
          status={status}
          userRole={userRole}
          content={assessmentContent}
          openEditTestPanel={openEditTestPanel}
          onTestClick={onTestClick}
          openPreviewTestModal={openPreviewTestModal}
          openTestInfoModal={openTestInfoModal}
          openRedeemModal={openRedeemCodeModal}
          openCancelTestPanel={openCancelTestPanel}
          dropdownTop={this.displayDropdownOnTop(ids.length, index)}
          openStudensStatusLicenceTab={toggleAssessmentLicenceCodeTab}
          showFirstButtonInformLabel={showFirstButtonInformLabel}
          context={context}
        />
      );
    });
  };

  openCancelTestPanel = testId => {
    const { switchToPageAction, setOnlineTestDataAction, assessmentsData, classroomId } = this.props;

    this.setState({ isCancelTestPanelOpen: true });
    setOnlineTestDataAction({
      test: assessmentsData[testId],
      productAssessmentTitle: assessmentsData[testId].assessmentTitle
    });
    switchToPageAction({ page: HubLayoutConstants.ONLINE_TEST_STEPS.CANCEL_TEST_CONFIRMATION, classId: classroomId });
  };

  closeCancelTestPanel = () => {
    this.setState({ isCancelTestPanelOpen: false });
  };

  openEditTestPanel = testId => {
    const { switchToPageAction, setOnlineTestDataAction, assessmentsData, classroomId } = this.props;

    this.setState({ isEditTestPanelOpen: true });
    setOnlineTestDataAction({
      test: assessmentsData[testId],
      productAssessmentTitle: assessmentsData[testId].assessmentTitle
    });
    switchToPageAction({ page: HubLayoutConstants.ONLINE_TEST_STEPS.EDIT_TEST_SETTINGS_PAGE, classId: classroomId });
  };

  closeEditTestPanel = () => {
    this.setState({ isEditTestPanelOpen: false });
  };

  // eslint-disable-next-line class-methods-use-this
  displayDropdownOnTop = (itemsLength, currentItemIndex) =>
    currentItemIndex === itemsLength - 1 || currentItemIndex === itemsLength - 2;

  filterTestIds = () => {
    const {
      props: { assessmentIds, assessmentsData }
    } = this;

    const sortedActiveTestIds = [];
    const sortedUpcomingTestIds = [];
    const sortedCompletedTestIds = [];

    assessmentIds.forEach(id => {
      const testStatus = assessmentsData[id]?.status;

      if (testStatus === TEST_STATUS.ACTIVE) {
        sortedActiveTestIds.push(id);
      }
      if (testStatus === TEST_STATUS.UPCOMING) {
        sortedUpcomingTestIds.push(id);
      }
      if (testStatus === TEST_STATUS.COMPLETED) {
        sortedCompletedTestIds.push(id);
      }
    });

    return { sortedActiveTestIds, sortedUpcomingTestIds, sortedCompletedTestIds };
  };

  _handlePreviewTest = (action = LTI_ACTIONS.PLAY, assessmentId) => {
    const {
      props: { downloadResource, userRole, assessmentsData }
    } = this;

    const testData = assessmentsData[assessmentId];
    const courseId = testData.courseId;
    const courseCode = testData.courseCode;
    const digitalResourceId = testData?.digitalResourceId;
    const isAdaptedTest = testData?.isAdaptedTest;
    const lorUserRole =
      userRole === userRoles.LEARNER
        ? HubLayoutConstants.ASSESSMENT_USER_ROLES.STUDENT
        : HubLayoutConstants.ASSESSMENT_USER_ROLES.INSTRUCTOR;
    const testDuration = userRole === userRoles.LEARNER && testData?.testDuration > 0 ? testData?.testDuration : '';

    downloadOrPreviewAssessment(
      downloadResource,
      action,
      digitalResourceId,
      isAdaptedTest,
      lorUserRole,
      testDuration,
      courseCode,
      courseId,
      assessmentId
    );
  };

  _downloadAssessment = (test, isAdaptedTest = false) => {
    const {
      props: { userRole, downloadResource }
    } = this;

    const lorUserRole =
      userRole === userRoles.LEARNER
        ? HubLayoutConstants.ASSESSMENT_USER_ROLES.STUDENT
        : HubLayoutConstants.ASSESSMENT_USER_ROLES.INSTRUCTOR;
    downloadOrPreviewAssessment(
      downloadResource,
      LTI_ACTIONS.DOWNLOAD,
      test.printableResourceId || '',
      isAdaptedTest,
      lorUserRole
    );
  };

  // Handle "Preview/Launch test" modal
  openPreviewTestModal = id => {
    this.setState(() => ({ selectedToPreviewTestId: id }));

    this._handlePreviewTest('play', id);
  };

  closePreviewTestModal = () => {
    this._getAssessments();
    this.setState(() => ({ selectedToPreviewTestId: '' }));
  };

  closeWarningInfoModal = () => {
    this.setState(() => ({ selectedToViewWarningTestId: '' }));
  };

  openWarningInfoModal = id => {
    this.setState(() => ({ selectedToViewWarningTestId: id }));
  };

  // Handle "View test information" modal
  openTestInfoModal = id => {
    this.setState(() => ({ selectedToViewInfoTestId: id }));
  };

  closeTestInfoModal = () => {
    this.setState(() => ({ selectedToViewInfoTestId: '' }));
  };

  // Handle "Set online test" modal
  _openSetTestPanel = () => {
    const { classroomId, switchToPageAction } = this.props;

    switchToPageAction({
      page: HubLayoutConstants.ONLINE_TEST_STEPS.CHOOSE_TEST_PAGE,
      classId: classroomId
    });

    this.setState({ isSetTestModalOpen: true });
  };

  _closeSetTestPanel = () => {
    this.setState({ isSetTestModalOpen: false });
  };

  _getOnlineTestPanelParameters = () => {
    const { isSetTestModalOpen, isEditTestPanelOpen, isCancelTestPanelOpen } = this.state;

    if (isSetTestModalOpen) {
      return {
        closePanel: this._closeSetTestPanel,
        isOpen: true,
        downloadAssessment: this._downloadAssessment,
        context: ONLINE_TEST_PANEL_CONTEXT.CLASS_CONTEXT,
        panelFlow: {
          isCancelTestFlow: false,
          isEditTestFlow: false
        }
      };
    }

    if (isEditTestPanelOpen) {
      return {
        closePanel: this.closeEditTestPanel,
        panelFlow: {
          isCancelTestFlow: false,
          isEditTestFlow: true
        }
      };
    }

    if (isCancelTestPanelOpen) {
      return {
        closePanel: this.closeCancelTestPanel,
        panelFlow: {
          isCancelTestFlow: true,
          isEditTestFlow: false
        }
      };
    }

    return {};
  };

  render() {
    const {
      props: {
        assessmentIds,
        classTitle,
        hubContent,
        areAssessmentsLoading,
        breakpoint,
        assessmentFilters,
        userRole,
        localizedContent: { hubClassAssessments: assessmentContent },
        initialiseInstanceAction
      },
      state: { isFilterLoaded },
      setFilterActionAssesments,
      setSortActionAssessment,
      getPageContent,
      _openSetTestPanel
    } = this;

    const classPageTitle = `${classTitle} | ${hubContent.assessment}`;
    const onlineTestPanelParameters = this._getOnlineTestPanelParameters();
    const loadResetFilters = isFilterLoaded && !areAssessmentsLoading && !assessmentIds.length;
    const loadNoResults = !isFilterLoaded && !areAssessmentsLoading && !assessmentIds.length;

    const noResultsTemplate = (
      <HubEmptyStateRestyle
        iconSrc={HubIllustrationConstants.TEST}
        title={
          userRole === userRoles.LEARNER
            ? assessmentContent.empty_state_title_for_student
            : assessmentContent.empty_state_title_for_teacher.replace('{className}', classTitle)
        }
        bodyText={
          userRole === userRoles.LEARNER ? assessmentContent.student_empty_state : assessmentContent.teacher_empty_state
        }
        {...(userRole !== userRoles.LEARNER && {
          btnFilledBase: {
            text: assessmentContent.set_test,
            action: _openSetTestPanel,
            icon: GLYPHS.ICON_PLUS
          }
        })}
      />
    );

    const classAssessmentsTemplate = (
      <>
        <div>
          <HubFilterBar
            idPrefix="organizationClassSearch"
            filterButtonText={breakpoint !== breakpoints.XXS ? hubContent.status_button : hubContent.filter_button}
            overlayLabelText={hubContent.status_button}
            breakpoint={breakpoint}
            filterOptions={assessmentFilterOptions(
              'organizationClassSearch',
              [
                assessmentFilters.filtersAssessments.completed,
                assessmentFilters.filtersAssessments.active,
                assessmentFilters.filtersAssessments.upcoming
              ],
              setFilterActionAssesments,
              hubContent
            )}
            sortOnChange={setSortActionAssessment}
            sortOptions={assessmentSortOptions(
              'organizationClassSearch',
              assessmentFilters.sortAssessments,
              hubContent
            )}
            ariaControls="searchResults"
            sortValue={assessmentFilters.sortAssessments}
          />
        </div>
        {!areAssessmentsLoading && getPageContent()}
        {areAssessmentsLoading && (
          <SubSectionSkeletonLoader
            tabName=""
            panelName=""
            speed={2}
            foregroundColor={colors.COLOR_GREY_DISABLED}
            backgroundColor={colors.COLOR_WHITE}
          />
        )}
        {loadResetFilters && (
          <HubEmptyStateRestyle
            iconSrc={HubIllustrationConstants.SEARCH}
            iconAlt={HubIllustrationAltText.SEARCH}
            title={assessmentContent.no_results_found_title}
            bodyText={assessmentContent.no_results_found_subtitle}
            btnFilledBase={{
              text: assessmentContent.reset_filters,
              action: () => initialiseInstanceAction(instance, false, initialFilters, undefined, initialSort)
            }}
            {...(userRole !== userRoles.LEARNER && {
              btnOutlineBase: {
                isPrimaryButton: true,
                text: hubContent.set_test,
                icon: GLYPHS.ICON_PLUS,
                action: _openSetTestPanel
              }
            })}
          />
        )}
      </>
    );

    const headingArgs = {
      text: hubContent.assessment,
      size: 'small'
    };

    return (
      <div>
        <Helmet title={classPageTitle} />
        {featureIsEnabled('navigation-changes') && <Heading {...headingArgs} />}
        {loadNoResults ? noResultsTemplate : classAssessmentsTemplate}
        <OnlineTestPanel
          closePanel={() => onlineTestPanelParameters?.closePanel()}
          isOpen={onlineTestPanelParameters?.isOpen || false}
          context={onlineTestPanelParameters?.context || ''}
          panelFlow={onlineTestPanelParameters?.panelFlow}
        />
      </div>
    );
  }
}

ClassAssessment.propTypes = {
  classTitle: PropTypes.string,
  testIds: PropTypes.array.isRequired,
  testsData: PropTypes.object.isRequired,
  areTestsLoading: PropTypes.bool.isRequired,
  getClassAssessments: PropTypes.func.isRequired,
  userRole: PropTypes.string.isRequired,
  orgId: PropTypes.string.isRequired,
  classroomId: PropTypes.string.isRequired,
  downloadResource: PropTypes.func.isRequired,
  openRedeemCodeModal: PropTypes.func.isRequired,
  toggleAssessmentLicenceCodeTab: PropTypes.func,
  hubContent: PropTypes.object,
  dropdownTop: PropTypes.bool,
  localizedContent: PropTypes.object.isRequired,
  breakpoint: PropTypes.string.isRequired,
  testCancelled: PropTypes.bool,
  classrooms: PropTypes.object,
  testSuccess: PropTypes.bool,
  switchToPageAction: PropTypes.func,
  setOnlineTestDataAction: PropTypes.func,
  context: PropTypes.string,
  downloadIsLoading: PropTypes.bool,
  assessmentFilters: PropTypes.object,
  areAssessmentsLoading: PropTypes.bool,
  assessmentIds: PropTypes.array,
  assessmentsData: PropTypes.object,
  initialiseInstanceAction: PropTypes.func,
  setFilterAction: PropTypes.func,
  setSortAction: PropTypes.func
};

export default compose(
  connect(
    ({
      classAssessments: { loading: areTestsLoading = false, classTests: testsData = {}, testIds = [] } = {},
      identity: { role: userRole } = {},
      onlineTest: { success: testSuccess = null },
      classrooms: { data: classrooms },
      hubResourceDownload: { downloadIsLoading = null },
      search: {
        orgClassAssessments: {
          loading: areAssessmentsLoading = false,
          sort: sortAssessments = initialSort,
          filters: filtersAssessments = initialFilters,
          ids: assessmentIds = [],
          data: assessmentsData = {}
        } = {}
      } = {}
    }) => ({
      areTestsLoading,
      testsData,
      testIds,
      userRole,
      testSuccess,
      classrooms,
      downloadIsLoading,
      areAssessmentsLoading,
      assessmentIds,
      assessmentsData,
      assessmentFilters: {
        sortAssessments,
        filtersAssessments
      }
    }),
    {
      getClassAssessments: loadClassAssessments,
      downloadResource: hubDownloadResource,
      openRedeemCodeModal: openRedeemModal,
      toggleAssessmentLicenceCodeTab: toggleAssessmentLicenceTab,
      switchToPageAction: switchToPage,
      setOnlineTestDataAction: setOnlineTestData,
      initialiseInstanceAction: initialiseInstance,
      setFilterAction: setFilter,
      setSortAction: setSort
    }
  ),
  withLocalizedContent('hubClassAssessments')
)(ClassAssessment);
