import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useWizard } from '@oup/shared-front-end';
import compose from '../../utils/compose/compose.js';

import { closePlacementTestOnboardingWizard } from '../../redux/actions/hubUi';
import { clearWizardState } from '../../redux/actions/classOnboardingWizard';
import { closeForm } from '../../redux/reducers/placementTestSessionCreate';
import Button, { buttonTypes } from '../Button/Button';
import SVGIcon, { GLYPHS } from '../SVGIcon/SVGIcon.js';
import Link from '../Link/Link';
import wizardStyles from './PlacementTestOnboardingWizard.scss';
import withLocalizedContent from '../../language/withLocalizedContent';

function Header({
  closeWizardAction,
  closeWizardWithWarningPopup,
  clearWizardStateAction,
  clearPlacementTestSessionCreateStateAction,
  localizedContent: { placementTests: placementTestsContent, hubGlossary: hubContent }
}) {
  const { step, isLastStep, isFirstStep } = useWizard();

  const _renderStepHeading = () => (
    <div className={wizardStyles.topContainer}>
      {/* Header title */}
      <div className={wizardStyles.title}>
        <span>{placementTestsContent.create_a_placement_test}</span>{' '}
        {isFirstStep ? `${placementTestsContent.placement_test_session_welcome}` : `${hubContent.step} ${step} `}
      </div>

      {/* Help and Support */}
      <div className={wizardStyles.helpAndSupport}>
        <Link
          id="placementTestSessionOnboardingWizard-supportButton"
          dataTestId="PLACEMENT_TEST_ONBOARDING_WIZARD_SUPPORT_BUTTON"
          to={hubContent.help_and_support_link_to}
          target="_blank"
          role="button"
        >
          <SVGIcon glyph={GLYPHS.ICON_HELP_AND_SUPPORT} />
          <span className={wizardStyles.helpSupportText}>{hubContent.help_and_support}</span>
          <span className="a11y-hide">Help</span>
        </Link>
      </div>

      {/* Close button */}
      <Button
        text={hubContent.close}
        onClick={() => {
          if (isFirstStep || step === 1) {
            closeWizardAction();
            clearWizardStateAction();
            clearPlacementTestSessionCreateStateAction();
          } else {
            closeWizardWithWarningPopup();
          }
        }}
        type={buttonTypes.CLOSE_BORDER_GRAY}
        customClassName={wizardStyles.closeButton}
        dataTestId="PLACEMENT_TEST_ONBOARDING_WIZARD_CLOSE_BUTTON"
      />
    </div>
  );

  const _renderSummaryHeading = () => (
    <div className={wizardStyles.topContainer}>
      <Button
        text="Close"
        onClick={() => {
          closeWizardAction();
          clearWizardStateAction();
          clearPlacementTestSessionCreateStateAction();
        }}
        type={buttonTypes.CLOSE_BORDER_GRAY}
        customClassName={wizardStyles.flexRight}
      />
    </div>
  );

  return !isLastStep ? (
    <div className={wizardStyles.header}>{_renderStepHeading()}</div>
  ) : (
    <div className={wizardStyles.header}>{_renderSummaryHeading()}</div>
  );
}

Header.propTypes = {
  closeWizardAction: PropTypes.func.isRequired,
  clearWizardStateAction: PropTypes.func.isRequired,
  clearPlacementTestSessionCreateStateAction: PropTypes.func.isRequired,
  closeWizardWithWarningPopup: PropTypes.func.isRequired,
  localizedContent: PropTypes.object.isRequired
};

export default compose(
  withLocalizedContent('hubGlossary', 'placementTests'),
  connect(
    ({ placementTestSessionCreate }) => ({
      placementTestSessionNameValue: placementTestSessionCreate.placementTestSessionNameValue
    }),
    {
      clearWizardStateAction: clearWizardState,
      clearPlacementTestSessionCreateStateAction: closeForm,
      closeWizardAction: closePlacementTestOnboardingWizard
    }
  )
)(Header);
