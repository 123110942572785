import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from '../utils/compose/compose.js';
// Redux
import { clearForm } from '../redux/reducers/addStudentsToClassroom';
import {
  resetStatusOptionsOnlineTest,
  resetOnlineTestPanel,
  resetOnlineClass,
  setOnlineTestId,
  setStudentsFormReseted,
  setOnlineTest,
  switchToPage as switchToPageAction
} from '../redux/actions/onlineTestActions';
import { overwriteSelection } from '../redux/reducers/selection';
import { toggleAssessmentLicenceTab } from '../redux/actions/hubUi';
// Constants
import { HubLayoutConstants, submitActions, ONLINE_TEST_PANEL_CONTEXT } from '../globals/hubConstants';
import USER_ROLES from '../globals/userRoles';
// Services
import withFormHandlers from '../decorators/withFormHandlers';
import withLocalizedContent from '../language/withLocalizedContent';
import {
  getTestTitle,
  getTestPrintableResourceId,
  getTestDigitaleResourceId
} from '../structure/HubCourseAssessments/Services/getTestData';
// Components
import {
  SidePanel,
  OnlineTestClass,
  OnlineTestAssessments,
  OnlineTestStudents,
  OnlineTestTime,
  OnlineTestCommPreference,
  OnlineTestEditSettings,
  OnlineTestReviewSettings,
  OnlineTestConfirmationPanel,
  OnlineTestCancelConfirmation,
  OnlineTestCancelFailure,
  OnlineTestCancelSuccess,
  OnlineTestEditFailure
} from '../components';
import AssessmentLicenceStatusPanel from './AssessmentLicenceStatusPanel';
import { isLtiMode } from '../utils/platform';

class OnlineTestPanel extends Component {
  componentDidUpdate(prevProps) {
    // classId is being taken from the onlineTest redux, while classroomId is being taken from the params.
    const { testId, setOnlineTestIdAction, classId, classroomId } = this.props;
    if (testId && !prevProps.testId) {
      setOnlineTestIdAction(testId);
    }
    if (prevProps.testId && testId && prevProps.testId !== testId) {
      this._resetPanelOnTestChange();
    } else if (
      prevProps?.classId &&
      ((classId && prevProps.classId !== classId) || (classroomId && prevProps.classId !== classroomId))
    ) {
      this._resetPanelOnClassChange();
    }
  }

  _resetPanelOnClassChange = () => {
    const {
      resetAddStudentsForm,
      resetPanelFromClass,
      setStudentsFormResetedAction,
      overwriteSelectionAction,
      classId,
      classroomId
    } = this.props;
    resetAddStudentsForm(); // this resets the addStudentsToClassroom form
    setStudentsFormResetedAction();
    overwriteSelectionAction([]);
    resetPanelFromClass({ classId: classroomId || classId }); // this resets the onlineTest panel
  };

  _resetPanelOnTestChange = () => {
    const {
      resetAddStudentsForm,
      resetPanel,
      setStudentsFormResetedAction,
      overwriteSelectionAction,
      setOnlineTestIdAction,
      testId
    } = this.props;
    resetAddStudentsForm(); // this resets the addStudentsToClassroom form
    setStudentsFormResetedAction();
    overwriteSelectionAction([]);
    resetPanel(); // this resets the onlineTest panel
    setOnlineTestIdAction(testId);
  };

  _closePanel = async () => {
    const { closePanel, resetAddStudentsForm, setStudentsFormResetedAction, resetPanel, context } = this.props;
    resetAddStudentsForm(); // this resets the addStudentsToClassroom form
    setStudentsFormResetedAction();
    closePanel();
    if (context === ONLINE_TEST_PANEL_CONTEXT.CLASS_CONTEXT) {
      resetPanel();
    }
  };

  _getOnlineTestBody = (action, id, takeDataFromTest = false) => {
    const {
      startDate,
      orgId,
      endDate,
      classId,
      selectedStudentIds,
      sendEmailToStudents,
      onlineTestId,
      authorId,
      test,
      productAssessmentTitle,
      testDuration,
      setDurationTest,
      onlineTest
    } = this.props;

    let body;
    const testTitle = getTestTitle(test);
    const printableResourceId = getTestPrintableResourceId(test);
    const digitalResourceId = getTestDigitaleResourceId(test);
    const isAdaptedTest = !!test.parent_resource_id;

    if (takeDataFromTest) {
      body = {
        classId: test.classId,
        selectedStudentIds: test.selectedStudentIds,
        sendEmailToStudents: isLtiMode() ? false : onlineTest.sendEmailToStudents,
        testId: test.testId,
        authorId: test.authorId,
        action,
        orgId: test.orgId,
        testTitle,
        productAssessmentTitle: test.assessmentTitle,
        printableResourceId,
        digitalResourceId,
        isAdaptedTest,
        ...(setDurationTest ? { testDuration } : { testDuration: -1 }),
        ...(id && { id })
      };

      if (action === submitActions.UPDATE) {
        body = {
          ...body,
          startDate,
          endDate
        };
      } else {
        body = {
          ...body,
          startDate: test.startDate,
          endDate: test.endDate
        };
      }
    } else {
      body = {
        startDate,
        endDate,
        classId,
        selectedStudentIds,
        sendEmailToStudents,
        testId: onlineTestId,
        authorId,
        action,
        orgId,
        testTitle,
        productAssessmentTitle,
        printableResourceId,
        digitalResourceId,
        isAdaptedTest,
        ...(setDurationTest ? { testDuration } : { testDuration: -1 }),
        ...(id && { id })
      };
    }
    return body;
  };

  _renderContent = () => {
    const {
      currentPage,
      switchToPage,
      test,
      resetPanel,
      closePanel,
      productAssessmentTitle,
      classId,
      isOpen,
      setOnlineTestAction,
      context
    } = this.props;
    const steps = HubLayoutConstants.ONLINE_TEST_STEPS;

    switch (currentPage) {
      case steps.CONFIRMATION_PAGE:
        return (
          <OnlineTestConfirmationPanel
            test={test}
            assessmentTitle={productAssessmentTitle}
            closePanel={() => {
              closePanel();
              resetPanel();
            }}
            prev={() => {
              switchToPage({ page: steps.REVIEW_SETTINGS_PAGE });
            }}
          />
        );
      case steps.CANCEL_TEST_SUCCESS:
        return (
          <OnlineTestCancelSuccess
            test={test}
            assessmentTitle={productAssessmentTitle}
            next={() => {
              this._resetPanelOnTestChange();
              this._closePanel();
            }}
          />
        );
      case steps.CANCEL_TEST_FAILURE:
        return (
          <OnlineTestCancelFailure
            test={test}
            assessmentTitle={productAssessmentTitle}
            prev={() => {
              switchToPage({ page: steps.CANCEL_TEST_CONFIRMATION });
            }}
          />
        );
      case steps.CANCEL_TEST_CONFIRMATION:
        return (
          <OnlineTestCancelConfirmation
            test={test}
            assessmentTitle={productAssessmentTitle}
            next={() => switchToPage({ page: steps.CANCEL_TEST_SUCCESS })}
            prev={() => {
              switchToPage({ page: steps.EDIT_TEST_SETTINGS_PAGE });
            }}
          />
        );
      case steps.EDIT_TEST_SETTINGS_PAGE:
        return (
          <OnlineTestEditSettings
            test={test}
            assessmentTitle={productAssessmentTitle}
            next={() => {
              setOnlineTestAction(this._getOnlineTestBody(submitActions.SET, null));
              switchToPage({ page: steps.CONFIRMATION_PAGE });
            }}
            prev={() => {
              switchToPage({ page: steps.CANCEL_TEST_CONFIRMATION });
            }}
            context={context}
            resetPanel={resetPanel}
          />
        );
      case steps.REVIEW_SETTINGS_PAGE:
        return (
          <OnlineTestReviewSettings
            test={test}
            assessmentTitle={productAssessmentTitle}
            next={() => {
              setOnlineTestAction(this._getOnlineTestBody(submitActions.SET, null));
              switchToPage({ page: steps.CONFIRMATION_PAGE });
            }}
            prev={() => {
              switchToPage({ page: steps.EDIT_TEST_SETTINGS_PAGE });
            }}
          />
        );
      case steps.COMMUNICATION_PREFERENCE_PAGE:
        return (
          <OnlineTestCommPreference
            test={test}
            assessmentTitle={productAssessmentTitle}
            next={() => {
              switchToPage({ page: steps.REVIEW_SETTINGS_PAGE });
            }}
            prev={() => {
              switchToPage({ page: steps.CHOOSE_TIME_PAGE });
            }}
          />
        );
      case steps.CHOOSE_TIME_PAGE:
        return (
          <OnlineTestTime
            test={test}
            assessmentTitle={productAssessmentTitle}
            next={() => {
              switchToPage({ page: isLtiMode() ? steps.REVIEW_SETTINGS_PAGE : steps.COMMUNICATION_PREFERENCE_PAGE });
            }}
            prev={() => {
              switchToPage({ page: steps.CHOOSE_STUDENTS_PAGE });
            }}
          />
        );
      case steps.CHOOSE_STUDENTS_PAGE:
        return (
          <OnlineTestStudents
            isOpen={isOpen}
            test={test}
            assessmentTitle={productAssessmentTitle}
            next={() => {
              switchToPage({ page: steps.CHOOSE_TIME_PAGE });
            }}
            classId={classId}
            prev={() => {
              if (context === ONLINE_TEST_PANEL_CONTEXT.CLASS_CONTEXT) {
                switchToPage({ page: steps.CHOOSE_TEST_PAGE });
              } else {
                switchToPage({ page: steps.CHOOSE_CLASS_PAGE });
              }
            }}
          />
        );
      case steps.CHOOSE_TEST_PAGE:
        return <OnlineTestAssessments isOpen={isOpen} classId={classId} next={switchToPage} resetPanel={resetPanel} />;
      default:
        return (
          <OnlineTestClass
            test={test}
            assessmentTitle={productAssessmentTitle}
            next={switchToPage}
            prev={() => this._closePanel()}
          />
        );
    }
  };

  _renderEditTestPanelFlow = () => {
    const {
      currentPage,
      switchToPage,
      test,
      productAssessmentTitle,
      setOnlineTestAction,
      resetPanel,
      closePanel,
      resetStatusOptions,
      localizedContent: { hubSetTestOnline: content }
    } = this.props;
    const steps = HubLayoutConstants.ONLINE_TEST_STEPS;

    switch (currentPage) {
      case steps.CANCEL_TEST_SUCCESS:
        return (
          <OnlineTestCancelSuccess
            test={test}
            isCancelPanelFlow
            assessmentTitle={test.assessmentTitle}
            next={() => {
              this._resetPanelOnTestChange();
              this._closePanel();
            }}
          />
        );
      case steps.CANCEL_TEST_FAILURE:
        return (
          <OnlineTestCancelFailure
            test={test}
            isCancelPanelFlow
            assessmentTitle={test.assessmentTitle}
            prev={() => {
              resetStatusOptions();
              switchToPage({ page: steps.CANCEL_TEST_CONFIRMATION });
            }}
          />
        );
      case steps.CANCEL_TEST_CONFIRMATION:
        return (
          <OnlineTestCancelConfirmation
            isCancelPanelFlow
            test={test}
            assessmentTitle={test.assessmentTitle}
            next={() => {
              setOnlineTestAction(this._getOnlineTestBody(submitActions.CANCEL, test.id, true));
              this._closePanel();
              resetPanel();
            }}
            switchToPage={switchToPage}
            prev={() => {
              switchToPage({ page: steps.EDIT_TEST_SETTINGS_PAGE });
            }}
          />
        );
      case steps.CONFIRMATION_PAGE:
        return (
          <OnlineTestConfirmationPanel
            test={test}
            isUpdateTestFlow
            closePanel={() => {
              closePanel();
              resetPanel();
            }}
            assessmentTitle={test.assessmentTitle}
          />
        );
      case steps.EDIT_TEST_SETTINGS_PAGE:
        return (
          <OnlineTestEditSettings
            isUpdateTestFlow
            test={test}
            assessmentTitle={productAssessmentTitle}
            next={() => {
              setOnlineTestAction(this._getOnlineTestBody(submitActions.UPDATE, test.id, true));
              switchToPage({ page: steps.CONFIRMATION_PAGE });
            }}
            switchToPage={switchToPage}
            prev={() => {
              switchToPage({ page: steps.CANCEL_TEST_CONFIRMATION });
            }}
          />
        );
      case steps.EDIT_TEST_FAILURE:
        return (
          <OnlineTestEditFailure
            assessmentTitle={test.assessmentTitle}
            content={content}
            prev={() => {
              switchToPage({ page: steps.EDIT_TEST_SETTINGS_PAGE });
            }}
          />
        );
      default:
        return <div />;
    }
  };

  _renderCancelTestPanelFlow = () => {
    const { currentPage, switchToPage, test, setOnlineTestAction, resetStatusOptions, resetPanel } = this.props;
    const steps = HubLayoutConstants.ONLINE_TEST_STEPS;

    switch (currentPage) {
      case steps.CANCEL_TEST_SUCCESS:
        return (
          <OnlineTestCancelSuccess
            test={test}
            isCancelPanelFlow
            assessmentTitle={test.assessmentTitle}
            next={() => {
              this._resetPanelOnTestChange();
              this._closePanel();
            }}
          />
        );
      case steps.CANCEL_TEST_FAILURE:
        return (
          <OnlineTestCancelFailure
            test={test}
            isCancelPanelFlow
            assessmentTitle={test.assessmentTitle}
            prev={() => {
              resetStatusOptions();
              switchToPage({ page: steps.CANCEL_TEST_CONFIRMATION });
            }}
          />
        );
      case steps.CANCEL_TEST_CONFIRMATION:
        return (
          <OnlineTestCancelConfirmation
            isCancelPanelFlow
            test={test}
            assessmentTitle={test.assessmentTitle}
            switchToPage={switchToPage}
            next={() => {
              setOnlineTestAction(this._getOnlineTestBody(submitActions.CANCEL, test.id, true));
              this._closePanel();
              resetPanel();
            }}
          />
        );
      default:
        return <div />;
    }
  };

  render() {
    const {
      isOpen,
      localizedContent: { hubSetTestOnline: content },
      statusStudentsAssessmenLicenceTab,
      toggleAssessmentLicenceCodeTab,
      test,
      startDate,
      endDate,
      orgId,
      selectedStudentIds,
      panelFlow,
      closePanel,
      resetPanel
    } = this.props;

    if (test) {
      test.startDate = startDate;
      test.endDate = endDate;
    }

    return (
      <>
        <SidePanel id="OnlineTest" isOpen={isOpen} onClose={this._closePanel} ariaLabel={content.online_test_title}>
          {this._renderContent()}
        </SidePanel>
        <SidePanel isOpen={statusStudentsAssessmenLicenceTab} onClose={() => toggleAssessmentLicenceCodeTab()}>
          <AssessmentLicenceStatusPanel
            orgId={orgId}
            backAction={toggleAssessmentLicenceCodeTab}
            initialRoles={[USER_ROLES.LEARNER, USER_ROLES.MANAGED_USER]}
            testData={test}
            onlineTestPanel
            selectedStudentIds={selectedStudentIds}
          />
        </SidePanel>
        <SidePanel
          isOpen={panelFlow?.isEditTestFlow}
          onClose={() => {
            resetPanel();
            closePanel();
          }}
          ariaLabel={content.online_test_title}
        >
          {this._renderEditTestPanelFlow()}
        </SidePanel>
        <SidePanel
          isOpen={panelFlow?.isCancelTestFlow}
          onClose={() => {
            resetPanel();
            closePanel();
          }}
          ariaLabel={content.online_test_title}
        >
          {this._renderCancelTestPanelFlow()}
        </SidePanel>
      </>
    );
  }
}

export default compose(
  withLocalizedContent('hubSetTestOnline'),
  connect(
    ({ onlineTest, identity, hubUi }) => ({
      currentPage: onlineTest.currentPage,
      test: onlineTest.test,
      productAssessmentTitle: onlineTest.productAssessmentTitle,
      classId: onlineTest.classId,
      startDate: onlineTest.startDate,
      endDate: onlineTest.endDate,
      onlineTestId: onlineTest.testId,
      selectedStudentIds: onlineTest.selectedStudentIds,
      sendEmailToStudents: onlineTest.sendEmailToStudents,
      orgId: identity.currentOrganisationId,
      authorId: identity.userId,
      currentOrganisationId: identity.currentOrganisationId,
      statusStudentsAssessmenLicenceTab: hubUi.statusStudentsAssessmenLicenceTab,
      testDuration: onlineTest.durationInMinutes,
      setDurationTest: onlineTest.durationTestSet,
      onlineTest
    }),
    {
      switchToPage: switchToPageAction,
      resetAddStudentsForm: clearForm,
      resetPanelFromClass: resetOnlineClass,
      resetPanel: resetOnlineTestPanel,
      resetStatusOptions: resetStatusOptionsOnlineTest,
      setOnlineTestIdAction: setOnlineTestId,
      setStudentsFormResetedAction: setStudentsFormReseted,
      overwriteSelectionAction: overwriteSelection,
      setOnlineTestAction: setOnlineTest,
      toggleAssessmentLicenceCodeTab: toggleAssessmentLicenceTab
    }
  ),
  withFormHandlers()
)(OnlineTestPanel);

OnlineTestPanel.propTypes = {
  localizedContent: PropTypes.object,
  isOpen: PropTypes.bool,
  closePanel: PropTypes.func,
  currentPage: PropTypes.string,
  switchToPage: PropTypes.func,
  test: PropTypes.object,
  testId: PropTypes.string,
  productAssessmentTitle: PropTypes.string,
  classId: PropTypes.string,
  resetAddStudentsForm: PropTypes.func,
  resetPanelFromClass: PropTypes.func,
  resetPanel: PropTypes.func,
  setOnlineTestIdAction: PropTypes.func,
  setStudentsFormResetedAction: PropTypes.func,
  overwriteSelectionAction: PropTypes.func,
  setOnlineTestAction: PropTypes.func,
  orgId: PropTypes.string,
  selectedStudentIds: PropTypes.array,
  authorId: PropTypes.string,
  sendEmailToStudents: PropTypes.bool,
  startDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  endDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  statusStudentsAssessmenLicenceTab: PropTypes.bool,
  toggleAssessmentLicenceCodeTab: PropTypes.func,
  context: PropTypes.string,
  panelFlow: PropTypes.object,
  onlineTestId: PropTypes.string,
  resetStatusOptions: PropTypes.func,
  testDuration: PropTypes.number,
  setDurationTest: PropTypes.bool,
  classroomId: PropTypes.string,
  onlineTest: PropTypes.object
};
