import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { useWizard, WizardHero } from '@oup/shared-front-end';

import compose from '../../../utils/compose/compose.js';
import withLocalizedContent from '../../../language/withLocalizedContent';
import { showSelfSelectRoleModal } from '../../../redux/actions/hubUi';

function OnboardingWizardSuccess({
  localizedContent: { selfRegisteredUserOnboardingWizard: selfRegisteredUserOnboardingWizardContent },
  showSelfSelectRoleModalAction,
  setIsOpenCreateFirstPlacementTestOrClassModal,
  isOpenCreateFirstPlacementTestOrClassModal
}) {
  const { stepHandler } = useWizard();

  useEffect(() => {
    if (!isOpenCreateFirstPlacementTestOrClassModal) {
      stepHandler(() => {
        setIsOpenCreateFirstPlacementTestOrClassModal(true);
        showSelfSelectRoleModalAction(false);
      });
    }
  }, [isOpenCreateFirstPlacementTestOrClassModal]);

  return (
    <div data-testid="SELF_REGISTERED_USER_ONBOARDING_WIZARD_SUCCESS_CONTAINER">
      <WizardHero
        heading={{
          text: selfRegisteredUserOnboardingWizardContent.self_registered_user_success_step_title
        }}
        subHeading={selfRegisteredUserOnboardingWizardContent.self_registered_user_success_step_subtitle}
        image={{
          src: '/media/feedback/illustration-success.svg',
          alt: 'Onboarding successful'
        }}
      />
    </div>
  );
}

OnboardingWizardSuccess.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  showSelfSelectRoleModalAction: PropTypes.func.isRequired,
  setIsOpenCreateFirstPlacementTestOrClassModal: PropTypes.func.isRequired,
  isOpenCreateFirstPlacementTestOrClassModal: PropTypes.bool
};

export default compose(
  connect(null, { showSelfSelectRoleModalAction: showSelfSelectRoleModal }),
  withLocalizedContent('selfRegisteredUserOnboardingWizard')
)(OnboardingWizardSuccess);
