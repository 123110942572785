import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// Components
import { Modal, ModalBody, ModalFooter, Button } from '@oup/shared-front-end';
import { ICON_CLOSE } from '@oup/shared-front-end/svg/oup';

import PropTypes from 'prop-types';
import compose from '../../../utils/compose/compose.js';
import styles from './UserAccessPlacementTestModal.scss';
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner';
import {
  closeUserAccessPlacementTestModal,
  loadUserAccessPlacementTestRequest
} from '../../../redux/actions/placementTests';
import Illustration from '../../../components/Illustration/Illustration';
import { HubIllustrationConstants, HubIllustrationAltText } from '../../../globals/hubConstants';
import withLocalizedContent from '../../../language/withLocalizedContent';

function UserAccessPlacementTestModal({ localizedContent: { placementTests: placementTestsContent } }) {
  const isLoading =
    useSelector(state => state.loadPlacementTestsReducer.userAccessPlacementTestSession.loading) ?? false;
  const isError = useSelector(state => state.loadPlacementTestsReducer.userAccessPlacementTestSession.error) ?? false;

  const errorRetryInfo =
    useSelector(state => state.loadPlacementTestsReducer.userAccessPlacementTestSession.retryInfo) ?? {};

  const isOpened = isLoading || isError;

  const dispatch = useDispatch();
  const currentModalCloseHandler = useCallback(() => {
    dispatch(closeUserAccessPlacementTestModal());
  }, [dispatch]);

  const currentModalRetryHandler = useCallback(() => {
    dispatch(loadUserAccessPlacementTestRequest(errorRetryInfo));
  }, [dispatch, errorRetryInfo]);

  return (
    <Modal
      id="placement-test-access-modal"
      ariaAttributes={{
        labelledby: 'placement-test-access-modal-heading'
      }}
      open={isOpened}
      variant="default"
      size="small"
      closeHandler={() => {
        currentModalCloseHandler();
      }}
    >
      <div className={styles.container}>
        <div className={styles.top}>
          <Button
            variant="transparent"
            size="small"
            icon={{ placement: 'right', component: <ICON_CLOSE aria-hidden="true" /> }}
            onClick={() => currentModalCloseHandler()}
          />
        </div>
        <ModalBody>
          {isLoading && (
            <LoadingSpinner text="">
              <div>
                <h2 id="placement-test-access-modal-heading">
                  {placementTestsContent.user_access_test_session_modal_launching_title}
                </h2>
                <p>{placementTestsContent.user_access_test_session_modal_launching_subtitle}</p>
              </div>
            </LoadingSpinner>
          )}
          {isError && (
            <div className={styles.errorBox}>
              <Illustration
                illustrationSrc={HubIllustrationConstants.ERROR}
                illustrationAltText={HubIllustrationAltText.ERROR}
              />
              <h2>{placementTestsContent.user_access_test_session_modal_error_title}</h2>
              <p>{placementTestsContent.user_access_test_session_modal_error_subtitle}</p>
            </div>
          )}
        </ModalBody>
        {isError && (
          <ModalFooter
            sticky
            leftButton={
              <Button onClick={() => currentModalCloseHandler()} variant="outline" size="small" text="Cancel" />
            }
            rightButton={
              <Button
                onClick={() => currentModalRetryHandler()}
                variant="filled"
                size="small"
                text={placementTestsContent.user_access_test_session_modal_try_again}
              />
            }
          />
        )}
      </div>
    </Modal>
  );
}

UserAccessPlacementTestModal.propTypes = {
  localizedContent: PropTypes.object
};

export default compose(withLocalizedContent('placementTests'))(UserAccessPlacementTestModal);
