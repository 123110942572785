import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';

import compose from '../../utils/compose/compose.js';
import withLocalizedContent from '../../language/withLocalizedContent';
import RegisterWizard from '../../components/RegisterWizard/RegisterWizard.js';
import ChooseRoleWizard from '../../components/ChooseRoleWizard/ChooseRoleWizard';

import styles from './RegisterPageWizard.scss';

function RegisterPageWizard({ userId, localizedContent: { registrationPage: content } }) {
  const navigate = useNavigate();
  const location = useLocation();

  const [isChooseRoleWizardOpen, setIsChooseRoleWizardOpen] = useState(true);
  const [isRegisterWizardOpen, setIsRegisterWizardOpen] = useState(false);

  useEffect(() => {
    if (location.pathname.includes('young-learner')) {
      setIsChooseRoleWizardOpen(false);
      setIsRegisterWizardOpen(true);
    }
  }, []);

  if (userId) {
    navigate('/');
  }

  const onStandardRoleChosen = () => {
    setIsChooseRoleWizardOpen(false);
    setIsRegisterWizardOpen(true);
  };

  const onYoungLearnerChosen = () => {
    setIsChooseRoleWizardOpen(false);
    navigate('/young-learner/register');
  };

  return (
    <div className={styles.register}>
      <Helmet title={content?.pageTitle} titleTemplate="%s | Oxford University Press" />
      <ChooseRoleWizard
        isOpen={isChooseRoleWizardOpen}
        onRoleChosen={role => {
          if (role === 'standard') {
            onStandardRoleChosen();
          } else if (role === 'youngLearner') {
            onYoungLearnerChosen();
          }
        }}
      />
      <RegisterWizard isOpen={isRegisterWizardOpen} />
    </div>
  );
}

RegisterPageWizard.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  userId: PropTypes.string
};

export default compose(
  withLocalizedContent('registrationPage'),
  connect(state => ({
    userId: state.identity.userId
  }))
)(RegisterPageWizard);
