/* eslint-disable class-methods-use-this */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';

import compose from '../../utils/compose/compose.js';
import withLocalizedContent from '../../language/withLocalizedContent';
import withRouter from '../../utils/withRouter';
// Style
import styles from './HubLayout.scss';
import SimpleHeaderStyles from '../StatePages/SimpleHeader/SimpleHeader.scss';
// Redux
import { formStates } from '../../redux/reducers/registration/registration.reducer';
import { formStates as invitationFormstates } from '../../redux/reducers/invitationsBanner.reducer';
import { showSelfSelectRoleModal } from '../../redux/actions/hubUi';
// Utils
import { setFavicons } from '../../globals/favicons';
import gtmPageview from '../../utils/gtm/pageview.js';
import { isIos } from '../../utils/device';
import { HubLayoutConstants } from '../../globals/hubConstants.js';
import withBreakpoint from '../../decorators/withBreakpoint';
import localStorageWithCache from '../../utils/localStorageWithCache';
import userRoles from '../../globals/userRoles';
// Components
import ErrorPage from '../StatePages/ErrorPage/ErrorPage.js';
import HubHeadersAndBanners from '../HubHeader/HubHeadersAndBanners';
import LoadingPage from '../StatePages/LoadingPage/LoadingPage.js';
import HubFooter from '../HubFooter/HubFooter.js';
import ConnectedBannerInvitation from '../../components/BannerInvitation/ConnectedBannerInvitation';
import SVGIcon, { GLYPHS } from '../../components/SVGIcon/SVGIcon.js';
// Features
import { socialIdpIsEnabled } from '../../globals/envSettings';
import evaluateLayoutProps from './Services/evaluateLayoutProps';
import SitewideProductPopup from '../SitewideProductPopup/SitewideProductPopup';
import { getCurrentPlatform, epsPlatformOlbOffline, epsPlatformEltHub } from '../../utils/platform';
import IncompleteAccount from '../../routes/IncompleteAccount/IncompleteAccount';
import MyProfileInitialEditPanel from '../../panels/MyProfileInitialEditPanel';
import { isPublicPath } from '../../globals/authSettings';
import ReCAPTCHA from '../../components/ReCAPTCHA/ReCAPTCHA.js';

// This structural component wraps the current react-router component
// in a page layout containing the SiteHeader
class HubLayout extends Component {
  constructor(props) {
    super(props);
    this.pageTitleRef = {};

    const {
      props: {
        location: { pathname, search },
        registrationComplete,
        userSignedIn
      }
    } = this;

    // Initialize layout props
    const { screenTitle, headerProps, footerProps } = evaluateLayoutProps({
      pathname,
      search,
      registrationComplete,
      userSignedIn,
      termsAndConditionsAccepted: this.isTermsAcceptedForPlatform(getCurrentPlatform())
    });
    this.state = { screenTitle, headerProps, footerProps, scrollProgress: 0 };
  }

  componentDidMount() {
    const {
      props: { setShowSelfSelectRoleModal, userId, role }
    } = this;

    /** Set Hub specific theme attribute && local storage key */
    const { THEME_NAME, TOP_LEVEL_PLATFORM, PLATFORM_NAME } = HubLayoutConstants;

    document.body.setAttribute('data-theme', THEME_NAME);
    localStorage.setItem(TOP_LEVEL_PLATFORM, PLATFORM_NAME);
    // localStorage.setItem('hub-mock-response', 'test-multiple-course');
    // localStorage.setItem('hub-mock-products', 'all');
    window.addEventListener('scroll', this.listenToScroll);

    const { getKey } = localStorageWithCache;
    const userIdFromLs = get(JSON.parse(getKey('selectedRoleData')), ['userId'], '');
    const isVisibleSelfSelectedRoleModal = role === userRoles.USER && userId !== userIdFromLs;
    if (isVisibleSelfSelectedRoleModal) setShowSelfSelectRoleModal(isVisibleSelfSelectedRoleModal);
  }

  componentDidUpdate(prevProps) {
    const {
      props: {
        breakpoint,
        location: { pathname, search },
        registrationComplete,
        userSignedIn,
        selfSelectRoleModalOpen
      }
    } = this;

    if (prevProps.location.pathname !== pathname || prevProps.registrationComplete !== registrationComplete) {
      this.updateLayout(pathname, search, registrationComplete, null, userSignedIn, selfSelectRoleModalOpen);
    }

    // Detect breakpoint change and update layout
    if (prevProps.breakpoint !== breakpoint) {
      this.updateLayout(pathname, search, registrationComplete, null, userSignedIn, selfSelectRoleModalOpen);
    }

    if (prevProps.location.pathname !== pathname && this.pageTitleRef) {
      this.pageTitleRef.focus();
    }

    if (prevProps.hubTermsAndConditionsAccepted !== this.isTermsAcceptedForPlatform(getCurrentPlatform())) {
      this.updateLayout(pathname, search, registrationComplete, null, userSignedIn, selfSelectRoleModalOpen);
    }

    if (prevProps.userSignedIn !== userSignedIn) {
      this.updateLayout(pathname, search, registrationComplete, null, userSignedIn, selfSelectRoleModalOpen);
    }

    if (prevProps.selfSelectRoleModalOpen !== selfSelectRoleModalOpen) {
      this.updateLayout(pathname, search, registrationComplete, null, userSignedIn, selfSelectRoleModalOpen);
    }
  }

  getHeaderRef = el => {
    this.pageTitleRef = el;
  };

  isTermsAcceptedForPlatform = platform => {
    const { olbTermsAndConditionsAccepted, cesTermsAndConditionsAccepted, hubTermsAndConditionsAccepted } = this.props;
    switch (platform) {
      case epsPlatformEltHub:
        return hubTermsAndConditionsAccepted;
      case epsPlatformOlbOffline:
        return olbTermsAndConditionsAccepted;
      default:
        return cesTermsAndConditionsAccepted;
    }
  };

  isSocialEnabledAndPathNotPublic = () =>
    socialIdpIsEnabled(getCurrentPlatform()) && !isPublicPath(window.location.pathname);

  updateLayout = (
    pathname,
    search,
    registrationComplete,
    hubContent,
    userSignedIn,
    isVisibleSelfSelectedRoleModal = false
  ) => {
    const { screenTitle, headerProps, footerProps } = evaluateLayoutProps({
      pathname,
      search,
      registrationComplete,
      userSignedIn,
      termsAndConditionsAccepted: this.isTermsAcceptedForPlatform(getCurrentPlatform()),
      isVisibleSelfSelectedRoleModal
    });
    this.setState({ screenTitle, headerProps, footerProps });
  };

  listenToScroll = () => {
    const winScroll = document.body.scrollTop || document.documentElement.scrollTop;
    let containerHeight = document.documentElement.scrollHeight - document.documentElement.clientHeight;
    if (isIos()) {
      const bottomFooterHeight = document.getElementById('legalStrip').clientHeight;
      containerHeight -= bottomFooterHeight;
    }
    const scrollProgress = containerHeight !== 0 ? winScroll / containerHeight : 0;
    this.setState({ scrollProgress });
  };

  isVisibleInvitationBanner = () =>
    this.hasPendingInvites() &&
    (!this.isSocialEnabledAndPathNotPublic() || this.isTermsAcceptedForPlatform(getCurrentPlatform()));

  hasPendingInvites = () => {
    const { invites } = this.props;
    return !!invites.length;
  };

  showInitialConsentCheck = () => {
    const { isSocialAccount } = this.props;

    return (
      this.isSocialEnabledAndPathNotPublic() &&
      isSocialAccount &&
      !this.isTermsAcceptedForPlatform(getCurrentPlatform())
    );
  };

  render() {
    const {
      props: {
        appError,
        appErrorReference,
        appReady,
        children,
        localizedContent: { hubGlossary: hubContent, hubFooterGlossary },
        breakpoint,
        favIcons,
        invites,
        invitationFormstate,
        userId,
        isSocialAccount,
        orgId,
        role,
        marketingPrefAccepted
      },
      state: {
        screenTitle,
        headerProps,
        footerProps: { hideFooter, hideUpperFooter, darkLayout },
        scrollProgress
      }
    } = this;

    let showRecaptchaFooter = false;

    if (window.location.pathname === '/') showRecaptchaFooter = true;

    const gtmParams = {
      orgId,
      userRole: role,
      userId
    };

    const isLoading = invitationFormstate === invitationFormstates.FETCHING && !invites.length && userId;
    const scrollReachBottom = scrollProgress >= 1;
    const customLogoComponent = (
      <div className={SimpleHeaderStyles.hubLogo}>
        <SVGIcon glyph={GLYPHS.OUP_LOGO_BLUE} />
      </div>
    );

    if (appError) {
      return <ErrorPage appErrorReference={appErrorReference} customLogoComponent={customLogoComponent} />;
    }
    if (!appReady || isLoading) {
      return <LoadingPage customLogoComponent={customLogoComponent} />;
    }
    const isImpersonationMode = !!localStorage.getItem('impersonation-token');
    const hasPendingInvites = this.hasPendingInvites() && !isImpersonationMode;

    const displayIncompleteAccount =
      !isSocialAccount &&
      !isPublicPath(window.location.pathname) &&
      (!marketingPrefAccepted || !this.isTermsAcceptedForPlatform(getCurrentPlatform()));

    const headerPropsExtended = {
      registeredAndRestyled: {
        ...headerProps,
        hasPendingInvites,
        breakpoint
      },
      unRegisteredAndNotRestyled: {
        ...headerProps,
        hasPendingInvites,
        hideRegisterLink: true,
        hideSignInLink: true,
        breakpoint
      }
    };

    const hideWidgetDropDown = window.location.pathname === '/logged-out';

    return (
      <ReCAPTCHA>
        <div className={styles.hubApp}>
          <Helmet htmlAttributes={{ lang: 'en' }} link={favIcons} onChangeClientState={() => gtmPageview(gtmParams)} />
          <div>
            {/* Feature flag the register and sign in buttons */}
            <HubHeadersAndBanners
              {...headerPropsExtended.registeredAndRestyled}
              hideWidgetDropDown={hideWidgetDropDown}
            />

            <div className={styles.pageContainer}>
              <main id="maincontent">
                {/* Visually hidden h1, visible for screen readers only */}
                <div className={styles.srOnly}>
                  <h1 tabIndex={-1} id="main-heading" ref={el => this.getHeaderRef(el)}>
                    {hubContent[screenTitle]}
                  </h1>
                </div>

                {this.showInitialConsentCheck() ? (
                  <MyProfileInitialEditPanel isOpen platform={getCurrentPlatform()} />
                ) : null}

                {/* Display hub specific T&C popup when user register on other platform and first login on Hub */}
                {displayIncompleteAccount && (
                  <IncompleteAccount
                    platform={getCurrentPlatform()}
                    isTandCaccepted={this.isTermsAcceptedForPlatform(getCurrentPlatform())}
                  />
                )}

                {/* Display page content  */}
                {!displayIncompleteAccount && !hasPendingInvites && children}

                <SitewideProductPopup content={hubContent} />
              </main>
              {!hideFooter && !hasPendingInvites && this.isTermsAcceptedForPlatform(getCurrentPlatform()) && (
                <HubFooter
                  hubFooterContent={hubFooterGlossary}
                  hideUpperFooter={hideUpperFooter}
                  breakpoint={breakpoint}
                  scrollReachBottom={scrollReachBottom}
                  darkLayout={darkLayout}
                  showRecaptchaFooter={showRecaptchaFooter}
                />
              )}
              {this.isVisibleInvitationBanner() && !isImpersonationMode && <ConnectedBannerInvitation />}
            </div>
          </div>
        </div>
      </ReCAPTCHA>
    );
  }
}
HubLayout.propTypes = {
  children: PropTypes.any,
  location: PropTypes.object.isRequired,
  appError: PropTypes.bool.isRequired,
  appErrorReference: PropTypes.string,
  appReady: PropTypes.bool.isRequired,
  partialRegistrationInProgress: PropTypes.bool.isRequired,
  requireTCAcceptance: PropTypes.bool,
  registrationComplete: PropTypes.bool.isRequired,
  userSignedIn: PropTypes.bool.isRequired,
  favIcons: PropTypes.array,
  localizedContent: PropTypes.object.isRequired,
  breakpoint: PropTypes.string,
  invites: PropTypes.array,
  invitationFormstate: PropTypes.string,
  userId: PropTypes.string,
  role: PropTypes.string,
  isSocialAccount: PropTypes.bool,
  hubTermsAndConditionsAccepted: PropTypes.bool,
  olbTermsAndConditionsAccepted: PropTypes.bool,
  cesTermsAndConditionsAccepted: PropTypes.bool,
  marketingPrefAccepted: PropTypes.bool,
  selfSelectRoleModalOpen: PropTypes.bool,
  setShowSelfSelectRoleModal: PropTypes.func,
  orgId: PropTypes.string
};
export default compose(
  connect(
    ({
      app: { appError, appErrorReference, appReady },
      identity: { isSocial, missingFields, userId = '', role, currentOrganisationId },
      registration: { formState },
      invites: { invites = [], formState: invitationFormstate = '' } = {},
      hubUi: { selfSelectRoleModalOpen }
    }) => ({
      appError,
      appErrorReference,
      appReady,
      isSocialAccount: isSocial,
      partialRegistrationInProgress: missingFields.length > 0,
      hubTermsAndConditionsAccepted: !missingFields.includes('hubTsAndCsAccepted'),
      olbTermsAndConditionsAccepted: !missingFields.includes('olbTsAndCsAccepted'),
      cesTermsAndConditionsAccepted: !missingFields.includes('tsAndCsAccepted'),
      marketingPrefAccepted: !missingFields.includes('marketingPref'),
      registrationComplete: formState === formStates.COMPLETE,
      favIcons: setFavicons('hub'),
      invites,
      invitationFormstate,
      userId,
      role,
      orgId: currentOrganisationId,
      userSignedIn: !!role,
      selfSelectRoleModalOpen
    }),
    { setShowSelfSelectRoleModal: showSelfSelectRoleModal }
  ),
  withLocalizedContent('hubGlossary', 'hubFooterGlossary'),
  withBreakpoint
)(withRouter(HubLayout));
