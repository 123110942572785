import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Components
import { useWizard, Heading, TextInput, ValidationMessage } from '@oup/shared-front-end';
// Utils
import userRoles from '../../../globals/userRoles.js';
import { HubLayoutConstants } from '../../../globals/hubConstants';
import preventBodyScroll from '../../../utils/dom/preventBodyScroll.js';

// Redux
import { setActiveClassId } from '../../../redux/reducers/classroomPage.js';
import { storeClassrooms } from '../../../redux/reducers/data/classrooms.js';
import { initialiseInstance } from '../../../redux/reducers/data/search.reducer.js';
import { setClassroomName, classroomNameOnBlur } from '../../../redux/reducers/classroomCreate.js';
import { setClassNameRequest } from '../../../redux/actions/classOnboardingWizard';
// Styles
import styles from './CreateAClass.scss';

function CreateAClass({
  createClassRequestAction,
  classNameValue,
  classNameValidationPending,
  classNameValidationIsInvalid,
  classNameValidationIsValid,
  classNameValidationIsWarning,
  classNameValidationMessage,
  setClassNameAction,
  classNameOnBlurAction,
  classCreationError,
  classCreationLoading,
  classCreated,
  userId,
  content,
  orgId,
  classId = '',
  setClassPageIdAction,
  storeClassroomsAction,
  initialiseInstanceAction,
  isOpen
}) {
  const { setNextStepDisabled, stepHandler, nextStep } = useWizard();

  // Update the classroom related redux store in order to have all the necessary information for AddStudents panel in the next step
  const updateClassContext = () => {
    const createdClass = {
      [classId]: {
        teacherIdList: [userId],
        amountOfTeachers: 1,
        amountOfLearners: 0,
        studentIdList: [],
        archived: false,
        name: classNameValue,
        orgId
      }
    };

    storeClassroomsAction(createdClass);
    setClassPageIdAction(orgId, classId);

    initialiseInstanceAction('profileClasses', false, { active: true, orgId, userId });
    initialiseInstanceAction('classStudents', false, {
      orgId,
      classId,
      active: true,
      invited: true,
      roles: [userRoles.LEARNER]
    });
  };

  useEffect(() => {
    preventBodyScroll(isOpen);
  }, []);

  stepHandler(() => {
    if (classCreated) {
      return;
    }

    const orgContext =
      window.location.pathname ===
      HubLayoutConstants.PATH_NAMES.ORGANIZATION_PATH + HubLayoutConstants.PATH_NAMES.CLASSES;
    createClassRequestAction({ userId, orgId, classNameValue, orgContext });
    throw new Error();
  });

  useEffect(() => {
    if (classCreated && !classCreationError) {
      updateClassContext();
      nextStep();
    }
  }, [classCreated]);

  useEffect(() => {
    setNextStepDisabled(
      !classNameValue || classNameValidationPending || classNameValidationIsInvalid || classCreationLoading
    );
  }, [classNameValue, classNameValidationPending, classNameValidationIsInvalid, classCreationLoading]);

  const onChangeClassName = ({ target: { value } }) => {
    setClassNameAction(value);
  };

  const getInputState = () => {
    switch (true) {
      case classNameValidationPending:
        return 'loading';
      case classNameValidationIsInvalid:
        return 'invalid';
      case classNameValidationIsWarning:
        return 'warning';
      case classNameValidationIsValid:
        return 'valid';
      default:
        return 'default';
    }
  };

  return (
    <div data-testid="ONBOARDING_WIZARD_CREATE_A_CLASS_CONTAINER" className={styles.stepContainer}>
      <Heading className={styles.heading} text={content.create_class_title} size="medium" />
      {classCreationError && (
        <ValidationMessage state="error">
          <p>{content.error_creating_class}</p>
        </ValidationMessage>
      )}
      <div className={styles.textInputContainer}>
        <TextInput
          name="className"
          label={content.class_name}
          placeholder={content.create_a_class_placeholder}
          maxLength={255}
          value={classNameValue}
          onChange={e => onChangeClassName(e)}
          onBlur={classNameOnBlurAction}
          state={(() => getInputState())()}
          validationMessage={classNameValidationMessage}
          disabled={classCreationLoading}
        />
      </div>
    </div>
  );
}

CreateAClass.propTypes = {
  createClassRequestAction: PropTypes.func.isRequired,
  classNameValue: PropTypes.string,
  classNameValidationPending: PropTypes.bool,
  classNameValidationIsInvalid: PropTypes.bool,
  classNameValidationIsValid: PropTypes.bool,
  classNameValidationIsWarning: PropTypes.bool,
  classNameValidationMessage: PropTypes.string,
  classCreated: PropTypes.bool,
  classCreationError: PropTypes.bool,
  classCreationLoading: PropTypes.bool,
  userId: PropTypes.string,
  orgId: PropTypes.string,
  content: PropTypes.object.isRequired,
  classId: PropTypes.string,
  setClassPageIdAction: PropTypes.func,
  storeClassroomsAction: PropTypes.func,
  initialiseInstanceAction: PropTypes.func,
  isOpen: PropTypes.bool.isRequired,
  setClassNameAction: PropTypes.func,
  classNameOnBlurAction: PropTypes.func
};

export default connect(
  ({ classOnboardingWizard, classroomCreate, identity }) => ({
    classNameValue: classroomCreate.classroomNameValue,
    classNameValidationIsWarning: classroomCreate.classroomNameValidationIsWarning,
    classNameValidationPending: classroomCreate.classroomNameValidationPending,
    classNameValidationIsInvalid: classroomCreate.classroomNameValidationIsInvalid,
    classNameValidationIsValid: classroomCreate.classroomNameValidationIsValid,
    classNameValidationMessage: classroomCreate.classroomNameValidationMessage,
    classCreated: classOnboardingWizard.classCreated,
    classCreationError: classOnboardingWizard.classCreationError,
    classCreationLoading: classOnboardingWizard.classCreationLoading,
    classId: classOnboardingWizard.classId,
    userId: identity.userId,
    orgId: identity.currentOrganisationId,
    userRole: identity.role
  }),
  {
    setClassNameAction: setClassroomName,
    classNameOnBlurAction: classroomNameOnBlur,
    setClassPageIdAction: setActiveClassId,
    storeClassroomsAction: storeClassrooms,
    initialiseInstanceAction: initialiseInstance,
    createClassRequestAction: setClassNameRequest
  }
)(CreateAClass);
