import { pick, get } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import compose from '../../../utils/compose/compose.js';
import { SidePanel } from '../../../components';
import ClassUserRemoveReview from '../../../components/ClassUserRemoveReview/ClassUserRemoveReview';
import PopoutPanelIconHeading, { types } from '../../../components/PopoutPanelIconHeading/PopoutPanelIconHeading';
import withLocalizedContent from '../../../language/withLocalizedContent';
import {
  clearForm,
  formStates,
  setNotifyWithEmail,
  submitForm
} from '../../../redux/reducers/hub/hubRemoveUsersFromClassroom.reducer';
import ClassUserConfirmation from '../../../components/ClassUserConfirmation/ClassUserConfirmation';
import { featureIsEnabled } from '../../../globals/envSettings';
import { isOrbMode } from '../../../utils/platform';
import { orgRoles } from '../../../globals/orgRoles';
import { HubIllustrationConstants, HubIllustrationAltText } from '../../../globals/hubConstants.js';

class RemoveMultipleUsers extends React.Component {
  _getConfirmationHeading = () => {
    const {
      localizedContent: { hubGlossary: hubContent },
      requestFailed,
      failedIds,
      numberOfUsers,
      classroomName = ''
    } = this.props;
    if (requestFailed) {
      return {
        title: hubContent.remove_users_error_title,
        type: types.ERROR,
        illustrationSrc: HubIllustrationConstants.ERROR,
        illustrationAltText: HubIllustrationAltText.ERROR
      };
    }
    if (failedIds.length) {
      return {
        title: (hubContent.remove_users_partial_error_title || '').replace('{className}', classroomName),
        type: types.WARNING,
        illustrationSrc: HubIllustrationConstants.WARNING,
        illustrationAltText: HubIllustrationAltText.WARNING
      };
    }
    return {
      title: (hubContent.remove_users_success_title || '')
        .replace('{numberOfUsers}', numberOfUsers)
        .replace('{plural}', numberOfUsers === 1 ? '' : 's')
        .replace('{className}', classroomName),
      type: types.VALID,
      illustrationSrc: HubIllustrationConstants.SUCCESS,
      illustrationAltText: HubIllustrationAltText.SUCCESS
    };
  };

  reset = () => {
    const { resetForm } = this.props;
    setTimeout(resetForm, 500);
  };

  _handleNotifyToggle = () => {
    const { notifyByEmail, onNotifyByEmailToggle } = this.props;
    onNotifyByEmailToggle(!notifyByEmail);
  };

  _handleClosePanel = () => {
    const { formState, closePanel } = this.props;

    if (formState === formStates.CONFIRMATION) this.reset();
    closePanel();
  };

  _handleOnComplete = () => {
    const { onComplete } = this.props;

    this.reset();
    if (onComplete()) onComplete();
  };

  _renderContent = () => {
    const toggleRender = featureIsEnabled('class-toggle-remove') ? !isOrbMode() : true;
    const {
      formState,
      failedIds,
      users,
      localizedContent: { removeStudentsPanel: content, hubGlossary: hubContent },
      notifyByEmail,
      onConfirmClick,
      classroomName = '',
      curriculumType,
      isSecondarySchool
    } = this.props;

    switch (formState) {
      case formStates.REVIEW_SELECTIONS:
        return (
          <ClassUserRemoveReview
            users={users}
            toggleRender={toggleRender && !isSecondarySchool}
            localizedContent={hubContent}
            notifyByEmail={notifyByEmail}
            onNotifyByEmailToggle={this._handleNotifyToggle}
            onSubmitClick={onConfirmClick}
            classroomName={classroomName}
            curriculumType={curriculumType}
          />
        );
      case formStates.SUBMITTING:
        return (
          <div className="flex-column align-items-center">
            <PopoutPanelIconHeading
              title={content.loading_page_title_text}
              subtitle={hubContent.loading_page_subtitle_text}
              type={types.LOADING}
            />
          </div>
        );

      case formStates.CONFIRMATION:
        return (
          <ClassUserConfirmation
            {...this._getConfirmationHeading()}
            localizedContent={content}
            users={users}
            failedIds={failedIds}
            onDoneClick={this._handleOnComplete}
          />
        );

      default:
        return (
          <div>
            <p>Error. Missing a form for `{formState}`.</p>
          </div>
        );
    }
  };

  render() {
    const { isOpen } = this.props;

    return (
      <SidePanel isOpen={isOpen} onClose={this._handleClosePanel}>
        {this._renderContent()}
      </SidePanel>
    );
  }
}

RemoveMultipleUsers.propTypes = {
  formState: PropTypes.string.isRequired,
  localizedContent: PropTypes.object.isRequired,
  users: PropTypes.object.isRequired,
  numberOfUsers: PropTypes.number.isRequired,
  failedIds: PropTypes.array.isRequired,
  classroomName: PropTypes.string,
  curriculumType: PropTypes.string.isRequired,
  notifyByEmail: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
  requestFailed: PropTypes.bool.isRequired,
  resetForm: PropTypes.func.isRequired,
  closePanel: PropTypes.func.isRequired,
  onComplete: PropTypes.func.isRequired,
  onNotifyByEmailToggle: PropTypes.func.isRequired,
  onConfirmClick: PropTypes.func.isRequired,
  isSecondarySchool: PropTypes.bool
};

export default compose(
  withLocalizedContent('removeStudentsPanel', 'hubGlossary'),
  connect(
    state => {
      const { classrooms, classroomPage, organisations, people, hubRemoveUsersFromClassroom } = state;
      const classroom = get(classrooms, ['data', classroomPage.classroomId], {});
      const orgId = get(classroomPage, ['orgId'], '');
      const { curriculumType = '', role = '' } = get(organisations, ['data', `${orgId}`], {});

      return {
        formState: hubRemoveUsersFromClassroom.formState,
        users: pick(people.data, hubRemoveUsersFromClassroom.submittedIds),
        classroomName: classroom.name,
        curriculumType,
        numberOfUsers: (hubRemoveUsersFromClassroom.submittedIds || []).length,
        notifyByEmail: !!hubRemoveUsersFromClassroom.notifyWithEmail,
        requestFailed: !!hubRemoveUsersFromClassroom.requestFailed,
        failedIds: hubRemoveUsersFromClassroom.failedIds || [],
        isSecondarySchool: role === orgRoles.SECONDARY_SCHOOL
      };
    },
    {
      resetForm: clearForm,
      onNotifyByEmailToggle: setNotifyWithEmail,
      onConfirmClick: submitForm
    }
  )
)(RemoveMultipleUsers);
