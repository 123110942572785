import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import compose from '../../utils/compose/compose.js';
import userRoles, { getSelfSelectedRole } from '../../globals/userRoles';
import { featureIsEnabled } from '../../globals/envSettings';
// HOC
import withLocalizedContent from '../../language/withLocalizedContent';
// Styles
import styles from './MyDashboard.scss';
// Components
import HubMyDashboardBanner from '../../components/HubMyDashboardBanner/HubMyDashboardBanner';
import MyDashboardTeacherView from './MyDashboardTeacherView';
import MyDashboardStudentView from './MyDashboardStudentView';

function MyDashboard(props) {
  const {
    firstName,
    orgName,
    userRole,
    localizedContent: { hubGlossary: hubContent }
  } = props;
  const selfSelectedRole = getSelfSelectedRole();
  return (
    <div className={styles.dashboard}>
      <Helmet title={hubContent.my_dashboard_page_title} titleTemplate={hubContent.page_title_template} />
      <div className={styles.dashboardMain}>
        <HubMyDashboardBanner firstName={firstName} userRole={userRole} orgName={orgName} hubContent={hubContent} />
        {((userRole === userRoles.USER && selfSelectedRole !== 'SELF_TEACHER') || userRole === userRoles.LEARNER) &&
        featureIsEnabled('learner-dashboard-refresh') ? (
          <MyDashboardStudentView />
        ) : (
          <MyDashboardTeacherView />
        )}
      </div>
    </div>
  );
}

export default compose(
  connect(
    ({
      identity: { userId, role: userRole, currentOrganisationId },
      people: { data },
      organisations: { data: orgData }
    }) => ({
      userId,
      userRole,
      orgName: get(orgData, [currentOrganisationId, 'name'], ''),
      firstName: get(data, [userId, 'firstname'])
    })
  ),
  withLocalizedContent('hubGlossary')
)(MyDashboard);
MyDashboard.propTypes = {
  firstName: PropTypes.string,
  orgName: PropTypes.string,
  userRole: PropTypes.string,
  localizedContent: PropTypes.object.isRequired
};
