import React from 'react';
import PropTypes from 'prop-types';
import { useWizard } from '@oup/shared-front-end';
import { ORG_REGISTRATION_CONTEXT } from '@oup/shared-node-browser/constants';
import wizardStyles from './SelfRegisteredUserOnboardingWizard.scss';
import Link from '../Link/Link';
import SVGIcon, { GLYPHS } from '../SVGIcon/SVGIcon';
import Button, { buttonTypes } from '../Button/Button';
import withLocalizedContent from '../../language/withLocalizedContent';

function OrganizationSetupWizardHeader({
  secondWizardType,
  localizedContent: {
    selfRegisteredUserOnboardingWizard: selfRegisteredUserOnboardingWizardContent,
    hubGlossary: hubContent
  },
  setIsOpenStopSettingUpOrgModal,
  setIsOpenCreateFirstPlacementTestOrClassModal
}) {
  const { step, isFirstStep, isLastStep } = useWizard();
  const currentStep = step + 1;
  const isPlacementTestOrg = secondWizardType === ORG_REGISTRATION_CONTEXT.PLACEMENT;

  const renderWizardTitle = () => {
    switch (true) {
      case isFirstStep:
        return !isPlacementTestOrg
          ? selfRegisteredUserOnboardingWizardContent.staff_role_chooses_classes_and_courses_wizard_title
          : selfRegisteredUserOnboardingWizardContent.staff_role_chooses_placement_test_wizard_title;
      default:
        return selfRegisteredUserOnboardingWizardContent.self_registered_user_onboarding_wizard_create_organization;
    }
  };

  const renderTextForStep = () => {
    switch (true) {
      case currentStep === 2:
        return selfRegisteredUserOnboardingWizardContent.self_registered_user_onboarding_wizard_about_you;
      case isLastStep:
        return selfRegisteredUserOnboardingWizardContent.self_registered_user_onboarding_wizard_success;
      default:
        return selfRegisteredUserOnboardingWizardContent.self_registered_user_onboarding_wizard_welcome;
    }
  };

  const handleClose = () =>
    isLastStep ? setIsOpenCreateFirstPlacementTestOrClassModal(true) : setIsOpenStopSettingUpOrgModal(true);

  return (
    <div className={wizardStyles.header}>
      <div className={wizardStyles.headerContainer}>
        <div className={wizardStyles.title}>
          <span>{renderWizardTitle()}</span> {renderTextForStep()}
        </div>
        <div className={wizardStyles.helpAndSupport}>
          <Link
            dataTestId="SELF_REGISTERED_USER_ONBOARDING_WIZARD_SUPPORT_BUTTON"
            to={hubContent.help_and_support_link_to}
            target="_blank"
            role="button"
          >
            <SVGIcon glyph={GLYPHS.ICON_HELP_AND_SUPPORT} />
            <span className={wizardStyles.helpSupportText}>{hubContent.help_and_support}</span>
            <span className="a11y-hide">Help</span>
          </Link>
          {currentStep > 1 && (
            <Button
              text="Close"
              onClick={handleClose}
              type={buttonTypes.CLOSE_BORDER_GRAY}
              customClassName={wizardStyles.flexRight}
            />
          )}
        </div>
      </div>
    </div>
  );
}

OrganizationSetupWizardHeader.propTypes = {
  secondWizardType: PropTypes.string.isRequired,
  localizedContent: PropTypes.object.isRequired,
  setIsOpenStopSettingUpOrgModal: PropTypes.func.isRequired,
  setIsOpenCreateFirstPlacementTestOrClassModal: PropTypes.func.isRequired
};

export default withLocalizedContent('selfRegisteredUserOnboardingWizard', 'hubGlossary')(OrganizationSetupWizardHeader);
