import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import compose from '../../../../utils/compose/compose.js';
import PanelHeading from '../../../../components/PanelHeading/PanelHeading';
import PopoutActionFooter from '../../../../components/PopoutActionFooter/PopoutActionFooter';
import ScrollContainer from '../../../../components/ScrollContainer/ScrollContainer';
import { updatePlacementTestSessionRequest } from '../../../../redux/actions/placementTestOnboardingWizard';
import PlacementTestSessionNameInput from '../../../../components/PlacementTestOnBoardingWizard/PlacementTestSessionInput/PlacementTestSessionInput';
import withLocalizedContent from '../../../../language/withLocalizedContent';

function PlacementTestEditSessionName({
  panelSessionName,
  placementTestSessionId,
  placementTestSessionNameValue,
  updatePlacementTestSessionRequestAction,
  placementTestSessionNameValidationIsInvalid,
  placementTestSessionNameValidationIsWarning,
  placementTestSessionNameIsTouched,
  orgId,
  cancelSessionName,
  localizedContent: { placementTests: placementTestsContent, hubGlossary: hubContent }
}) {
  return (
    <ScrollContainer
      headerContent={
        <PanelHeading
          title={placementTestsContent.placement_test_session_manage_session_name}
          subtitle={`${hubContent.for_label} <strong>${panelSessionName}</strong>`}
        />
      }
      footerContent={
        <PopoutActionFooter
          wizardButtons
          withIcon={false}
          primaryButtonText={hubContent.save}
          primaryButtonDisabled={
            placementTestSessionNameValidationIsInvalid ||
            placementTestSessionNameValidationIsWarning ||
            !placementTestSessionNameValue ||
            !placementTestSessionNameIsTouched
          }
          primaryButtonAction={e => {
            updatePlacementTestSessionRequestAction({
              orgId,
              placementTestSessionId,
              placementTestSessionNameValue
            });
            cancelSessionName(e, placementTestSessionNameValue);
          }}
          secondaryButtonText={hubContent.cancel}
          secondaryButtonAction={cancelSessionName}
        />
      }
    >
      <div className="pad2">
        <PlacementTestSessionNameInput
          isInputDisabled={false}
          placementTestSessionNameLabel={placementTestsContent.placement_test_session_name}
          placementTestSessionNamePlaceholder={placementTestsContent.placement_test_session_name_placeholder}
        />
      </div>
    </ScrollContainer>
  );
}

PlacementTestEditSessionName.propTypes = {
  panelSessionName: PropTypes.string.isRequired,
  placementTestSessionId: PropTypes.string.isRequired,
  placementTestSessionNameValue: PropTypes.string.isRequired,
  updatePlacementTestSessionRequestAction: PropTypes.func.isRequired,
  placementTestSessionNameValidationIsInvalid: PropTypes.bool.isRequired,
  placementTestSessionNameValidationIsWarning: PropTypes.bool.isRequired,
  placementTestSessionNameIsTouched: PropTypes.bool,
  orgId: PropTypes.string.isRequired,
  cancelSessionName: PropTypes.func.isRequired,
  localizedContent: PropTypes.object
};

export default compose(
  withLocalizedContent('hubGlossary', 'placementTests'),
  connect(
    ({ placementTestSessionCreate, identity }) => ({
      placementTestSessionId: placementTestSessionCreate.placementTestSessionId,
      placementTestSessionNameValue: placementTestSessionCreate.placementTestSessionNameValue,
      placementTestSessionNameValidationIsInvalid:
        placementTestSessionCreate.placementTestSessionNameValidationIsInvalid,
      placementTestSessionNameValidationIsWarning:
        placementTestSessionCreate.placementTestSessionNameValidationIsWarning,
      placementTestSessionNameIsTouched: placementTestSessionCreate.placementTestSessionNameIsTouched,
      orgId: identity.currentOrganisationId
    }),
    { updatePlacementTestSessionRequestAction: updatePlacementTestSessionRequest }
  )
)(PlacementTestEditSessionName);
