import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
// Redux
import { connect } from 'react-redux';
import { Heading } from '@oup/shared-front-end';
import {
  setStudentSelected,
  setSelectAllStudents,
  checkIfAllStudentsAreSelected,
  setTeacherSelected,
  setSelectAllTeachers,
  checkIfAllTeachersAreSelected,
  setAllStudentsSelected,
  setAllTeachersSelected
} from '../../../redux/reducers/classroomPage';
import { handlePeopleSelectionPage, resetPagination } from '../../../redux/actions/hubPeople';
import { openAddTeacherModal, toggleAddStudentsModal } from '../../../redux/actions/hubUi';
// Styles
import styles from './People.scss';
// Constants
import {
  getPeopleHeaderItems,
  HubLayoutConstants,
  HubIllustrationConstants,
  HubIllustrationAltText
} from '../../../globals/hubConstants';
import colors from '../../../globals/colors';
import USER_ROLES, { roleIsAtLeast } from '../../../globals/userRoles';
import breakpoints from '../../../globals/breakpoints';
// Services
import getPeopleCheckboxData from '../../HubDashboardLayout/Services/getPeopleCheckboxData';
import { filterPeople } from '../../HubDashboardLayout/Services/filterPeople';
import loadPeopleDetails from '../../HubDashboardLayout/Services/loadPeopleDetails';
import getPeopleActionButtons from '../Services/getPeopleActionButtons.js';
// Components
import Person from './Person';
import SubSectionSkeletonLoader from '../../../components/SkeletonLoader/SubSectionSkeletonLoader';
import { PaginationButtons } from '../../../components';
import PeopleFooter from './PeopleFooter';
import HubEmptyStateRestyle from '../../../components/HubEmptyStateRestyle/HubEmptyStateRestyle';

import { GLYPHS } from '../../../components/SVGIcon/SVGIcon';
import HubRowHeader from '../../../components/HubRowHeader/HubRowHeader';
import HubFilterBar from '../../../components/HubFilterBar/HubFilterBar';
import { peopleSortOptions, peopleFilterOptions } from '../../../globals/hubSearchFilter';
import { setFilter, setSort, initialiseInstance } from '../../../redux/reducers/data/search.reducer';
import HubFilterBarSkeleton from '../../../components/HubFilterBar/HubFilterBarSkeleton';
import DataRefresher from '../../../components/DataRefresher/DataRefresher';
import { isLtiMode } from '../../../utils/platform.js';
import { featureIsEnabled } from '../../../globals/envSettings';

const getEmptyState = (userRole, hubContent, emptyStateButtons, classTitle, filtersEmptyState) => {
  let parametersEmptyState;

  if (userRole === USER_ROLES.LEARNER) {
    parametersEmptyState = {
      title: hubContent.student_page_empty_class_state_title,
      bodyText: isLtiMode()
        ? hubContent.student_page_empty_state_subtitle_ltihub
        : hubContent.student_class_page_empty_state_subtitle,
      btnFilledBase: isLtiMode() ? null : emptyStateButtons[0]
    };
  } else {
    parametersEmptyState = {
      title: hubContent.teacher_page_empty_class_state_title,
      bodyText: hubContent.teacher_class_page_empty_state_subtitle,
      btnFilledBase: emptyStateButtons[1]
    };
  }

  return (
    <div>
      <HubEmptyStateRestyle
        iconSrc={filtersEmptyState ? HubIllustrationConstants.INFORMATION : HubIllustrationConstants.SEARCH}
        title={parametersEmptyState.title}
        bodyText={parametersEmptyState.bodyText.replace('{className}', classTitle)}
        btnFilledBase={parametersEmptyState.btnFilledBase}
        customClassName="displayMobileButtons"
      />
    </div>
  );
};

function People({
  studentIds,
  teacherIds,
  classroomId,
  people,
  hubContent,
  productFinderContent,
  arePeopleLoading,
  selectedStudentIds,
  areStudentsSelected,
  selectedTeacherIds,
  setStudentSelectedAction,
  setSelectAllStudentsAction,
  checkIfAllStudentsAreSelectedAction,
  setAllStudentsSelectedAction,
  setTeacherSelectedAction,
  setSelectAllTeachersAction,
  areTeachersSelected,
  checkIfAllTeachersAreSelectedAction,
  setAllTeachersSelectedAction,
  openAddTeacherModalAction,
  toggleAddStudentsModalAction,
  handlePeopleSelectionPageAction,
  resetPaginationAction,
  isTeacherAssignedToClass,
  page,
  classTitle,
  startSlice,
  endSlice,
  orgId,
  breakpoint,
  processedUserRole,
  userDropdownActions,
  isClassModalOpen,
  authUser,
  push,
  setFilterAction,
  setSortAction,
  initialiseSearch,
  peopleFilters,
  areStudentsLoading,
  areTeachersLoading,
  context,
  selectedUsers,
  currentOrganisationLti
}) {
  const [filtersEmptyState, setFiltersEmptyState] = useState(false);
  useEffect(() => {
    if (!areStudentsLoading && !areTeachersLoading) {
      resetPaginationAction();
    }
  }, [arePeopleLoading]);
  const classPageTitle = `${classTitle} | ${hubContent.people}`;

  const handlePageSelection = value => {
    handlePeopleSelectionPageAction(value);
  };

  const _getUsersContextName = () =>
    selectedUsers.length === 1
      ? `${people[selectedUsers[0]]?.firstname} ${people[selectedUsers[0]]?.lastname}`
      : `${selectedUsers.length} ${productFinderContent.users_text}`;

  const slicedIds = [...teacherIds, ...studentIds].slice(startSlice, endSlice);

  const totalResults = teacherIds.length + studentIds.length;
  const { MAX_PEOPLE_PER_PAGE } = HubLayoutConstants;

  const isPageFullOfTeachers = teacherIds && teacherIds.slice(startSlice, endSlice).length === MAX_PEOPLE_PER_PAGE;

  const headerItems = getPeopleHeaderItems(hubContent, openAddTeacherModalAction, toggleAddStudentsModalAction);

  const footerOptions = {
    isArchived: false,
    isActive: true,
    isPending: false,
    hideRemoveOption:
      selectedTeacherIds.includes(authUser.id) && !roleIsAtLeast(USER_ROLES.TEACHER_ADMIN, authUser.role),
    showSendBulkPasswordResetEmail:
      featureIsEnabled('bulk-password-reset') &&
      roleIsAtLeast(USER_ROLES.TEACHER_ADMIN, authUser.role) &&
      selectedUsers?.length &&
      selectedUsers?.filter(selectedUserId => studentIds?.indexOf(selectedUserId) === -1)?.length === 0,
    showSendPasswordResetEmail: false,
    // Do not display the supervised user option on People footer
    isSupervisedUser: false
  };

  const peopleFooterActionButtons = getPeopleActionButtons({
    hubContent,
    dropdownOptions: footerOptions,
    dropdownActions: userDropdownActions,
    currentOrganisationLti
  });

  const {
    PEOPLE: {
      emptyStateTitle = '',
      emptyStateSubtitle = '',
      showAddStudentsButton = false,
      showAddTeachersButton = false
    } = {}
  } = processedUserRole;

  const emptyStateButtons = [
    {
      showButton: showAddStudentsButton && isTeacherAssignedToClass,
      isPrimaryButton: true,
      text: hubContent.add_students,
      action: toggleAddStudentsModalAction,
      icon: GLYPHS.ICON_PLUS
    },
    {
      showButton: showAddTeachersButton && isTeacherAssignedToClass,
      isPrimaryButton: true,
      text: hubContent.add_teachers,
      action: openAddTeacherModalAction,
      icon: GLYPHS.ICON_PLUS
    }
  ];

  const filtersEmptyStateButtons = [
    {
      showButton: true,
      isPrimaryButton: true,
      text: `${hubContent.class_page_filters_reset}`,
      icon: GLYPHS.ICON_REFRESH,
      action: () => {
        loadPeopleDetails(initialiseSearch, orgId, classroomId);
        setFiltersEmptyState(false);
      }
    }
  ];

  const setFilterActionPeople = (valueName, value) => {
    setFilterAction('classStudents', valueName, value);
    setFilterAction('classTeachers', valueName, value);
    setFiltersEmptyState(true);
  };

  const setSortActionPeople = sort => {
    setSortAction('classStudents', sort[0]);
    setSortAction('classTeachers', sort[0]);
  };

  const emptyStateComponent = filtersEmptyState ? (
    <HubEmptyStateRestyle
      iconSrc={HubIllustrationConstants.SEARCH}
      iconAlt={HubIllustrationAltText.SEARCH}
      title={hubContent.class_page_filters_empty_state_title}
      bodyText={hubContent.class_page_filters_empty_state_subtitle}
      btnFilledBase={
        filtersEmptyStateButtons && filtersEmptyStateButtons.length > 0 ? filtersEmptyStateButtons[0] : null
      }
      btnOutlineBase={isLtiMode() ? undefined : emptyStateButtons[0]}
      btnSecondaryBase={isLtiMode() ? undefined : emptyStateButtons[1]}
    />
  ) : (
    <HubEmptyStateRestyle
      iconSrc={HubIllustrationConstants.SEARCH}
      title={emptyStateTitle}
      bodyText={emptyStateSubtitle.replace('{className}', classTitle)}
      btnFilledBase={isLtiMode() ? undefined : emptyStateButtons[1]}
      btnOutlineBase={isLtiMode() ? undefined : emptyStateButtons[0]}
    />
  );

  const sortValue = peopleFilters.sortStudents || peopleFilters.sortTeachers;

  const customFilterComponent = <HubFilterBarSkeleton />;

  const processedHeaderItems = isPageFullOfTeachers ? headerItems.slice(0, 1) : headerItems;

  const showHeading = featureIsEnabled('navigation-changes');
  const headingArgs = {
    text: hubContent.people,
    size: 'small'
  };

  return (
    <div className={styles.peopleContainer}>
      <Helmet title={classPageTitle} />
      {showHeading && <Heading {...headingArgs} />}
      <DataRefresher
        loading={arePeopleLoading}
        noSidePadding={showHeading}
        refreshData={() => loadPeopleDetails(initialiseSearch, orgId, classroomId)}
      />
      <div>
        <HubFilterBar
          idPrefix="classPeopleSearch"
          filterButtonText={breakpoint !== breakpoints.XXS ? hubContent.status_button : hubContent.filter_button}
          overlayLabelText={hubContent.status_button}
          breakpoint={breakpoint}
          filterOptions={peopleFilterOptions(
            'classPeopleSearch',
            [
              peopleFilters.filtersTeachers.active && peopleFilters.filtersStudents.active,
              peopleFilters.filtersTeachers.invited && peopleFilters.filtersStudents.invited,
              peopleFilters.filtersTeachers.archived && peopleFilters.filtersStudents.archived
            ],
            setFilterActionPeople,
            hubContent
          )}
          sortOnChange={setSortActionPeople}
          sortOptions={peopleSortOptions('classPeopleSearch', sortValue, hubContent)}
          ariaControls="searchResults"
          sortValue={sortValue}
        />
      </div>
      {arePeopleLoading ? (
        <SubSectionSkeletonLoader
          tabName=""
          panelName=""
          speed={2}
          foregroundColor={colors.COLOR_GREY_DISABLEDEDED}
          backgroundColor={colors.COLOR_WHITE}
          customEnhancedComponent={customFilterComponent}
        />
      ) : (
        <div>
          {processedHeaderItems && processedHeaderItems.length && totalResults
            ? processedHeaderItems.map((item, index) => {
                const { peopleToDisplay, peopleWithCheckbox } = filterPeople(slicedIds, people, item.role, classroomId);
                return (
                  <div key={item.name}>
                    <HubRowHeader
                      item={item}
                      type={HubLayoutConstants.HUB_ROW_HEADER_TYPES.PEOPLE}
                      hasAddButton={!currentOrganisationLti && !!peopleToDisplay?.length}
                      ids={peopleWithCheckbox}
                      isTeacherAssignedToClass={isTeacherAssignedToClass}
                      showCheckbox
                      checkboxData={getPeopleCheckboxData(
                        item.role,
                        setStudentSelectedAction,
                        selectedStudentIds,
                        setSelectAllStudentsAction,
                        areStudentsSelected,
                        checkIfAllStudentsAreSelectedAction,
                        setAllStudentsSelectedAction,
                        setTeacherSelectedAction,
                        selectedTeacherIds,
                        setSelectAllTeachersAction,
                        areTeachersSelected,
                        checkIfAllTeachersAreSelectedAction,
                        setAllTeachersSelectedAction
                      )}
                    />
                    {!(peopleToDisplay && peopleToDisplay.length) &&
                      !startSlice &&
                      getEmptyState(item.role, hubContent, emptyStateButtons, classTitle, filtersEmptyState)}
                    <Person
                      ids={peopleToDisplay}
                      peopleIds={peopleWithCheckbox}
                      isTeacherAssignedToClass={isTeacherAssignedToClass}
                      items={people}
                      hubContent={hubContent}
                      role={item.role}
                      breakpoint={breakpoint}
                      classroomId={classroomId}
                      actions={userDropdownActions}
                      showCheckbox
                      showDropdown
                      checkboxData={getPeopleCheckboxData(
                        item.role,
                        setStudentSelectedAction,
                        selectedStudentIds,
                        setSelectAllStudentsAction,
                        areStudentsSelected,
                        checkIfAllStudentsAreSelectedAction,
                        setAllStudentsSelectedAction,
                        setTeacherSelectedAction,
                        selectedTeacherIds,
                        setSelectAllTeachersAction,
                        areTeachersSelected,
                        checkIfAllTeachersAreSelectedAction,
                        setAllTeachersSelectedAction
                      )}
                      authUser={authUser}
                      push={push}
                      dropdownTop={
                        /** If this is the last section on People page, then apply the dropdownTop logic */
                        index === (processedHeaderItems?.length || 0) - 1
                      }
                      context={context}
                      currentOrganisationLti={currentOrganisationLti}
                    />
                  </div>
                );
              })
            : emptyStateComponent}
        </div>
      )}
      {!arePeopleLoading && totalResults > MAX_PEOPLE_PER_PAGE ? (
        <div className={styles.paginationButtonsContainer}>
          <PaginationButtons
            idPrefix="peopleSearch"
            value={page}
            numberOfPages={Math.ceil(totalResults / 10)}
            onClick={handlePageSelection}
            aria={{ 'aria-controls': 'searchResults' }}
          />
        </div>
      ) : null}
      <PeopleFooter
        widgetText={hubContent.chosen_text}
        contextName={_getUsersContextName()}
        actions={peopleFooterActionButtons}
        breakpoint={breakpoint}
        isModalOpen={isClassModalOpen}
        selectedUsers={selectedUsers}
        context={context}
      />
    </div>
  );
}

People.propTypes = {
  studentIds: PropTypes.array.isRequired,
  teacherIds: PropTypes.array.isRequired,
  people: PropTypes.object.isRequired,
  classroomId: PropTypes.string.isRequired,
  productFinderContent: PropTypes.object,
  hubContent: PropTypes.object.isRequired,
  arePeopleLoading: PropTypes.bool.isRequired,
  selectedStudentIds: PropTypes.array.isRequired,
  areStudentsSelected: PropTypes.bool.isRequired,
  selectedTeacherIds: PropTypes.array.isRequired,
  setStudentSelectedAction: PropTypes.func.isRequired,
  setSelectAllStudentsAction: PropTypes.func.isRequired,
  checkIfAllStudentsAreSelectedAction: PropTypes.func.isRequired,
  areTeachersSelected: PropTypes.bool.isRequired,
  setTeacherSelectedAction: PropTypes.func.isRequired,
  setSelectAllTeachersAction: PropTypes.func.isRequired,
  checkIfAllTeachersAreSelectedAction: PropTypes.func.isRequired,
  handlePeopleSelectionPageAction: PropTypes.func.isRequired,
  resetPaginationAction: PropTypes.func.isRequired,
  isTeacherAssignedToClass: PropTypes.bool.isRequired,
  page: PropTypes.number.isRequired,
  classTitle: PropTypes.string,
  startSlice: PropTypes.number.isRequired,
  endSlice: PropTypes.number.isRequired,
  orgId: PropTypes.string.isRequired,
  breakpoint: PropTypes.string.isRequired,
  openAddTeacherModalAction: PropTypes.func.isRequired,
  toggleAddStudentsModalAction: PropTypes.func.isRequired,
  userDropdownActions: PropTypes.object,
  processedUserRole: PropTypes.object.isRequired,
  isClassModalOpen: PropTypes.bool,
  authUser: PropTypes.object.isRequired,
  push: PropTypes.func.isRequired,
  setFilterAction: PropTypes.func.isRequired,
  setSortAction: PropTypes.func.isRequired,
  initialiseSearch: PropTypes.func.isRequired,
  peopleFilters: PropTypes.object.isRequired,
  areStudentsLoading: PropTypes.bool,
  areTeachersLoading: PropTypes.bool,
  context: PropTypes.string.isRequired,
  selectedUsers: PropTypes.array,
  currentOrganisationLti: PropTypes.bool,
  setAllStudentsSelectedAction: PropTypes.func,
  setAllTeachersSelectedAction: PropTypes.func
};

export default connect(
  ({
    search: {
      classStudents: {
        ids: classStudentIds = [],
        loading: areStudentsLoading = false,
        sort: sortStudents = 'firstName:asc',
        page: pageStudents = 1,
        filters: filtersStudents = {},
        totalResults: totalResultsStudents = 0,
        error: errorStudents = false
      } = {},
      classTeachers: {
        ids: classTeacherIds = [],
        loading: areTeachersLoading = false,
        sort: sortTeachers = 'firstName:asc',
        page: pageTeachers = 1,
        filters: filtersTeachers = {},
        totalResults: totalResultsTeachers = 0,
        error: errorTeachers = false
      } = {}
    } = {},
    people: { data: people = {} } = {},
    hubPeople: { page = 0, startSlice = 0, endSlice = 0 } = {},
    hubUi: { classModalOpen },
    classroomPage: {
      selectedStudentIds = [],
      selectedTeacherIds = [],
      areStudentsSelected = false,
      areTeachersSelected = false
    } = {},
    identity: { userId: authUserId, role: authRole, currentOrganisationLti }
  }) => ({
    studentIds: classStudentIds,
    teacherIds: classTeacherIds,
    people,
    arePeopleLoading: areStudentsLoading || areTeachersLoading,
    areStudentsLoading,
    areTeachersLoading,
    selectedStudentIds,
    areStudentsSelected,
    selectedTeacherIds,
    areTeachersSelected,
    page,
    startSlice,
    endSlice,
    totalSelectedPeople: selectedStudentIds.length + selectedTeacherIds.length,
    selectedUsers: [...selectedStudentIds, ...selectedTeacherIds],
    isClassModalOpen: classModalOpen,
    authUser: { id: authUserId, role: authRole },
    currentOrganisationLti,
    peopleFilters: {
      sortStudents,
      pageStudents,
      filtersStudents,
      totalResultsStudents,
      errorStudents,
      sortTeachers,
      pageTeachers,
      filtersTeachers,
      totalResultsTeachers,
      errorTeachers
    }
  }),
  {
    setStudentSelectedAction: setStudentSelected,
    setSelectAllStudentsAction: setSelectAllStudents,
    checkIfAllStudentsAreSelectedAction: checkIfAllStudentsAreSelected,
    setTeacherSelectedAction: setTeacherSelected,
    setSelectAllTeachersAction: setSelectAllTeachers,
    checkIfAllTeachersAreSelectedAction: checkIfAllTeachersAreSelected,
    setAllStudentsSelectedAction: setAllStudentsSelected,
    setAllTeachersSelectedAction: setAllTeachersSelected,
    handlePeopleSelectionPageAction: handlePeopleSelectionPage,
    resetPaginationAction: resetPagination,
    openAddTeacherModalAction: openAddTeacherModal,
    toggleAddStudentsModalAction: toggleAddStudentsModal,
    setFilterAction: setFilter,
    setSortAction: setSort,
    initialiseSearch: initialiseInstance
  }
)(People);
