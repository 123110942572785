import React from 'react';
import PropTypes from 'prop-types';
import compose from '../../../utils/compose/compose.js';
import OnlineTestDateTime from '../../OnlineTestDateTime/OnlineTestDateTime';

function PlacementTestStartEndDate({
  testStartDate,
  setTestStartDate,
  setTestStartDateIsInvalid,
  testEndDate,
  setTestEndDate,
  setTestEndDateIsInvalid,
  placementTestStatus
}) {
  return (
    <OnlineTestDateTime
      showTooltipStartDateTime={false}
      showTooltipEndDateTime={false}
      onSelectStartDateTime={(startDate, hasStartDateErrors) => {
        setTestStartDate(startDate.toISOString());
        setTestStartDateIsInvalid(hasStartDateErrors);
      }}
      onSelectEndDateTime={(endDate, hasEndDateErrors) => {
        setTestEndDate(endDate.toISOString());
        setTestEndDateIsInvalid(hasEndDateErrors);
      }}
      defaultStartDateTime={testStartDate ? new Date(testStartDate) : null}
      defaultEndDateTime={testEndDate ? new Date(testEndDate) : null}
      placementTestStatus={placementTestStatus}
    />
  );
}

PlacementTestStartEndDate.propTypes = {
  testStartDate: PropTypes.string,
  setTestStartDate: PropTypes.func,
  setTestStartDateIsInvalid: PropTypes.func,
  testEndDate: PropTypes.string,
  setTestEndDate: PropTypes.func,
  setTestEndDateIsInvalid: PropTypes.func,
  placementTestStatus: PropTypes.string
};

export default compose()(PlacementTestStartEndDate);
