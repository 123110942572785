import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ValidationMessage, Button } from '@oup/shared-front-end';
import { PLACEMENT_TEST_STATUS, PLACEMENT_TEST_RESULTS_STATUS } from '@oup/shared-node-browser/constants';
import { userRoles } from '@oup/shared-node-browser/user.js';
import { ICON_CLOSE, ICON_LINK } from '@oup/shared-front-end/svg/oup';

import compose from '../../../../utils/compose/compose.js';
import HubModalLayout from '../../../../components/HubModalLayout/HubModalLayout';
import { OPT_YL } from '../../../../components/PlacementTestOnBoardingWizard/utils/constants';
import { formatDate } from '../../../../components/PlacementTestOnBoardingWizard/utils/helpers';
import SVGIcon, { GLYPHS } from '../../../../components/SVGIcon/SVGIcon';
import {
  closePlacementTestSessionInformationModal,
  openPlacementTestOnboardingWizard,
  openPlacementTestPanel
} from '../../../../redux/actions/hubUi';
import { continueEditedPlacementTest } from '../../../../redux/reducers/placementTestSessionCreate';
import { getContentPreviewRequest } from '../../../../redux/actions/contentPreview';
import { loadPlacementTestResultsRequest } from '../../../../redux/actions/placementTestResults';
import styles from './PlacementTestSessionInformationModal.scss';
import withLocalizedContent from '../../../../language/withLocalizedContent';
import downloadAsFile from '../../../../utils/downloadAsFile';
import { loadUserAccessPlacementTestRequest } from '../../../../redux/actions/placementTests';

function showPlacementTestStatus(userRole, placementTestStatus) {
  const showStatus =
    userRole === userRoles.LEARNER &&
    (placementTestStatus === PLACEMENT_TEST_STATUS.ACTIVE ||
      placementTestStatus === PLACEMENT_TEST_RESULTS_STATUS.UPCOMING);
  return showStatus;
}
function PlacementTestSessionInformationModal({
  isOpen,
  closeModal,
  placementTestSessionNameValue,
  placementTestSessionId,
  orgId,
  placementTestType,
  placementTestStatus,
  placementTest,
  testStartDate,
  testEndDate,
  showResultsToStudents,
  getContentPreviewRequestAction,
  openPlacementTestOnboardingWizardAction,
  continueEditedPlacementTestAction,
  openPlacementTestPanelAction,
  downloadPlacementTestResultsAction,
  resultsFileUrl,
  downloadResultUrlReady,
  content,
  localizedContent: { placementTests: placementTestsContent, hubGlossary: hubContent },
  userRole,
  loadUserAccessPlacementTestAction
}) {
  const [modalSessionName, setModalSessionName] = useState('');
  const [buttonActionAndLabel, setButtonActionAndLabel] = useState({ label: '', action: () => {} });
  const [product, setProduct] = useState({});
  const isMyTasksPage = window.location.pathname.split('/').includes('myTasks');

  useEffect(() => {
    getContentPreviewRequestAction({
      source: 'am'
    });
  }, []);

  useEffect(() => {
    if (downloadResultUrlReady) {
      downloadAsFile({ url: resultsFileUrl });
    }
  }, [downloadResultUrlReady]);

  const renderProductCover = () => {
    let customClassName = styles.iconContainer;
    let iconGlyph = placementTestType === OPT_YL ? GLYPHS.ICON_YOUNG_LEARNERS_TEST : GLYPHS.ICON_DRAFT_PLACEMENT_TEST;
    if (!placementTestType) {
      customClassName = styles.productCover;
      iconGlyph = GLYPHS.ICON_PENCIL_IN_CIRCLE;
    }
    return (
      <div className={customClassName}>
        <SVGIcon glyph={iconGlyph} />
      </div>
    );
  };

  const formatStartDate = () => {
    if (formatDate(new Date(), false) === formatDate(testStartDate, false)) {
      return `Today ${formatDate(testStartDate, false, true)}`;
    }
    return formatDate(testStartDate);
  };

  const renderValidationMessage = () => {
    if (isMyTasksPage) {
      return !showResultsToStudents ? (
        <ValidationMessage state="information">
          {placementTestsContent.placement_test_talk_to_your_teacher_about_your_results}
        </ValidationMessage>
      ) : null;
    }
    return (
      <ValidationMessage state="information">{placementTestsContent.placement_test_session_closed}</ValidationMessage>
    );
  };

  useEffect(() => {
    switch (placementTestStatus) {
      case PLACEMENT_TEST_STATUS.DRAFT:
        return setButtonActionAndLabel({
          label: hubContent.placement_test_session_continue_set_up,
          action: e => {
            closeModal();
            openPlacementTestOnboardingWizardAction();
            continueEditedPlacementTestAction({
              placementTestSessionId,
              placementTestSessionNameValue,
              placementTest
            });
            e.preventDefault();
          }
        });
      case PLACEMENT_TEST_STATUS.ACTIVE:
        if (isMyTasksPage)
          return setButtonActionAndLabel({
            label: hubContent.placement_test_session_access_test,
            icon: { placement: 'right', component: <ICON_LINK /> },
            action: () => {
              closeModal();
              loadUserAccessPlacementTestAction({ classId: placementTestSessionId, orgId });
            }
          });
        return setButtonActionAndLabel({
          label: placementTestsContent.placement_test_ellipsis_menu_button_download_progress,
          action: e => {
            downloadPlacementTestResultsAction({ sessionId: placementTestSessionId });
            e.preventDefault();
          }
        });
      case PLACEMENT_TEST_STATUS.UPCOMING:
        return isMyTasksPage
          ? setButtonActionAndLabel({
              label: hubContent.done_button,
              action: e => {
                closeModal();
                e.preventDefault();
              }
            })
          : setButtonActionAndLabel({
              label: hubContent.placement_test_session_manage_session,
              action: e => {
                closeModal();
                openPlacementTestPanelAction();
                continueEditedPlacementTestAction({
                  placementTestSessionId,
                  placementTestSessionNameValue,
                  joiningCode: placementTest.joiningCode,
                  placementTest
                });
                e.preventDefault();
              }
            });
      case PLACEMENT_TEST_STATUS.COMPLETED:
        if (isMyTasksPage) {
          return showResultsToStudents
            ? setButtonActionAndLabel({ label: hubContent.placement_test_session_view_progress, action: () => {} })
            : setButtonActionAndLabel({
                label: hubContent.done_button,
                action: () => {
                  closeModal();
                }
              });
        }
        return setButtonActionAndLabel({
          label: placementTestsContent.placement_test_ellipsis_menu_button_download_results,
          action: e => {
            downloadPlacementTestResultsAction({ sessionId: placementTestSessionId });
            e.preventDefault();
          }
        });
      case PLACEMENT_TEST_RESULTS_STATUS.MISSED:
      case PLACEMENT_TEST_STATUS.MISSING:
        return setButtonActionAndLabel({
          label: hubContent.done_button,
          action: e => {
            closeModal();
            e.preventDefault();
          }
        });
      default:
        return setButtonActionAndLabel({ label: '', action: () => {} });
    }
  }, [placementTestStatus, showResultsToStudents]);

  useEffect(() => {
    if (placementTestType && content && content.length) {
      const sessionProduct = placementTestType === OPT_YL ? content[1] : content[0];
      setProduct(sessionProduct);
    }
  }, [content, placementTestType]);

  useEffect(() => {
    setModalSessionName(placementTestSessionNameValue);
  }, [isOpen]);

  return (
    <div className={`${styles.modalWrapper} ${isOpen ? styles.open : ''}`}>
      <HubModalLayout
        isOpen={isOpen}
        closeAction={closeModal}
        ariaLabel={placementTestsContent.placement_test_session_close_modal_ariaLabel}
      >
        {isOpen && (
          <div className={`${styles.modalContainer} ${isOpen ? styles.animationFadeIn : ''}`} aria-hidden={!isOpen}>
            <div className={styles.modalHeader}>
              <Button
                variant="transparent"
                size="small"
                icon={{ placement: 'right', component: <ICON_CLOSE aria-hidden="true" /> }}
                onClick={e => {
                  e.stopPropagation();
                  closeModal();
                }}
                dataAttributes={{ testId: 'PLACEMENT_TEST_INFORMATION_MODAL_HEADER_CLOSE' }}
              />
            </div>

            <div className={styles.modalContent}>
              <div className={styles.sessionTitlesContainer}>
                <div className={styles.testTypeCover}>{renderProductCover()}</div>
                <div className={styles.productTitles}>
                  <div className={styles.titleContainer}>
                    {showPlacementTestStatus(userRole, placementTestStatus) && (
                      <span className={`${styles.testStatus} ${styles[placementTestStatus]}`}>
                        {placementTestStatus}
                      </span>
                    )}
                    <p className={styles.title}>{modalSessionName}</p>
                  </div>
                  {placementTestType && <p className={styles.productTitle}>{product.title}</p>}
                </div>
              </div>
              {placementTestStatus === PLACEMENT_TEST_STATUS.COMPLETED && renderValidationMessage()}
              <div className={styles.sessionDates}>
                <span>
                  <b>{placementTestsContent.placement_test_summary_start} </b>
                  {testStartDate ? formatStartDate() : placementTestsContent.placement_test_session_time_not_set}
                </span>
                <span>
                  <b>{placementTestsContent.placement_test_summary_end} </b>
                  {testEndDate ? formatDate(testEndDate) : placementTestsContent.placement_test_session_time_not_set}
                </span>
                {isMyTasksPage &&
                showResultsToStudents &&
                placementTestStatus === PLACEMENT_TEST_RESULTS_STATUS.COMPLETED &&
                placementTest.cefrLevel ? (
                  <span>
                    <b>{placementTestsContent.placement_test_score} </b>
                    <span>
                      {' '}
                      {placementTest.score}/120 ({placementTest.cefrLevel})
                    </span>
                  </span>
                ) : null}
              </div>
            </div>

            <div
              className={`${styles.modalFooter} ${
                isMyTasksPage ? styles.modalFooterMyTasks : styles.modalFooterDefault
              }`}
            >
              <Button
                text={hubContent.cancel}
                variant="outline"
                onClick={() => closeModal()}
                dataAttributes={{ testId: 'PLACEMENT_TEST_INFORMATION_MODAL_FOOTER_CLOSE' }}
              />
              <Button
                text={buttonActionAndLabel.label}
                onClick={buttonActionAndLabel.action}
                dataAttributes={{ testId: 'PLACEMENT_TEST_INFORMATION_MODAL_ACTION_BUTTON' }}
                icon={buttonActionAndLabel.icon ?? {}}
              />
            </div>
          </div>
        )}
      </HubModalLayout>
    </div>
  );
}

PlacementTestSessionInformationModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func,
  placementTestSessionNameValue: PropTypes.string,
  placementTestSessionId: PropTypes.string,
  orgId: PropTypes.string,
  placementTestType: PropTypes.string,
  testStartDate: PropTypes.string,
  testEndDate: PropTypes.string,
  placementTestStatus: PropTypes.string,
  showResultsToStudents: PropTypes.bool,
  getContentPreviewRequestAction: PropTypes.func,
  openPlacementTestOnboardingWizardAction: PropTypes.func,
  continueEditedPlacementTestAction: PropTypes.func,
  openPlacementTestPanelAction: PropTypes.func,
  downloadPlacementTestResultsAction: PropTypes.func,
  resultsFileUrl: PropTypes.string,
  downloadResultUrlReady: PropTypes.bool,
  placementTest: PropTypes.object,
  content: PropTypes.object,
  localizedContent: PropTypes.object,
  userRole: PropTypes.string,
  loadUserAccessPlacementTestAction: PropTypes.func
};

export default compose(
  withLocalizedContent('hubGlossary', 'placementTests'),
  connect(
    ({
      placementTestSessionCreate,
      contentPreview,
      loadPlacementTestResultsReducer: { resultsFileUrl, success: downloadResultUrlReady }
    }) => ({
      placementTestSessionNameValue: placementTestSessionCreate.placementTestSessionNameValue,
      placementTestSessionId: placementTestSessionCreate.placementTestSessionId,
      placementTestType: placementTestSessionCreate.placementTest.placementTestType,
      placementTestStatus: placementTestSessionCreate.placementTest.status,
      placementTest: placementTestSessionCreate.placementTest,
      testStartDate: placementTestSessionCreate.placementTest.testStartDate,
      testEndDate: placementTestSessionCreate.placementTest.testEndDate,
      showResultsToStudents: placementTestSessionCreate.placementTest.showResultsToStudents,
      content: contentPreview.data || contentPreview.results,
      resultsFileUrl,
      downloadResultUrlReady,
      orgId: placementTestSessionCreate.orgId
    }),
    {
      downloadPlacementTestResultsAction: loadPlacementTestResultsRequest,
      closeModal: closePlacementTestSessionInformationModal,
      getContentPreviewRequestAction: getContentPreviewRequest,
      openPlacementTestOnboardingWizardAction: openPlacementTestOnboardingWizard,
      continueEditedPlacementTestAction: continueEditedPlacementTest,
      openPlacementTestPanelAction: openPlacementTestPanel,
      loadUserAccessPlacementTestAction: loadUserAccessPlacementTestRequest
    }
  )
)(PlacementTestSessionInformationModal);
