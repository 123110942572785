import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// Styles
import { ICON_RESOURCES } from '@oup/shared-front-end/svg/oup';
import { Heading } from '@oup/shared-front-end';
import compose from '../../utils/compose/compose.js';
import styles from './LastOpened.scss';
import { GLYPHS } from '../../components/SVGIcon/SVGIcon';
// Redux
import {
  openRedeemModal,
  openSelfRegisteredWizard,
  showSelfSelectRoleModal,
  openEnterCodeModal
} from '../../redux/actions/hubUi';
import { getRecentlyAccessedRequest, updateRecentlyAccessedRequest } from '../../redux/actions/recentlyAccessed';
// Constants
import { HubLayoutConstants, HubIllustrationConstants, HubIllustrationAltText } from '../../globals/hubConstants';
import { featureIsEnabled } from '../../globals/envSettings';
// HOC
import withLocalizedContent from '../../language/withLocalizedContent';
// Components
import LastOpenedItem from '../../components/LastOpened/LastOpenedItem/LastOpenedItem';
import HubEmptyStateRestyle from '../../components/HubEmptyStateRestyle/HubEmptyStateRestyle';
import TileLoader from '../../components/SkeletonLoader/Hub/TileLoader';

import { isLtiMode } from '../../utils/platform';

function LastOpened({
  recentlyAccessedItems,
  loading,
  courses,
  coursesLoading,
  openRedeemModalAction,
  getRecentlyAccessedAction,
  updateRecentlyAccessedAction,
  openEnterCodeModalAction,
  localizedContent: { hubGlossary: hubContent }
}) {
  const navigate = useNavigate();
  useEffect(() => {
    getRecentlyAccessedAction();
  }, []);

  const showEnterCodeButton = !Object.keys(courses).length && !isLtiMode();

  const emptyState = showEnterCodeButton ? (
    <HubEmptyStateRestyle
      iconSrc={HubIllustrationConstants.DIGITAL_LIBRARY}
      iconAlt={HubIllustrationAltText.DIGITAL_LIBRARY}
      title={hubContent.student_dashboard_new_accessed_products_title}
      bodyText={hubContent.student_dashboard_new_accessed_products_subtitle}
      btnFilledBase={{
        text: hubContent.enter_a_code,
        icon: GLYPHS.ICON_PLUS,
        action: () =>
          featureIsEnabled('learner-dashboard-refresh') ? openEnterCodeModalAction() : openRedeemModalAction()
      }}
    />
  ) : (
    <HubEmptyStateRestyle
      iconSrc={HubIllustrationConstants.DIGITAL_LIBRARY}
      iconAlt={HubIllustrationAltText.DIGITAL_LIBRARY}
      title={hubContent.student_dashboard_never_accessed_products_title}
      bodyText={hubContent.student_dashboard_never_accessed_products_subtitle}
      btnFilledBase={{
        text: hubContent.my_courses,
        icon: GLYPHS.ICON_COURSE,
        action: () => navigate(HubLayoutConstants.PATH_NAMES.COURSES_PATH)
      }}
    />
  );

  const handleOnClick = (e, item) => {
    if (item.type === 'product') {
      e.preventDefault();
      e.stopPropagation();
      updateRecentlyAccessedAction({
        url: item.url,
        type: 'product',
        title: item.title,
        imageUrl: item.imageUrl
      });
      window.open(item.url, '_blank');
    }
  };
  const lastOpenedContent =
    recentlyAccessedItems.length === 0 && !loading && !coursesLoading ? (
      <div className={styles.emptyStateContainer}>{emptyState}</div>
    ) : (
      <>
        <Heading className={styles.heading} size="small" text={hubContent.last_opened} variant="h1" />
        {(loading || (recentlyAccessedItems.length === 0 && coursesLoading)) && (
          <TileLoader columnNumber={3} customClassName="lastOpenedTileSkeleton" />
        )}
        {!loading &&
          recentlyAccessedItems.length > 0 &&
          Object.values(recentlyAccessedItems).map(item => (
            <LastOpenedItem
              key={item}
              title={item.title}
              subtitle={item.subtitle}
              to={item.url}
              imageOrIcon={
                item.type === 'product' ? (
                  <img className={styles.image} src={item.imageUrl} alt={item.title} />
                ) : (
                  <ICON_RESOURCES className={styles.icon} />
                )
              }
              onClick={e => handleOnClick(e, item)}
            />
          ))}
      </>
    );

  return <div className={styles.lastOpenArea}>{lastOpenedContent}</div>;
}

export default compose(
  connect(
    ({ recentlyAccessed = { items: [], loading: false }, hubCourses = { courses: {} } }) => ({
      recentlyAccessedItems: recentlyAccessed.items,
      loading: recentlyAccessed.loading,
      courses: hubCourses.courses,
      coursesLoading: hubCourses.loading
    }),
    {
      openRedeemModalAction: openRedeemModal,
      showSelfSelectRoleModalAction: showSelfSelectRoleModal,
      openSelfRegisteredWizardAction: openSelfRegisteredWizard,
      getRecentlyAccessedAction: getRecentlyAccessedRequest,
      updateRecentlyAccessedAction: updateRecentlyAccessedRequest,
      openEnterCodeModalAction: openEnterCodeModal
    }
  ),
  withLocalizedContent('hubGlossary')
)(LastOpened);

LastOpened.propTypes = {
  recentlyAccessedItems: PropTypes.object,
  courses: PropTypes.object,
  coursesLoading: PropTypes.bool,
  openRedeemModalAction: PropTypes.func,
  localizedContent: PropTypes.object.isRequired,
  getRecentlyAccessedAction: PropTypes.func,
  updateRecentlyAccessedAction: PropTypes.func,
  openEnterCodeModalAction: PropTypes.func,
  loading: PropTypes.bool
};
