import React from 'react';
import PropTypes from 'prop-types';
// Services
import { get } from 'lodash';
import { Checkbox } from '@oup/shared-front-end';
import getTeacherLabel from '../../HubDashboardLayout/Services/getTeacherLabel';
import getDropdownItems from '../../../components/HubDropdownMenu/Services/getDropdownItems';
import getOrgClassesStatusData from '../../HubDashboardLayout/Services/getOrgClassesStatusData';
// Utils
import copyToClipboard from '../Utils/copyToClipboard';
import { HubLayoutConstants } from '../../../globals/hubConstants';
// Styles
import styles from './Classes.scss';
// Components
import breakpoints from '../../../globals/breakpoints';
import SVGIcon, { GLYPHS } from '../../../components/SVGIcon/SVGIcon';
import HubDropdownMenu from '../../../components/HubDropdownMenu/HubDropdownMenu';
import TeacherClassCode from '../../../components/TeacherClassCode/TeacherClassCode';
import Tooltip from '../../../components/Tooltip/Tooltip';

function Classes({
  ids,
  items,
  breakpoint,
  hubContent,
  selectedIds = [],
  actions: { setClassToArchive, setSelectionAction, redirectToProgress, redirectToOrgClassPage },
  userRole,
  displayClassCode = false,
  customOrgId = '',
  customClassname = '',
  openEditClassroomModal
}) {
  const copyText = copyToClipboard();

  const handleKeyDown = (e, id) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      redirectToOrgClassPage(e, id);
    }
  };

  const isLargeBreakpoint = breakpoint === breakpoints.MD || breakpoint === breakpoints.LG;

  return (
    <ul>
      {ids && ids.length
        ? ids.map((id, index) => {
            const item = get(items, [id], {});
            const { name = '', amountOfTeachers = 0, joiningCode = {}, archived } = item;

            const teacherLabel = getTeacherLabel(items, id, amountOfTeachers);

            const { icon, iconColour, date, text } = getOrgClassesStatusData(item, hubContent);

            const tooltipData = `${text} ${date}`;

            const dropdownOptions = {
              isClassCodeEnabled: displayClassCode && joiningCode?.enabled
            };
            const classCode = joiningCode ? `${customOrgId}-${joiningCode?.code}` : '';

            const classArchiveSelectionReachedMaximum =
              !selectedIds.includes(id) &&
              selectedIds.length === HubLayoutConstants.MAX_ORGANIZATION_CLASSES_SELECTION_FOR_ARCHIVE;

            const dropdownActions = {
              setClassToArchive: event => {
                setClassToArchive(id, event);
              },
              copyClassCode: () => {
                copyText(classCode);
              },
              redirectToProgress: event => {
                redirectToProgress(event, id);
              }
            };

            return (
              <li
                key={id}
                data-testid="ORG_CLASSES_CLASS_ROW_ITEM"
                className={`${styles.classesContainer} ${styles[customClassname]} ${
                  archived ? styles.classArchived : ''
                } ${selectedIds.includes(id) ? styles.active : ''} ${
                  classArchiveSelectionReachedMaximum ? styles.maximumSelectionReached : ''
                }`}
                onClick={e => {
                  if (e.target.closest(`.${styles.checkboxContainer}`) === null && !archived) {
                    redirectToOrgClassPage(e, id);
                  }
                }}
                onKeyDown={e => handleKeyDown(e, id)}
                role="none"
              >
                <div className={styles.classRow}>
                  {!archived && (
                    <div className={styles.checkboxContainer}>
                      <Checkbox
                        label={`select class ${name}`}
                        hideLabel
                        id={`select-class-archive-${id}`}
                        checked={(selectedIds || []).includes(id)}
                        onChange={() => {
                          setSelectionAction(id);
                        }}
                      />
                    </div>
                  )}

                  <div className={styles.leftContainer}>
                    <div className={styles.classInfoDot}>
                      <SVGIcon glyph={GLYPHS.ICON_CLASS} />
                    </div>
                    <div className={styles.classInfoContainer}>
                      <div>
                        <span>{name}</span>
                      </div>
                      <div>
                        <span>{teacherLabel}</span>
                      </div>
                    </div>
                  </div>

                  {displayClassCode && isLargeBreakpoint && !archived ? (
                    <TeacherClassCode
                      joiningCode={joiningCode}
                      customOrgId={customOrgId}
                      hubContent={hubContent}
                      openEditClassroomModal={openEditClassroomModal}
                    />
                  ) : (
                    <div className={styles.classCodePlaceholder} />
                  )}

                  <div className={styles.statusContainer}>
                    <div className={styles.svgContainer}>
                      <Tooltip
                        title={breakpoint === breakpoints.XXS ? tooltipData : ''}
                        className={styles.statusTooltip}
                      >
                        <SVGIcon glyph={icon} fill={iconColour} />
                      </Tooltip>
                    </div>
                    <div className={styles.textContainer}>
                      <span>{text}</span>
                      <span>{date}</span>
                    </div>
                    {breakpoint === breakpoints.XXS && !archived ? (
                      <div className={styles.dropdownContainer}>
                        <HubDropdownMenu
                          dropdownData={getDropdownItems(
                            'classes',
                            hubContent,
                            dropdownOptions,
                            dropdownActions,
                            userRole
                          )}
                          parentId={id}
                          customClassname={styles.dropDownMenu}
                          dropdownTop={
                            /** If total results 1 or more, last 2 items dropdown render on top */
                            ids.length >= 1 && (index === ids.length - 1 || index === ids.length - 2)
                          }
                        />
                      </div>
                    ) : (
                      <div className={styles.dropdownPlaceholder} />
                    )}
                  </div>

                  <div className={styles.rightContainer}>
                    {breakpoint !== breakpoints.XXS && !archived && (
                      <HubDropdownMenu
                        dropdownData={getDropdownItems(
                          'classes',
                          hubContent,
                          dropdownOptions,
                          dropdownActions,
                          userRole
                        )}
                        parentId={id}
                        customClassname={styles.dropDownMenu}
                        dropdownTop={
                          /** If total results 1 or more, last 2 items dropdown render on top */
                          ids.length >= 1 && (index === ids.length - 1 || index === ids.length - 2)
                        }
                      />
                    )}
                  </div>
                </div>
                {displayClassCode && !isLargeBreakpoint && !archived ? (
                  <div className={styles.bottomClassCodeContainer}>
                    <span>{hubContent.class_code}: </span>
                    <TeacherClassCode
                      joiningCode={joiningCode}
                      customOrgId={customOrgId}
                      hubContent={hubContent}
                      openEditClassroomModal={openEditClassroomModal}
                      displayCopyButton={false}
                      customClassname="unsetWidth"
                    />
                  </div>
                ) : null}
              </li>
            );
          })
        : null}
    </ul>
  );
}
Classes.defaultProps = {
  ids: [],
  items: {},
  hubContent: {}
};

Classes.propTypes = {
  ids: PropTypes.array,
  items: PropTypes.object,
  hubContent: PropTypes.object,
  breakpoint: PropTypes.string.isRequired,
  actions: PropTypes.object.isRequired,
  userRole: PropTypes.string.isRequired,
  setClassToArchive: PropTypes.func,
  displayClassCode: PropTypes.bool,
  customOrgId: PropTypes.string,
  customClassname: PropTypes.string,
  openEditClassroomModal: PropTypes.func,
  selectedIds: PropTypes.array
};

export default Classes;
