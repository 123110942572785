import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';

import { useWizard, Heading, TextInput, Button, Link, InformationBox } from '@oup/shared-front-end';
import Illustration from '../../Illustration/Illustration.js';

import {
  setUserName,
  setEmailConfirmation,
  validateUserName
} from '../../../redux/reducers/registration/registration.reducer.js';
import appSettings from '../../../globals/appSettings.js';
import { USER_REGISTRATION_STATUS } from '../../../globals/appConstants.js';
import { HubIllustrationConstants, HubIllustrationAltText, USER_TYPES } from '../../../globals/hubConstants.js';

import { getIdpLoginUrl } from '../../../redux/sagas/preRender/auth/getCredentials/redirectToIdP.saga.js';
import { prepareTargetUrl, getProviderId } from '../../../utils/url';
import styles from './CaptureEmailStep.scss';

function CaptureEmailStep({
  content,
  firstNameValue,
  emailValue,
  setEmailAction,
  emailConfirmationValue,
  setEmailConfirmationAction,
  validateEmailAction,
  isEmailTaken,
  registrationStatus,
  isYoungLearner
}) {
  const { setNextStepDisabled } = useWizard();
  const [isEmailFormatValid, setIsEmailFormatValid] = useState(undefined);
  const [emailErrorMessage, setEmailErrorMessage] = useState('');
  const [isConfirmedEmailValid, setIsConfirmedEmailValid] = useState(undefined);
  const [emailConfirmationErrorMessage, setEmailConfirmationErrorMessage] = useState('');
  const isTeacher = localStorage.getItem('userType') === USER_TYPES.TEACHER;

  useEffect(() => {
    if (
      !isEmailFormatValid ||
      !isConfirmedEmailValid ||
      (isEmailTaken && registrationStatus !== USER_REGISTRATION_STATUS.REGISTRATION_PENDING)
    ) {
      setNextStepDisabled(true);
    } else {
      setNextStepDisabled(false);
    }
  }, [isEmailFormatValid, isConfirmedEmailValid, isEmailTaken]);

  useEffect(() => {
    setNextStepDisabled(!(emailValue && emailConfirmationValue));
  }, []);

  useEffect(() => {
    if (isEmailTaken && registrationStatus !== USER_REGISTRATION_STATUS.REGISTRATION_PENDING) {
      setEmailErrorMessage(content.username_exists_sign_in_text);
    }
  }, [isEmailTaken]);

  const validateConfirmationEmail = (email, confirmedEmail) => {
    const isValid = email.toLowerCase() === confirmedEmail.toLowerCase();
    setIsConfirmedEmailValid(isValid);

    let errorMessage = '';
    if (!confirmedEmail) {
      errorMessage = content.confirm_email_text;
    } else if (emailValue.toLowerCase() !== confirmedEmail.toLowerCase()) {
      errorMessage = content.emails_not_equal_error;
    }
    setEmailConfirmationErrorMessage(errorMessage);
  };

  const onChangeEmail = ({ target: { value } }) => {
    setEmailAction(value);
    setIsEmailFormatValid(appSettings.EMAIL_REGEX.test(value));

    let errorMessage = '';
    if (!value) {
      errorMessage = content.email_required_text;
    } else if (!appSettings.EMAIL_REGEX.test(value)) {
      errorMessage = content.invalid_email_text;
    }
    setEmailErrorMessage(errorMessage);

    if (emailConfirmationValue) validateConfirmationEmail(value, emailConfirmationValue);
  };

  const onChangeConfirmEmail = ({ target: { value } }) => {
    setEmailConfirmationAction(value);
    validateConfirmationEmail(emailValue, value);
  };

  const onBlurEmail = () => {
    if (!emailErrorMessage) {
      validateEmailAction();
    }
  };

  const getEmailState = () => {
    if (
      (!isEmailFormatValid && emailErrorMessage) ||
      (isEmailTaken && registrationStatus !== USER_REGISTRATION_STATUS.REGISTRATION_PENDING)
    ) {
      return 'invalid';
    }
    if (emailValue && isEmailFormatValid) {
      return 'valid';
    }
    return 'default';
  };

  const getConfirmationEmailState = () => {
    if (emailConfirmationErrorMessage) {
      return 'invalid';
    }
    if (emailConfirmationValue && isConfirmedEmailValid) {
      return 'valid';
    }
    return 'default';
  };

  const onClickSingIn = async () => {
    const redirectTo = prepareTargetUrl();
    const providerId = getProviderId();
    const params = { withReturnTo: false };

    if (redirectTo) {
      params.redirect_uri = redirectTo;
    }
    if (providerId) {
      params.navigateTo = providerId;
    }

    const url = await getIdpLoginUrl(null, params);

    window.location.href = url;
  };

  return (
    <div
      data-testid="REGISTER_WIZARD_EMAIL_STEP"
      className={classnames(styles.stepContainer, { [styles.ylStepContainer]: isYoungLearner })}
    >
      <div className={styles.contentContainer}>
        <div>
          <Heading
            className={styles.heading}
            text={content.wizard_email_step_title.replace('[name]', firstNameValue)}
            size="medium"
            dataAttributes={{ testId: 'REGISTER_WIZARD_EMAIL_STEP_HEADING' }}
          />
          {!isTeacher && (
            <p className={styles.subHeading} data-testid="REGISTER_WIZARD_EMAIL_STEP_DESCRIPTION">
              {content.wizard_email_step_subtitle}
              <Link to={content.what_is_oxford_id_support_link} className={styles.link} target="_blank">
                <span>{`${content.oxford_id}.`}</span>
              </Link>
            </p>
          )}
        </div>
        <div className={styles.inputContainer}>
          <TextInput
            id="email"
            name="email"
            required
            label={content.email_address}
            value={emailValue}
            onChange={onChangeEmail}
            onBlur={onBlurEmail}
            state={(() => getEmailState())()}
            validationMessage={emailErrorMessage || ''}
            dataAttributes={{ testId: 'WIZARD_REGISTER_EMAIL_INPUT' }}
          />
          <TextInput
            id="confirmEmail"
            name="confirmEmail"
            required
            label={content.confirm_email_address}
            value={emailConfirmationValue}
            onChange={onChangeConfirmEmail}
            state={(() => getConfirmationEmailState())()}
            validationMessage={emailConfirmationErrorMessage || ''}
            dataAttributes={{ testId: 'WIZARD_REGISTER_CONFIRM_EMAIL_INPUT' }}
          />
        </div>
      </div>

      <div className={styles.rightColumnContainer}>
        {isYoungLearner ? (
          <div className={styles.illustrationContainer}>
            <Illustration
              illustrationSrc={HubIllustrationConstants.BLUE_MONSTER_WITH_SHADOW}
              illustrationAltText={HubIllustrationAltText.BLUE_MONSTER_WITH_SHADOW}
            />
          </div>
        ) : (
          <InformationBox title={content.do_you_have_oxford_id}>
            <div className={styles.infoBoxContent}>
              <p>{content.sign_in_with_your_username_or_password}</p>
              <Button id="sign-in-button" text={content.button_sign_in_text} onClick={onClickSingIn} />
            </div>
          </InformationBox>
        )}
      </div>
    </div>
  );
}

CaptureEmailStep.propTypes = {
  content: PropTypes.object.isRequired,
  firstNameValue: PropTypes.string.isRequired,
  emailValue: PropTypes.string.isRequired,
  setEmailAction: PropTypes.func.isRequired,
  emailConfirmationValue: PropTypes.string,
  setEmailConfirmationAction: PropTypes.func.isRequired,
  validateEmailAction: PropTypes.func.isRequired,
  isEmailTaken: PropTypes.bool,
  registrationStatus: PropTypes.string,
  isYoungLearner: PropTypes.bool
};

export default connect(
  ({ registration }) => ({
    emailValue: registration.userNameValue,
    emailConfirmationValue: registration.emailConfirmationValue,
    firstNameValue: registration.firstNameValue,
    isEmailTaken: registration.userNameExists,
    registrationStatus: registration.registrationStatus,
    isYoungLearner: registration.isYoungLearner
  }),
  {
    setEmailAction: setUserName,
    setEmailConfirmationAction: setEmailConfirmation,
    validateEmailAction: validateUserName
  }
)(CaptureEmailStep);
