import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from '../../../../utils/compose/compose.js';
import PlacementTestLanguageVariationContent from '../PlacementTestLanguageVariation/PlacementTestLanguageVariation';
import { setEditedPlacementTest } from '../../../../redux/reducers/placementTestSessionCreate';

import PanelHeading from '../../../../components/PanelHeading/PanelHeading';
import PopoutActionFooter from '../../../../components/PopoutActionFooter/PopoutActionFooter';
import ScrollContainer from '../../../../components/ScrollContainer/ScrollContainer';
import { updatePlacementTestSessionRequest } from '../../../../redux/actions/placementTestOnboardingWizard';
import withLocalizedContent from '../../../../language/withLocalizedContent';

function PlacementTestEditLanguageVariation({
  panelSessionName,
  listeningAccentsCodeProp,
  languageVariationCodeProp,
  updatePlacementTestSessionRequestAction,
  cancelLanguageVariationPanel,
  orgId,
  placementTestSessionId,
  setEditedPlacementTestAction,
  localizedContent: { placementTests: placementTestsContent, hubGlossary: hubContent }
}) {
  const [isLanguageVariationTouched, setIsLanguageVariationTouched] = useState(false);
  const [editedListeningAccentsCode, setEditedListeningAccentsCode] = useState(listeningAccentsCodeProp);
  const [editedLanguageVariationCode, setEditedLanguageVariationCode] = useState(languageVariationCodeProp);

  return (
    <ScrollContainer
      headerContent={
        <PanelHeading
          title={placementTestsContent.placement_test_session_manage_test_settings}
          subtitle={`${hubContent.for_label} <strong>${panelSessionName}</strong>`}
        />
      }
      footerContent={
        <PopoutActionFooter
          wizardButtons
          withIcon={false}
          primaryButtonText={hubContent.save}
          primaryButtonAction={e => {
            updatePlacementTestSessionRequestAction({
              orgId,
              sessionId: placementTestSessionId,
              placementTest: {
                languageVariationCode: editedLanguageVariationCode,
                listeningAccentsCode: editedListeningAccentsCode,
                placementTestType: editedListeningAccentsCode
              }
            });
            setEditedPlacementTestAction({
              languageVariationCode: editedLanguageVariationCode,
              listeningAccentsCode: editedListeningAccentsCode,
              placementTestType: editedListeningAccentsCode
            });
            cancelLanguageVariationPanel(e, true);
          }}
          secondaryButtonText={hubContent.cancel}
          secondaryButtonAction={cancelLanguageVariationPanel}
          primaryButtonDisabled={!isLanguageVariationTouched}
        />
      }
    >
      <PlacementTestLanguageVariationContent
        isWizard={false}
        setLanguageVariationCodeCallback={data => setEditedLanguageVariationCode(data)}
        setIsLanguageVariationTouchedCallback={data => setIsLanguageVariationTouched(data)}
        setListeningAccentsCodeCallback={data => setEditedListeningAccentsCode(data)}
      />
    </ScrollContainer>
  );
}

PlacementTestEditLanguageVariation.propTypes = {
  panelSessionName: PropTypes.string.isRequired,
  listeningAccentsCodeProp: PropTypes.string,
  languageVariationCodeProp: PropTypes.string,
  updatePlacementTestSessionRequestAction: PropTypes.func,
  cancelLanguageVariationPanel: PropTypes.func.isRequired,
  orgId: PropTypes.string.isRequired,
  placementTestSessionId: PropTypes.string.isRequired,
  localizedContent: PropTypes.object.isRequired,
  setEditedPlacementTestAction: PropTypes.func
};

export default compose(
  withLocalizedContent('hubGlossary', 'placementTests'),
  connect(
    ({ placementTestSessionCreate, identity }) => {
      const placementTest = placementTestSessionCreate.placementTest;
      return {
        placementTest,
        listeningAccentsCodeProp: placementTest.listeningAccentsCode,
        languageVariationCodeProp: placementTest.languageVariationCode,
        orgId: identity.currentOrganisationId,
        placementTestSessionId: placementTestSessionCreate.placementTestSessionId
      };
    },
    {
      updatePlacementTestSessionRequestAction: updatePlacementTestSessionRequest,
      setEditedPlacementTestAction: setEditedPlacementTest
    }
  )
)(PlacementTestEditLanguageVariation);
