import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { parse as parseQueryString } from 'query-string';

import { Heading, useWizard, TextLink } from '@oup/shared-front-end';

import Illustration from '../../Illustration/Illustration.js';
import { HubIllustrationConstants, HubIllustrationAltText } from '../../../globals/hubConstants.js';
import { getIdpLoginUrl } from '../../../redux/sagas/preRender/auth/getCredentials/redirectToIdP.saga.js';
import { formStates, submitForm } from '../../../redux/reducers/registration/registration.reducer.js';

import styles from './RegistrationComplete.scss';

function RegistrationComplete({ content, isYoungLearner, platform, formState, errorOccurred, registerUserAction }) {
  const { stepHandler, setStepOut } = useWizard();

  stepHandler(async () => {
    if (errorOccurred) {
      registerUserAction();
      throw new Error();
    } else {
      const url = await getIdpLoginUrl(null, { platform });
      window.location.href = url;
      setStepOut(true);
    }
  });

  return (
    <div
      data-testid="REGISTER_WIZARD_COMPLETED_STEP"
      className={classnames({ [styles.ylStepContainer]: isYoungLearner })}
    >
      <div className={styles.container}>
        {formState === formStates.COMPLETE && !errorOccurred ? (
          <>
            <div className={styles.infoContainer}>
              <Heading
                text={content.header_registration_complete}
                size="medium"
                className={styles.heading}
                dataAttributes={{ testId: 'REGISTER_WIZARD_COMPLETED_STEP_HEADING' }}
              />
              <p className={styles.subHeading} data-testid="REGISTER_WIZARD_COMPLETED_STEP_DESCRIPTION">
                {content.registration_complete_subtitle}
              </p>
            </div>
            <div className={styles.illustration}>
              <Illustration
                illustrationSrc={
                  isYoungLearner ? HubIllustrationConstants.PINK_MONSTER_WITH_SHADOW : HubIllustrationConstants.SUCCESS
                }
                illustrationAltText={
                  isYoungLearner ? HubIllustrationAltText.PINK_MONSTER_WITH_SHADOW : HubIllustrationAltText.SUCCESS
                }
              />
            </div>
          </>
        ) : (
          <>
            <div className={styles.infoContainer}>
              <Heading
                text={content.registration_error_title}
                size="medium"
                className={styles.heading}
                dataAttributes={{ testId: 'REGISTER_WIZARD_COMPLETED_STEP_HEADING' }}
              />
              <p className={styles.subHeading} data-testid="REGISTER_WIZARD_COMPLETED_STEP_DESCRIPTION">
                {content.registration_error}
              </p>
              {isYoungLearner ? (
                <p>
                  {content.contact_us}{' '}
                  <TextLink to={content.contact_us_link} target="_blank">
                    {content.elt_support_link_title}
                  </TextLink>
                </p>
              ) : (
                <TextLink to={content.contact_us_link} target="_blank">
                  {content.registration_error_link}
                </TextLink>
              )}
            </div>
            <div className={styles.illustration}>
              <Illustration
                illustrationSrc={
                  isYoungLearner ? HubIllustrationConstants.YELLOW_MONSTER : HubIllustrationConstants.ERROR
                }
                illustrationAltText={
                  isYoungLearner ? HubIllustrationAltText.YELLOW_MONSTER : HubIllustrationAltText.ERROR
                }
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
}

RegistrationComplete.propTypes = {
  isYoungLearner: PropTypes.bool,
  content: PropTypes.object.isRequired,
  platform: PropTypes.string,
  formState: PropTypes.string,
  errorOccurred: PropTypes.bool,
  registerUserAction: PropTypes.func
};

export default connect(
  ({ registration }) => {
    const queryString = parseQueryString(window.location.search) || {};

    return {
      formState: registration.formState,
      errorOccurred: registration.errorOccurred,
      isYoungLearner: registration.isYoungLearner,
      platform: queryString.providerId || queryString.platform
    };
  },
  {
    registerUserAction: submitForm
  }
)(RegistrationComplete);
