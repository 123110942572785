/* eslint-disable class-methods-use-this */
/* eslint-disable react/state-in-constructor */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { isEmpty } from 'lodash';
// Redux
import { connect } from 'react-redux';
import { Heading } from '@oup/shared-front-end';
import compose from '../../utils/compose/compose.js';
import actions from '../../redux/actions';
import { storeSavedSettings } from '../../redux/reducers/savedSettings';
// Components
import MaterialRepresentation from '../../components/MaterialRepresentation/MaterialRepresentation';
import ProductSelectionV2 from '../../components/ProductSelection/ProductSelectionV2';
import DataRefresher from '../../components/DataRefresher/DataRefresher';
import ProgressBar, { progressTypes } from '../../components/ProgressBar/ProgressBar';
import PieChartProgressDetails from '../../components/PieChartProgressDetails/PieChartProgressDetails';
import SubSectionSkeletonLoader from '../../components/SkeletonLoader/SubSectionSkeletonLoader';
import { SIZES as thumbnailSizes } from '../../components/Thumbnail/Thumbnail';
import RecordListingControls from '../../components/GradebookTable/RecordListingControls';
import GradebookProgressSettingsModal from '../../components/GradebookProgressSettingsModal/GradebookProgressSettingsModal.js';
import { attemptOptions } from '../../components/LearnerProgress/LearnerProgress';
import { HubAssessmentFallbackImgSmallCircle as AssessmentImage } from '../../components/HubImage';
import SVGIcon, { GLYPHS } from '../../components/SVGIcon/SVGIcon';
// @ts-ignore
// Utils
import formatFraction from '../../components/ClassProgress/formatFraction';
import withLocalizedContent from '../../language/withLocalizedContent';
import withLocalizedErrors from '../../language/withLocalizedErrors';
import { featureIsEnabled } from '../../globals/envSettings';
import { isHubMode } from '../../utils/platform.js';
// Constants
import { ALIGNMENT, GRADEBOOK_TYPE } from '../../globals/appConstants';
import { HubLayoutConstants } from '../../globals/hubConstants';
// Styles
import productSelectionStyles from '../../components/ProductSelection/ProductSelection.scss';
import styles from './ProductSelectionPageV2.scss';
import colors from '../../globals/colors';
import stickyProgressSettingsBar from '../../utils/stickyProgressSettingsBar.js';

class ProductSelectionPageV2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      usePercentages: true,
      useCompletedOnly: true,
      scrollPosition: 0,
      topProgressSettingsPos: 0,
      progressSettingsContainerHeight: 0,
      gradebookContentWidth: 0
    };
    this.contentEl = React.createRef();
    this.progressSettingsContainerEl = React.createRef();
  }

  componentDidMount() {
    const { workbookRequestAction } = this.props;
    workbookRequestAction('', 'products', this.getWorkbookRequestParams());
    if (isHubMode() && featureIsEnabled('gradebook-ui-updates')) {
      window.addEventListener('scroll', () =>
        stickyProgressSettingsBar(this.contentEl, this.progressSettingsContainerEl, newState => this.setState(newState))
      );
    }
  }

  componentDidUpdate(prevProps) {
    const { workbookRequestAction, params } = this.props;
    if (isHubMode()) {
      if (prevProps.params.classroomId !== params.classroomId) {
        workbookRequestAction('', 'products', this.getWorkbookRequestParams());
      }
    }
  }

  componentWillUnmount() {
    const { workbookLoadingAction } = this.props;
    workbookLoadingAction();
    if (isHubMode() && featureIsEnabled('gradebook-ui-updates')) {
      window.removeEventListener('scroll', stickyProgressSettingsBar);
    }
  }

  onProductClickHandler = product => {
    const { assignSelectedProduct, setSelectedProductTypeAction } = this.props;
    assignSelectedProduct(product.name);
    setSelectedProductTypeAction(product.gradebookType);
  };

  onUsePercentagesOnChange = () => {
    const { usePercentages } = this.state;
    this.setState({
      usePercentages: !usePercentages
    });
  };

  onUseCompletedOnlyOnChange = () => {
    const { useCompletedOnly } = this.state;
    this.setState({
      useCompletedOnly: !useCompletedOnly
    });
  };

  getWorkbookRequestParams() {
    const { myProgress, params, identity } = this.props;
    return myProgress ? { ...params, myProgress, userId: identity?.userId } : params; // To unblock testing...
  }

  get _localizedContent() {
    const { localizedContent } = this.props;
    return localizedContent.productSelectionPage;
  }

  _formatAssessmentRows = assessments => {
    const { role } = this.props;
    if (!Object.values(assessments).length) return [];

    return Object.entries(assessments).map(([productId, product], index) => ({
      id: `assessments_${index}`,
      cells: [
        <div key="cell-rep" className={styles.rowContainer}>
          <div className={productSelectionStyles.containerTitleWithIcon}>
            <AssessmentImage className={styles.assessmentIcon} />
            <MaterialRepresentation
              textAlignment={ALIGNMENT.LEFT}
              name={product.name}
              linkTo={`${window.location.pathname}/workbook/${productId}`}
              onClick={() => this.onProductClickHandler(product)}
            />
          </div>
        </div>,
        <div
          key="cell-com"
          className={`${productSelectionStyles.progressContainer} ${styles.assessmentProgressContainer}`}
        >
          <div>
            <p className={productSelectionStyles.mediumText}>{product.completion}</p>
            <p className={productSelectionStyles.textCenter}>
              {this._localizedContent.assessment_average_completion_suffix_tests.toLowerCase()}
            </p>
          </div>
        </div>,
        <div
          key="cell-sco"
          className={`${productSelectionStyles.smallText} ${productSelectionStyles.scoreBarContainer}`}
        >
          <ProgressBar
            role={role}
            percentage={
              product.productScoreAvailable ? (product.productScoreAchieved / product.productScoreAvailable) * 100 : 0
            }
            type={progressTypes.SECONDARY}
            color={colors.CORRECT}
            ariaLabelText="Average score"
            label={formatFraction(product.productScoreAchieved, product.productScoreAvailable, true)}
            content={this._localizedContent}
            pendingMark={product.pendingMark}
            displayShortLabelOnMobile
          />
        </div>
      ]
    }));
  };

  _formatWorkbookProductRows = (data, studentsInClass) => {
    const { role, workbook, myProgress } = this.props;
    const { usePercentages, useCompletedOnly } = this.state;
    if (!Object.values(data).length) return [];

    return Object.entries(data).map(([productId, product], index) => ({
      id: `workbookRow${index}`,
      cells: [
        isHubMode() && !product.url ? (
          <div key="cell-rep" className={styles.rowContainerWithIcon}>
            <div className={styles.fallbackOupIcon}>
              <SVGIcon glyph={GLYPHS.OUP_SYMBOL_REBRANDING} />
            </div>
            <MaterialRepresentation
              key="cell-rep"
              textAlignment={ALIGNMENT.LEFT}
              linkTo={`${window.location.pathname}/workbook/${productId}`}
              name={product.name}
              deletedStyle={!product.productScoreAvailable}
              onClick={() => this.onProductClickHandler(product)}
            />
          </div>
        ) : (
          <MaterialRepresentation
            key="cell-rep"
            thumbnailSize={thumbnailSizes.TABLE}
            textAlignment={ALIGNMENT.LEFT}
            imageSrc={product.url || null}
            linkTo={`${window.location.pathname}/workbook/${productId}`}
            name={product.name}
            deletedStyle={!product.productScoreAvailable}
            onClick={() => this.onProductClickHandler(product)}
          />
        ),
        <div key="cell-com" className={productSelectionStyles.progressContainer}>
          <PieChartProgressDetails
            completed={
              myProgress || usePercentages
                ? product.submittedNumberOfActivities
                : product.submittedNumberOfActivities / workbook.studentsInClass
            }
            total={
              myProgress || !usePercentages
                ? product.totalNumberOfActivities
                : product.totalNumberOfActivities * workbook.studentsInClass
            }
            usePercentages={usePercentages}
            content={this._localizedContent}
            customClassName={productSelectionStyles.gradebookProgressPieChart}
            displayShortLabelOnMobile
            role={role}
          />
        </div>,
        <div
          key="cell-sco"
          className={`${productSelectionStyles.smallText} ${productSelectionStyles.scoreBarContainer}`}
        >
          {useCompletedOnly ? (
            <ProgressBar
              role={role}
              percentage={
                product.productScoreAvailable ? (product.productScoreAchieved / product.productScoreAvailable) * 100 : 0
              }
              pendingMark={product.pendingMark}
              type={progressTypes.SECONDARY}
              color={colors.CORRECT}
              ariaLabelText="Average score"
              label={formatFraction(product.productScoreAchieved, product.productScoreAvailable, usePercentages)}
              content={this._localizedContent}
              displayShortLabelOnMobile
            />
          ) : (
            <ProgressBar
              role={role}
              percentage={
                (product.productScoreAchieved / studentsInClass / product.allActivityLevelScoreAvailable) * 100
              }
              type={progressTypes.SECONDARY}
              color={colors.CORRECT}
              ariaLabelText="Average score"
              pendingMark={product.pendingMark}
              label={formatFraction(
                Math.round(product.productScoreAchieved / studentsInClass),
                product.allActivityLevelScoreAvailable,
                usePercentages
              )}
              content={this._localizedContent}
              displayShortLabelOnMobile
            />
          )}
        </div>
      ]
    }));
  };

  _isProductAvailable = data => !isEmpty(data);

  _extractProdByType = (data, type) => {
    const results = {};
    Object.keys(data).forEach(id => {
      if (data[id]?.gradebookType === type) {
        results[id] = data[id];
      }
    });
    return results;
  };

  _filterStudentProducts = products => {
    const results = {};
    Object.keys(products).forEach(id => {
      if (products[id]?.targetUsertype === HubLayoutConstants.TARGET_USERTYPE.STUDENT) {
        results[id] = products[id];
      }
    });
    return results;
  };

  switchAttempt = attemptFilter => {
    const { storeSavedSettingsAction, workbookRequestAction } = this.props;
    storeSavedSettingsAction({ attemptFilter });
    workbookRequestAction('', 'products', this.getWorkbookRequestParams());
  };

  showFiguresAsPercentage = value => {
    this.setState({
      usePercentages: value
    });
  };

  renderProducts = (assessments, onlinePractice, classMaterials) => (
    <>
      {classMaterials}
      {onlinePractice}
      {assessments}
    </>
  );

  renderLoader = tabName => (
    <SubSectionSkeletonLoader
      speed={2}
      tabName={tabName}
      foregroundColor={colors.COLOR_GREY_DISABLED2}
      backgroundColor={colors.COLOR_WHITE}
    />
  );

  renderProductsLoad = (products, type, studentsInClass) => {
    const { myProgress } = this.props;
    let formattedProducts = {};
    let filteredProducts = this._extractProdByType(products, type);

    if (!Object.values(filteredProducts).length) {
      return null;
    }

    if (type === GRADEBOOK_TYPE.ONLINE_PRACTICE) {
      filteredProducts = this._filterStudentProducts(filteredProducts);
    }

    let heading = '';

    switch (type) {
      case GRADEBOOK_TYPE.ASSESSMENT:
        formattedProducts = this._formatAssessmentRows(filteredProducts);
        heading = this._localizedContent.assessments_title_text;
        break;
      case GRADEBOOK_TYPE.ONLINE_PRACTICE:
        formattedProducts = this._formatWorkbookProductRows(filteredProducts, studentsInClass);
        heading = this._localizedContent.online_practice_title_text;
        break;
      default:
        formattedProducts = this._formatWorkbookProductRows(filteredProducts, studentsInClass);
        heading = this._localizedContent.class_materials_title_text;
        break;
    }

    if (!this._isProductAvailable(filteredProducts)) {
      return null;
    }

    return (
      <ProductSelectionV2
        products={formattedProducts}
        heading={heading}
        localizedContent={this._localizedContent}
        enhancedLayout
        isMyProgress={myProgress}
      />
    );
  };

  getSubtitleFragment = progressSettings => {
    const { workbook, workbookRequestAction } = this.props;
    const { scrollPosition, topProgressSettingsPos, gradebookContentWidth } = this.state;

    return featureIsEnabled('gradebook-ui-updates') ? (
      <div className={styles.subtitleFragmentContainer}>
        <DataRefresher
          loading={workbook.loading}
          noSidePadding
          showLabel={false}
          refreshData={() => {
            workbookRequestAction('', 'products', this.getWorkbookRequestParams());
          }}
          className={styles.dataRefresherBar}
        />
        <div
          className={classnames(styles.gradebookControlsContainer, {
            [styles.progressContainerFixed]: scrollPosition < 0
          })}
          ref={this.progressSettingsContainerEl}
          style={{
            top: scrollPosition < 0 ? `${topProgressSettingsPos}px` : undefined,
            width: scrollPosition < 0 ? `calc(${gradebookContentWidth}px + 1.25rem)` : undefined
          }}
        >
          <div className={styles.progressSettingsButton}>
            <GradebookProgressSettingsModal content={this._localizedContent} settings={progressSettings} />
          </div>
        </div>
      </div>
    ) : (
      <DataRefresher
        loading={workbook.loading}
        noSidePadding
        showLabel={false}
        refreshData={() => {
          workbookRequestAction('', 'products', this.getWorkbookRequestParams());
        }}
      />
    );
  };

  render() {
    const {
      workbook,
      workbook: { products, studentsInClass },
      attemptFilter,
      getLocalizedErrorMessage,
      isProgressPage,
      params,
      myProgress
    } = this.props;
    const { usePercentages, useCompletedOnly, scrollPosition, progressSettingsContainerHeight } = this.state;

    // Gradebook Progress settings
    const progressSettings = {
      score: {
        onChange: this.showFiguresAsPercentage,
        displayAs: usePercentages ? 'percentages' : 'fractions'
      }
    };

    return (
      <div
        className={classnames(productSelectionStyles['product-selection-page'], {
          [productSelectionStyles.navigationChanges]: featureIsEnabled('navigation-changes'),
          [styles.gradebookUiUpdates]: featureIsEnabled('gradebook-ui-updates')
        })}
      >
        {isHubMode() && !myProgress && (
          <Heading text={this._localizedContent.progress_title_text} size="small" className={styles.heading} />
        )}

        {!workbook.loading &&
          !workbook.failure &&
          Object.keys(products).length > 0 &&
          (!featureIsEnabled('gradebook-ui-updates') || !isHubMode()) && (
            <RecordListingControls
              usePercentages={usePercentages}
              useCompletedOnly={useCompletedOnly}
              onUsePercentagesOnChange={this.onUsePercentagesOnChange}
              onUseCompletedOnlyOnChange={this.onUseCompletedOnlyOnChange}
              attemptFilter={attemptFilter}
              attemptFilterOptions={Object.values(attemptOptions(this._localizedContent))}
              onAttemptFilterOnChange={this.switchAttempt}
              localizedContent={this._localizedContent}
              rangeValue=""
              rangeValueOnchange={() => {}}
              useFilterByScore={false}
              onUseFilterByScoreOnChange={() => {}}
              hideFilterByScore
              isProgressPage={isProgressPage}
              isMyProgress={myProgress}
            />
          )}

        {workbook.failure ? (
          <p className={classnames('row', productSelectionStyles.message)}>
            {getLocalizedErrorMessage(workbook.errors.error) ||
              'There is a problem which means we cannot load this data at the moment. Please try again later or contact Customer Support for help.'}
            {/* TODO: Need a better handling */}
          </p>
        ) : null}

        {isHubMode() && this.getSubtitleFragment(progressSettings)}

        <div
          className={styles.myProgressOuterContainer}
          style={{ paddingTop: scrollPosition < 0 ? `${progressSettingsContainerHeight}px` : '' }}
        >
          <div ref={this.contentEl}>
            {workbook.loading
              ? this.renderLoader(params.tabName)
              : this.renderProducts(
                  this.renderProductsLoad(products, GRADEBOOK_TYPE.ASSESSMENT, studentsInClass || 0),
                  this.renderProductsLoad(products, GRADEBOOK_TYPE.ONLINE_PRACTICE, studentsInClass || 0),
                  this.renderProductsLoad(products, GRADEBOOK_TYPE.WORKBOOK, studentsInClass || 0)
                )}
          </div>
        </div>
      </div>
    );
  }
}
ProductSelectionPageV2.propTypes = {
  params: PropTypes.object.isRequired,
  myProgress: PropTypes.bool,
  workbook: PropTypes.object.isRequired,
  attemptFilter: PropTypes.string.isRequired,
  setSelectedProductTypeAction: PropTypes.func,
  workbookRequestAction: PropTypes.func.isRequired,
  workbookLoadingAction: PropTypes.func.isRequired,
  assignSelectedProduct: PropTypes.func.isRequired,
  storeSavedSettingsAction: PropTypes.func.isRequired,
  localizedContent: PropTypes.object.isRequired,
  isProgressPage: PropTypes.bool,
  getLocalizedErrorMessage: PropTypes.func,
  identity: PropTypes.object,
  role: PropTypes.string
};

export default compose(
  withLocalizedContent('productSelectionPage'),
  withLocalizedErrors('eps-product-selection-page'),
  connect(
    state => ({
      workbook: state.gradebookClassReport,
      gradedReaders: state.results.gradedReaders,
      classroomName: state.classroomEdit.classroomNameValue,
      attemptFilter: state.savedSettings.settings.attemptFilter
    }),
    {
      workbookRequestAction: actions.gradebookClassReportRequest,
      workbookLoadingAction: actions.gradebookClassReportLoading,
      assignSelectedProduct: actions.gradebookAssignSelectedProduct,
      storeSavedSettingsAction: storeSavedSettings,
      setSelectedProductTypeAction: actions.setSelectedProductType
    }
  )
)(ProductSelectionPageV2);
