import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ValidationMessage, TextLink, Button } from '@oup/shared-front-end';
import APP_CONSTANTS from '@oup/shared-node-browser/constants';
import compose from '../../../../utils/compose/compose.js';
import withLocalizedContent from '../../../../language/withLocalizedContent';
import { clearWizardState } from '../../../../redux/actions/placementTestOnboardingWizard';
import { closePlacementTestPanel } from '../../../../redux/actions/hubUi';
import { refreshPlacementTestList, openEditJoiningCodePanel } from '../../../../redux/actions/placementTests';
import styles from './PlacementTestPanel.scss';
import SVGIcon, { GLYPHS } from '../../../../components/SVGIcon/SVGIcon';
import CopyToClipboardButton from '../../../../components/CopyToClipboardButton/CopyToClipboardButton';

function PlacementTestEditJoiningCode({
  customOrgId,
  localizedContent: { placementTests: placementTestsContent, hubGlossary: hubContent },
  openEditJoiningCodePanelAction,
  classCodeId,
  placesRemainingInSession = 0,
  joiningCode
}) {
  const [classCode, setClassCode] = useState('');

  useEffect(() => {
    setClassCode(`${customOrgId}-${classCodeId}`);
  }, [classCodeId]);

  const noPlaces = placesRemainingInSession === 0;
  const joiningCodeSwitchedOff = joiningCode?.enabled === false;

  return (
    <div className={styles.joiningCode}>
      <div className={styles.joiningCodeLabel}>{hubContent.joining_code_label}</div>
      <div className={styles.joiningCodeContent}>
        <div className={styles.joiningCodeLeft}>
          <div className={styles.joiningCodeValue}>{joiningCodeSwitchedOff ? hubContent.off : classCode}</div>
          {(!joiningCodeSwitchedOff || (joiningCodeSwitchedOff && !noPlaces)) && (
            <div>
              {placementTestsContent.placement_test_places_remaining.replace(
                '[placesRemaining]',
                placesRemainingInSession
              )}
            </div>
          )}
        </div>
        <div className={styles.joiningCodeRight}>
          <CopyToClipboardButton
            classCode={classCode}
            buttonLabel="Copy"
            disabled={noPlaces || joiningCodeSwitchedOff}
          />
          <Button
            variant="outline"
            text={hubContent.manage}
            icon={{ component: <SVGIcon glyph={GLYPHS.ICON_SETTINGS} />, placement: 'left' }}
            onClick={openEditJoiningCodePanelAction}
          />
        </div>
      </div>
      {noPlaces && !joiningCodeSwitchedOff && (
        <ValidationMessage state="warning">
          <span
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: placementTestsContent.placement_test_no_places_remaining_warning
            }}
          />
        </ValidationMessage>
      )}
      <p
        className={styles.joiningCodeCopyAndShare}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: joiningCodeSwitchedOff
            ? placementTestsContent.placement_test_choose_manage_to_activate
            : placementTestsContent.placement_test_copy_and_share
        }}
      />
      <TextLink to={APP_CONSTANTS.HUB_SUPPORT_CONTACT_US} target="_blank">
        {placementTestsContent.placement_test_add_students_page_learn_about_joining_codes}
      </TextLink>
    </div>
  );
}

PlacementTestEditJoiningCode.propTypes = {
  customOrgId: PropTypes.string.isRequired,
  localizedContent: PropTypes.object,
  openEditJoiningCodePanelAction: PropTypes.func,
  classCodeId: PropTypes.string,
  placesRemainingInSession: PropTypes.number,
  joiningCode: PropTypes.object
};

export default compose(
  withLocalizedContent('hubGlossary', 'placementTests'),
  connect(
    ({ placementTestSessionCreate, identity, organisations }) => ({
      placementTestSessionNameValue: placementTestSessionCreate.placementTestSessionNameValue,
      customOrgId: organisations?.data[identity.currentOrganisationId]?.customId
    }),
    {
      closePlacementTestPanelAction: closePlacementTestPanel,
      clearWizardStateAction: clearWizardState,
      refreshPlacementTestListAction: refreshPlacementTestList,
      openEditJoiningCodePanelAction: openEditJoiningCodePanel
    }
  )
)(PlacementTestEditJoiningCode);
