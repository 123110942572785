import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from '../utils/compose/compose.js';
import USER_ROLES from '../globals/userRoles';
import StudentLicenceView from '../views/StudentLicenceView';
import withLocalizedContent from '../language/withLocalizedContent';

import { initialiseInstance } from '../redux/reducers/data/search.reducer';
import getUsersGroupedByRole from '../redux/utils/getUsersGroupedByRole';

const ORDERED_ROLES = [
  USER_ROLES.MANAGED_USER,
  USER_ROLES.LEARNER,
  USER_ROLES.TEACHER,
  USER_ROLES.TEACHER_ADMIN,
  USER_ROLES.ORG_ADMIN
];

class AssessmentLicenceStatusPanel extends Component {
  componentDidMount() {
    const { orgId, initialRoles, initialiseSearch } = this.props;

    const searchObject = {
      orgId,
      archived: false,
      active: true,
      invited: true,
      roles: initialRoles
    };

    initialiseSearch('userSelection', true, searchObject);
  }

  render() {
    const {
      localizedContent,
      backAction,
      usersByRole,
      isLoading,
      testData,
      selectedStudentIds,
      onlineTestPanel
    } = this.props;

    return (
      <StudentLicenceView
        testData={testData}
        closeAction={backAction}
        backAction={backAction}
        usersByRole={usersByRole}
        isLoading={isLoading}
        localizedContent={localizedContent.studentAssessmentLicenceStatusPanel}
        onlineTestPanel={onlineTestPanel}
        selectedStudentIds={selectedStudentIds}
      />
    );
  }
}

AssessmentLicenceStatusPanel.propTypes = {
  orgId: PropTypes.string,
  usersByRole: PropTypes.object,
  initialiseSearch: PropTypes.func,
  backAction: PropTypes.func,
  isLoading: PropTypes.bool,
  initialRoles: PropTypes.array,
  localizedContent: PropTypes.object,
  testData: PropTypes.object,
  selectedStudentIds: PropTypes.array,
  onlineTestPanel: PropTypes.bool
};

export default compose(
  withLocalizedContent('studentAssessmentLicenceStatusPanel'),
  connect(
    state => {
      const searchInstance = state.search.userSelection || {};
      const resultIds = searchInstance.ids || [];
      const isLoading = searchInstance.loading;

      return {
        isLoading,
        usersByRole: getUsersGroupedByRole(state, resultIds, ORDERED_ROLES)
      };
    },
    {
      initialiseSearch: initialiseInstance
    }
  )
)(AssessmentLicenceStatusPanel);
