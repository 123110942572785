import React from 'react';
import PropTypes from 'prop-types';
// Components
import { Button, Checkbox } from '@oup/shared-front-end';
import Tooltip from '../Tooltip/Tooltip';
import SVGIcon, { GLYPHS } from '../SVGIcon/SVGIcon';
import tooltipTitleText from '../Tooltip/TooltipUtils';
// Constants
import { HubLayoutConstants } from '../../globals/hubConstants';
// Styles
import styles from './HubRowHeader.scss';

function HubRowHeader({
  item,
  ids,
  isTeacherAssignedToClass,
  selectable = true,
  checkboxData: { selectedIds = [], onSelectAll = null, areAllSelected = false } = {},
  hasAddButton,
  type,
  displayClassCode = false,
  customClassname = '',
  hubContent,
  showCheckbox
}) {
  const isChecked = () => {
    if (!selectable) {
      return null;
    }
    return [].concat(ids).every(id => selectedIds.includes(id));
  };

  return (
    <div className={`${styles.rowHeader} ${styles[customClassname]}`}>
      <div className={styles.textContainer}>
        {isTeacherAssignedToClass && showCheckbox && (
          <Checkbox
            id="people"
            label="Select entity"
            hideLabel
            checked={isChecked()}
            onChange={() => onSelectAll(ids, areAllSelected)}
          />
        )}
        <span>{item.name}</span>
      </div>

      {displayClassCode && type === HubLayoutConstants.HUB_ROW_HEADER_TYPES.ORG_CLASSES ? (
        <div className={`${styles.alignItemsCentered} ${styles.textContainer}`}>
          <span>{item.classCode}</span>
          <Tooltip title={tooltipTitleText(hubContent)} className={styles.largeTooltip}>
            <SVGIcon glyph={GLYPHS.ICON_INFORMATION_CIRCLE} className={styles.infoIcon} />
          </Tooltip>
        </div>
      ) : null}

      <div className={styles.textContainer}>
        <span>{item.status}</span>
      </div>

      <div className={styles.buttonContainer}>
        {hasAddButton &&
          (type === HubLayoutConstants.HUB_ROW_HEADER_TYPES.ORG_CLASSES ? (
            <Button
              text={item.buttonText}
              variant="filled"
              onClick={() => item.buttonAction(item.isTeacherInClass)}
              icon={{ component: <SVGIcon glyph={GLYPHS.ICON_PLUS} /> }}
            />
          ) : (
            <Button
              text={item.buttonText}
              variant="filled"
              onClick={() => item.buttonAction()}
              icon={{ component: <SVGIcon glyph={GLYPHS.ICON_PLUS} /> }}
            />
          ))}
      </div>
    </div>
  );
}

HubRowHeader.defaultProps = {
  hasAddButton: false,
  showCheckbox: true
};

HubRowHeader.propTypes = {
  item: PropTypes.object.isRequired,
  checkboxData: PropTypes.object,
  ids: PropTypes.array.isRequired,
  isTeacherAssignedToClass: PropTypes.bool.isRequired,
  selectable: PropTypes.bool,
  hasAddButton: PropTypes.bool,
  type: PropTypes.string,
  displayClassCode: PropTypes.bool,
  customClassname: PropTypes.string,
  hubContent: PropTypes.object,
  showCheckbox: PropTypes.bool
};

export default HubRowHeader;
