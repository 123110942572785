import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';

import { useWizard, TextInput, Heading } from '@oup/shared-front-end';
import Illustration from '../../Illustration/Illustration.js';

import { setFirstName, setLastName } from '../../../redux/reducers/registration/registration.reducer.js';
import appSettings from '../../../globals/appSettings.js';
import { HubIllustrationConstants, HubIllustrationAltText } from '../../../globals/hubConstants.js';
import styles from './FirstLastNameStep.scss';

function FirstAndLastNameStep({
  content,
  firstNameValue,
  lastNameValue,
  setFirstNameAction,
  setLastNameAction,
  isYoungLearner
}) {
  const { setNextStepDisabled } = useWizard();
  const [firstNameError, setFirstNameError] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const [firstNameFieldState, setFirstNameFieldState] = useState('default');
  const [lastNameFieldState, setLastNameFieldState] = useState('default');

  useEffect(() => {
    if (firstNameFieldState === 'valid' && lastNameFieldState === 'valid') {
      setNextStepDisabled(false);
    } else {
      setNextStepDisabled(true);
    }
  }, [firstNameFieldState, lastNameFieldState]);

  useEffect(() => {
    setNextStepDisabled(!(firstNameValue && lastNameValue));
  }, []);

  const validateName = (value = '') => {
    const trimmedValue = value.trim();
    const invalidName = trimmedValue.length <= 1;
    const invalidCharacters = appSettings.INVALID_CHARACTERS_REGEX.test(trimmedValue);

    return { invalidName, invalidCharacters };
  };

  return (
    <div
      data-testid="REGISTER_WIZARD_NAME_STEP"
      className={classnames({
        [styles.firstLastNameStepContainer]: !isYoungLearner,
        [styles.ylStepContainer]: isYoungLearner
      })}
    >
      <div className={styles.container}>
        <Heading
          text={content.wizard_first_step_title}
          size="medium"
          className={styles.heading}
          dataAttributes={{ testId: 'REGISTER_WIZARD_NAME_STEP_HEADING' }}
        />

        <TextInput
          id="firstName"
          name="firstName"
          required
          label={content.first_name_label}
          value={firstNameValue}
          onBlur={({ target: { value } }) => {
            const { invalidName, invalidCharacters } = validateName(value);

            if (invalidName) {
              setFirstNameError(content.forename_required_text);
              setFirstNameFieldState('invalid');
              return;
            }

            if (invalidCharacters) {
              setFirstNameError(content.invalid_name_error);
              setFirstNameFieldState('invalid');
              return;
            }

            if (!invalidName && !invalidCharacters) {
              setFirstNameError('');
              setFirstNameFieldState('valid');
            }
          }}
          onChange={({ target: { value } }) => {
            setFirstNameAction(value);
          }}
          state={firstNameFieldState}
          validationMessage={firstNameFieldState === 'valid' ? '' : firstNameError}
          dataAttributes={{ testId: 'WIZARD_REGISTER_FIRST_NAME' }}
        />
        <TextInput
          id="lastName"
          name="lastName"
          required
          label={content.last_name_label}
          value={lastNameValue}
          onBlur={({ target: { value } }) => {
            const { invalidName, invalidCharacters } = validateName(value);

            if (invalidName) {
              setLastNameError(content.surname_required_text);
              setLastNameFieldState('invalid');
              return;
            }

            if (invalidCharacters) {
              setLastNameError(content.invalid_name_error);
              setLastNameFieldState('invalid');
              return;
            }

            if (!invalidName && !invalidCharacters) {
              setLastNameError('');
              setLastNameFieldState('valid');
            }
          }}
          onChange={({ target: { value } }) => {
            setLastNameAction(value);
          }}
          state={lastNameFieldState}
          validationMessage={lastNameFieldState === 'valid' ? '' : lastNameError}
          dataAttributes={{ testId: 'WIZARD_REGISTER_LAST_NAME' }}
        />
      </div>
      {isYoungLearner ? (
        <div className={styles.rightColumnContainer}>
          <div className={styles.illustrationContainer}>
            <Illustration
              illustrationSrc={HubIllustrationConstants.BLUE_MONSTER_WITH_SHADOW}
              illustrationAltText={HubIllustrationAltText.BLUE_MONSTER_WITH_SHADOW}
            />
          </div>
        </div>
      ) : null}
    </div>
  );
}

FirstAndLastNameStep.propTypes = {
  content: PropTypes.object.isRequired,
  firstNameValue: PropTypes.string.isRequired,
  lastNameValue: PropTypes.string.isRequired,
  setFirstNameAction: PropTypes.func.isRequired,
  setLastNameAction: PropTypes.func.isRequired,
  isYoungLearner: PropTypes.bool
};

export default connect(
  ({ registration }) => ({
    firstNameValue: registration.firstNameValue,
    lastNameValue: registration.lastNameValue,
    isYoungLearner: registration.isYoungLearner
  }),
  {
    setFirstNameAction: setFirstName,
    setLastNameAction: setLastName
  }
)(FirstAndLastNameStep);
