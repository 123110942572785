import React from 'react';
import PropTypes from 'prop-types';
import { Wizard, SiteHeader, Button } from '@oup/shared-front-end';
import { ICON_HELP_CIRCLE } from '@oup/shared-front-end/svg/oup';

import compose from '../../utils/compose/compose.js';
import withLocalizedContent from '../../language/withLocalizedContent';
import { HubLayoutConstants } from '../../globals/hubConstants';
import useMediaQuery from '../../utils/mediaQuery.js';

import styles from './RegisterOrSignInWizard.scss';
import RegisterOrSignInStep from './RegisterOrSignInStep.js';
import RegisterOrSignInWizardFooter from './RegisterOrSignInFooter.js';

function RegisterOrSignInWizard({ localizedContent: { registerOrSignInWizard: content } }) {
  const isMobile = useMediaQuery('(max-width: 52.5rem)');

  return (
    <div data-testid="REGISTER_OR_SIGNIN_WIZARD_CONTAINER">
      <Wizard
        header={
          <SiteHeader
            buttons={[
              {
                type: Button,
                props: {
                  variant: 'filled',
                  icon: { component: <ICON_HELP_CIRCLE className={styles.helpIconLoggedOut} /> },
                  text: !isMobile ? content.help_and_support_button_text : '',
                  onClick: () => window.open(HubLayoutConstants.HELP_AND_SUPPORT_URL, '_blank'),
                  className: !isMobile ? styles.helpButtonLarge : styles.helpButtonSmall
                }
              }
            ]}
            secondaryText={content.oxford_english_hub}
          />
        }
        footer={<RegisterOrSignInWizardFooter content={content} />}
        showProgressBar={false}
      >
        <RegisterOrSignInStep content={content} />
      </Wizard>
    </div>
  );
}

RegisterOrSignInWizard.propTypes = {
  localizedContent: PropTypes.object.isRequired
};

export default compose(withLocalizedContent('registerOrSignInWizard'))(RegisterOrSignInWizard);
