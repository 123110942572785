import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useWizard, Footer } from '@oup/shared-front-end';

function RegisterWizardFooter({ content, errorOccurred }) {
  const { step, nextStep, prevStep, nextStepDisabled, isLastStep } = useWizard();

  const setPrimaryButtonLabel = () => {
    if (isLastStep) {
      return errorOccurred ? content.register_step_error : content.register_finish_button_text;
    }
    if (step === 3) {
      return content.create_account;
    }

    return content.register_step_button_text;
  };

  const setPrimaryButtonTestId = () => {
    if (isLastStep) {
      return errorOccurred ? 'REGISTER_WIZARD_TRY_BUTTON' : 'REGISTER_WIZARD_FINISH_BUTTON';
    }
    return 'REGISTER_WIZARD_NEXT_BUTTON';
  };

  return (
    <Footer
      primaryButtonAction={nextStep}
      primaryButtonLabel={setPrimaryButtonLabel()}
      primaryButtonAttributes={{
        disabled: nextStepDisabled,
        dataAttributes: {
          testid: setPrimaryButtonTestId()
        }
      }}
      secondaryButtonLabel={content.register_prev_step_button_text}
      secondaryButtonAttributes={{
        disabled: step === 0,
        dataAttributes: {
          testid: 'REGISTER_WIZARD_BACK_BUTTON'
        }
      }}
      secondaryButtonAction={prevStep}
    />
  );
}

RegisterWizardFooter.propTypes = {
  content: PropTypes.object.isRequired,
  errorOccurred: PropTypes.bool
};

export default connect(({ registration }) => ({
  errorOccurred: registration.errorOccurred
}))(RegisterWizardFooter);
