export const limitTypes = {
  USERS: 'USERS',
  CLASSES: 'CLASSES',
  USERS_AND_CLASSES: 'USERS_AND_CLASSES',
  NONE: 'NONE'
};

const warningLimits = {
  USERS: 1300,
  USERS_LTI: 9800,
  CLASSES: 375
};

export const maximumRecommendedLimits = {
  USERS: 1500,
  USERS_LTI: 10000,
  CLASSES: 400
};

export const getLimitTypeReached = (classesCount, activeUserCount, currentOrganisationLti) => {
  const maxUsers = currentOrganisationLti ? warningLimits.USERS_LTI : warningLimits.USERS;

  switch (true) {
    case classesCount >= warningLimits.CLASSES && activeUserCount >= maxUsers:
      return limitTypes.USERS_AND_CLASSES;
    case classesCount >= warningLimits.CLASSES:
      return limitTypes.CLASSES;
    case activeUserCount >= maxUsers:
      return limitTypes.USERS;
    default:
      return limitTypes.NONE;
  }
};
