import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { PLACEMENT_TEST_RESULTS_STATUS } from '@oup/shared-node-browser/constants';
import compose from '../../utils/compose/compose.js';
import PlacementTestItem from '../../structure/HubOrganizationLayout/OrganizationPlacementTests/PlacementTest.js';
import calcDropdownPosition from '../../structure/HubOrganizationLayout/Utils/calcDropdownPosition.js';
import withLocalizedContent from '../../language/withLocalizedContent';
import withBreakpoint from '../../decorators/withBreakpoint';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner.js';
import { HubIllustrationConstants, HubIllustrationAltText } from '../../globals/hubConstants.js';
import HubEmptyStateRestyle from '../../components/HubEmptyStateRestyle/HubEmptyStateRestyle';

function MyTasksList({
  localizedContent: { hubGlossary: hubContent },
  tabTitle,
  placementTests,
  userRole,
  currentOrganisationId,
  orgName,
  arePlacementTestsLoading,
  emptyStateText
}) {
  const loadEmptyState = placementTests.length <= 0 && !arePlacementTestsLoading;
  const loadPlacementTests = placementTests.length > 0 && !arePlacementTestsLoading;

  return (
    <>
      {loadEmptyState && (
        <HubEmptyStateRestyle
          iconSrc={HubIllustrationConstants.SEARCH}
          iconAlt={HubIllustrationAltText.SEARCH}
          {...emptyStateText}
        />
      )}
      {loadPlacementTests && (
        <>
          <h2>{tabTitle}</h2>
          <h3>{hubContent?.placementTest}</h3>
          <ul data-testid="studentTasksList">
            {placementTests.map((test, itemIndex) => {
              const status = test?.configurations?.CONFIGURATION_PLACEMENT_TEST?.status;

              return (
                <PlacementTestItem
                  key={test._id}
                  test={test}
                  userRole={userRole}
                  id={test._id}
                  customOrgId={currentOrganisationId}
                  orgName={orgName}
                  isMyTasksPage
                  dropdownTop={calcDropdownPosition(placementTests, itemIndex)}
                  displayStatus={
                    status === PLACEMENT_TEST_RESULTS_STATUS.ACTIVE || status === PLACEMENT_TEST_RESULTS_STATUS.UPCOMING
                  }
                />
              );
            })}
          </ul>
        </>
      )}
      {arePlacementTestsLoading && <LoadingSpinner />}
    </>
  );
}

MyTasksList.propTypes = {
  localizedContent: PropTypes.object,
  hubContent: PropTypes.object.isRequired,
  tabTitle: PropTypes.string.isRequired,
  userRole: PropTypes.string,
  currentOrganisationId: PropTypes.string,
  placementTests: PropTypes.array,
  orgName: PropTypes.string,
  arePlacementTestsLoading: PropTypes.bool,
  emptyStateText: PropTypes.string
};

export default compose(
  connect(
    ({
      identity: { role: userRole, currentOrganisationId },
      loadPlacementTestsReducer: { studentTasks, loading: arePlacementTestsLoading },
      organisations: { data: orgData }
    }) => ({
      userRole,
      currentOrganisationId,
      placementTests: studentTasks.placementTests,
      orgName: get(orgData, [currentOrganisationId, 'name'], ''),
      arePlacementTestsLoading
    }),
    {}
  ),
  withLocalizedContent('hubGlossary'),
  withBreakpoint
)(MyTasksList);
